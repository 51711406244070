import { cloneDeep } from "lodash-es";

export function initializeCustomComponentState(
  propsValue: any,
  initComponentState: any
) {
  let newComponentState = cloneDeep(initComponentState);

  if (propsValue) {
    Object.keys(newComponentState).forEach((key) => {
      newComponentState[key] = propsValue.get(key, newComponentState[key]);
    });
  }

  return newComponentState;
}

export function getFormattedDate() {
  const today = new Date();
  let monthStr = (today.getMonth() + 1).toString();
  let dateStr = today.getDate().toString();

  if (monthStr.length === 1) monthStr = "0" + monthStr;
  if (dateStr.length === 1) dateStr = "0" + dateStr;

  return today.getFullYear() + "-" + monthStr + "-" + dateStr;
}

export function fillInMissingBlockDataWithDefaults(
  blockData: any,
  defaultData: any
) {
  const newData = cloneDeep(blockData);
  Object.keys(defaultData).forEach((key: any) => {
    if (!(key in blockData)) {
      newData[key] = defaultData[key];
    }
  });
  return newData;
}
