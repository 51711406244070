/** @jsx jsx */

import { useState } from "react";
import {
  FullWidthInput,
  TextArea,
  ControllerSection,
  COLORS,
} from "../../style/styledComponents";
import { cloneDeep } from "lodash-es";
import { css, jsx } from "@emotion/react";
import XOptionsRadioButtons from "../PAGEBUILDER_UTILITY_COMPONENTS/XOptionsRadioButtons";
import ColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/ColorOptions";
import BlockExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockExpandableBar";
import ImageSelector from "../PAGEBUILDER_UTILITY_COMPONENTS/ImageSelector";
import CategoryExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/CategoryExpandableBar";
import { BLOCKNAMES, BLOCKS } from "../../blocks";
import BlockColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockColorOptions";
import VerticalPaddingSliders from "../PAGEBUILDER_UTILITY_COMPONENTS/VerticalPaddingSliders";
import VideoSelector from "../PAGEBUILDER_UTILITY_COMPONENTS/VideoSelector";

interface Props {
  content: any;
  index: number;
  handleBlockChange: Function;
  disabledStyle: string;
}

export const contactFormBlockInit = {
  loadingImg: { imageID: "", vFocus: 50, hFocus: 50, crop: true },
  smallLayout: false,
  video: {
    videoID: "",
    aspectRatioX: 16,
    aspectRatioY: 9,
  },
  height: 2,
  contentWidth: 0,
  titleColor: 0,
  textColor: 0,
  bgColor: 0,
  vPaddingTop: 30,
  vPaddingBottom: 30,
};

export const ContactFormBlockControl = ({
  content,
  index,
  handleBlockChange,
  disabledStyle,
}: Props) => {
  const handleValueChange = (key: string, value: string | boolean | number) => {
    const newContent = cloneDeep(content);
    newContent[key] = value;
    handleBlockChange({ ...newContent }, index);
  };

  return (
    <>
      <CategoryExpandableBar title="BLOCK OPTIONS" index={index}>
        <ControllerSection>
          <input
            type="checkbox"
            checked={content.smallLayout}
            onChange={() => {
              handleValueChange("smallLayout", !content.smallLayout);
            }}
            id="smallLayout"
          ></input>
          <label htmlFor="smallLayout">- Use small layout</label>
        </ControllerSection>
        <ControllerSection>
          <span>Content Width:</span>
          <XOptionsRadioButtons
            propName="contentWidth"
            currentVal={content.contentWidth}
            handleChange={handleValueChange}
            index={index}
            labels={["Full-width", "Extra padding"]}
          />
        </ControllerSection>
      </CategoryExpandableBar>
      <CategoryExpandableBar title="VIDEO" index={index}>
        <label>VIDEO</label>
        <VideoSelector
          propName="video"
          currentValue={
            content.video ? content.video : contactFormBlockInit.video
          }
          handleValueChange={handleValueChange}
          showCropControls
        />
        <ImageSelector
          propName="loadingImg"
          currentValue={
            content.loadingImg
              ? content.loadingImg
              : contactFormBlockInit.loadingImg
          }
          handleValueChange={handleValueChange}
          label="Loading Image (first frame of video)"
          croppedByDefault
        />
      </CategoryExpandableBar>
      <BlockColorOptions
        titleColor={content.titleColor}
        bgColor={content.bgColor}
        textColor={content.textColor}
        handleValueChange={handleValueChange}
      />
    </>
  );
};
export default ContactFormBlockControl;
