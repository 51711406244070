/** @jsx jsx */

import { colors } from "../../../styles/style";
import { css, jsx } from "@emotion/react";

type Props = {
  propName: string;
  currentVal: number;
  handleColorChange: Function;
  enableInherit?: boolean;
};

export default function ColorOptions({
  propName,
  currentVal,
  handleColorChange,
  enableInherit,
}: Props) {
  return (
    <div
      css={css`
        display: inline-flex;
      `}
    >
      {enableInherit && (
        <div
          onClick={() => {
            handleColorChange(propName, 0);
          }}
          style={{
            textDecoration: currentVal === 0 ? "underline" : "none",
          }}
          css={css`
            margin: 4px 5px 4px 0px;
            transition: transform 0.3s;
            line-height: 25px;
            &:hover {
              transform: scale(1.06);
              cursor: pointer;
            }
          `}
        >
          Same as page
        </div>
      )}
      {colors.map((color: string, index: number) => {
        if (index > 0) {
          return (
            <div
              css={css`
                width: 25px;
                height: 25px;
                margin: 4px 5px;
                transition: transform 0.3s;
                &:hover {
                  transform: scale(1.1);
                  cursor: pointer;
                }
              `}
              role="button"
              key={`color${propName}${index}`}
              onClick={() => {
                handleColorChange(propName, index);
              }}
              style={{
                border:
                  index == currentVal ? "3px solid black" : "1px solid black",
                backgroundColor: color,
              }}
            ></div>
          );
        }
      })}
    </div>
  );
}
