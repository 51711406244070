import { Link } from "gatsby";
import React, { ReactNode, useState } from "react";
import { menuItem } from "../cms/CMSComponents/HeaderBuilder";
import { colors } from "../styles/style";
import HiMenuAlt3 from "../images/icons/bars-solid";
import IoClose from "../images/icons/close-outline";
import { imageData } from "../cms/CMSComponents/PAGEBUILDER_UTILITY_COMPONENTS/ImageSelector";
import ImageComponent from "./ImageComponent";

type Props = {
  menuItems: menuItem[];
  titleColor: number;
  bgColor: number;
  logoImage: imageData;
};

export default function MobileMenu({
  menuItems,
  titleColor,
  bgColor,
  logoImage,
}: Props) {
  const [exp, setExp] = useState(false);

  return (
    <>
      <div
        style={{ color: colors[titleColor], backgroundColor: colors[bgColor] }}
        onClick={() => setExp(!exp)}
        className="fixed top-5 left-5 w-12 h-12 p-3 rounded-full z-10 block lg:hidden"
      >
        {exp ? (
          <IoClose
            className="w-full h-full"
            style={{ color: colors[titleColor] }}
          />
        ) : (
          <HiMenuAlt3
            className="w-full h-full"
            style={{ color: colors[titleColor] }}
          />
        )}
      </div>
      <nav
        className={`block lg:hidden overflow-hidden fixed left-0 top-0 w-screen max-w-lg h-screen z-0 pt-20 mobileMenuAnimation ${
          exp ? "mobileMenuExpanded" : "mobileMenuCollapsed"
        }`}
        style={{
          backgroundColor: colors[bgColor],
          pointerEvents: exp ? "auto" : "none",
        }}
      >
        <div className="w-32 h-32 absolute right-5 top-5">
          <Link to="/">
            <ImageComponent imageData={logoImage} crop={false} noPlugins />
          </Link>
        </div>
        <MenuItem
          text={"home"}
          linkUrl={"/"}
          children={[]}
          bgColor={bgColor}
          titleColor={titleColor}
        />
        {menuItems.map((item, index) => (
          <MenuItem
            key={"menu" + index}
            text={item.text}
            linkUrl={item.linkUrl}
            children={item.children}
            bgColor={bgColor}
            titleColor={titleColor}
          />
        ))}
        <div
          className="block h-full w-full mt-12"
          style={{ backgroundColor: colors[titleColor] }}
        ></div>
      </nav>
    </>
  );
}

type MobileMenuItem = menuItem & {
  bgColor: number;
  titleColor: number;
};

function MenuItem({
  children,
  text,
  linkUrl,
  bgColor,
  titleColor,
}: MobileMenuItem) {
  return (
    <div
      className="mx-16 my-4 font-nav"
      style={{
        backgroundColor: colors[bgColor],
        color: colors[titleColor],
        height: "fit-content",
      }}
    >
      <Link className="text-3xl " to={linkUrl}>
        {text}
      </Link>

      {/* {children.map((item) => (
        <Link
          className="text-xl py-2 text-center block transition-shadow hover:shadow-lg"
          to={item.linkUrl}
        >
          {item.text}
        </Link>
      ))} */}
    </div>
  );
}
