import React, { useEffect } from "react";
import { motion } from "framer-motion";

import IoClose from "../images/icons/close-outline";
import { videoData } from "../cms/CMSComponents/PAGEBUILDER_UTILITY_COMPONENTS/VideoSelector";
//import VimeoVideoComponentInline from "./VimeoVideoComponentInline";
import loadable from "@loadable/component";
const VimeoVideoComponentInline = loadable(
  () => import("./VimeoVideoComponentInline")
);

type LightboxProps = {
  video: videoData;
  setShowLightbox: Function;
  breakPoint: number;
};

export default function VideoLightbox({
  video,
  setShowLightbox,
  breakPoint,
}: LightboxProps) {
  function handleKey(e: KeyboardEvent) {
    if (e.key === "Escape") setShowLightbox(false);
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKey);
    return () => window.removeEventListener("keydown", handleKey);
  });

  const motionProps = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <motion.div
      {...motionProps}
      transition={{ duration: 0.5 }}
      className="fixed w-screen h-screen  top-0 left-0 select-none cursor-auto"
      style={{ backgroundColor: "rgba(0,0,0,0.9)", zIndex: 9999 }}
      onClick={() => setShowLightbox(false)}
    >
      <div
        style={{
          width: breakPoint === 0 ? "100vw" : "80vw",
          height: breakPoint === 0 ? "75vh" : "80vh",
        }}
        className="absolute flex left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
      >
        <VimeoVideoComponentInline videoData={video} autoplay />
      </div>

      <IoClose
        color="rgb(255,255,255)"
        className="absolute w-12 h-12 top-4 right-4 opacity-50 hover:opacity-100 active:opacity-10 transition-opacity"
        onClick={() => setShowLightbox(false)}
      />
    </motion.div>
  );
}
