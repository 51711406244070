import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "@reach/router";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import { colors } from "../styles/style";

type Props = {
  isShowingCookiePopup: boolean;
  setIsShowingCookiePopup: Function;
};

export default function CookieConsentPopup({
  isShowingCookiePopup,
  setIsShowingCookiePopup,
}: Props) {
  const location = useLocation();

  useEffect(() => {
    if (
      !getCookie("hasAskedCookieConsent") &&
      getCookie("gatsby-plugin-google-analytics-gdpr_cookies-enabled") !==
        "true"
    ) {
      setIsShowingCookiePopup(true);
    }
    return () => {};
  }, []);

  function onAccept() {
    if (typeof window !== "undefined") {
      setCookie("gatsby-gdpr-google-analytics", true, 365);
      setCookie("gatsby-gdpr-google-tagmanager", true, 365);
      setCookie("hasAskedCookieConsent", true, 365);
      setIsShowingCookiePopup(false);
      initializeAndTrack(location);
    }
  }

  function onReject() {
    if (typeof window !== "undefined") {
      setCookie("gatsby-gdpr-google-analytics", false, 10);
      setCookie("gatsby-gdpr-google-tagmanager", false, 365);
      setCookie("hasAskedCookieConsent", true, 10);
      setIsShowingCookiePopup(false);

      initializeAndTrack(location);
    }
  }

  return isShowingCookiePopup ? (
    <div
      className="w-screen fixed left-0 bottom-0 justify-center flex items-center flex-wrap py-4 fadeInSlow"
      style={{
        borderTop: `2px solid ${colors[3]}`,
        borderBottom: `2px solid ${colors[3]}`,

        backgroundColor: colors[1],
        zIndex: 9999,
        boxShadow: "0 -2px 6px #00000055, 0 2px 6px #00000055",
      }}
    >
      <img src="/blik_cookies.png" className="w-24 h-24"></img>
      <div
        className="min-w-320px max-w-600px px-8"
        style={{ color: colors[2] }}
      >
        <h3 className="text-2xl">
          <b>Cookies</b>
        </h3>
        <div>
          Wij gebruiken cookies om de manier waarop onze website gebruikt wordt
          te analyseren en zo onze website verder te verbeteren.
        </div>
      </div>
      <div className="flex flex-wrap justify-start px-8 mt-6 sm:mt-0">
        <button
          onClick={onAccept}
          className="w-32 text-center py-2 rounded-full mr-4 my-2"
          style={{
            backgroundColor: colors[3],
            color: colors[2],
          }}
        >
          Accepteren
        </button>
        <button
          onClick={onReject}
          style={{
            border: `1px solid ${colors[3]}`,
            color: colors[3],
          }}
          className="w-32 text-center py-2 my-2 rounded-full"
        >
          Weigeren
        </button>
      </div>
    </div>
  ) : (
    <></>
  );
}

export function setCookie(cName: string, cValue: any, exDays: number) {
  if (typeof window !== "undefined") {
    const d = new Date();
    d.setTime(d.getTime() + exDays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cName + "=" + cValue + ";" + expires + ";path=/";
  }
}

export function getCookie(cName: string) {
  if (typeof window !== "undefined") {
    let name = cName + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}
