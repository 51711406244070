import React, { ReactNode } from "react";

type Props = {
  isH2?: boolean;
  children?: ReactNode;
  [x: string]: any;
};

export default function H2H4Switch({ isH2, children, ...rest }: Props) {
  return (
    <>{isH2 ? <h2 {...rest}>{children}</h2> : <h4 {...rest}>{children}</h4>}</>
  );
}
