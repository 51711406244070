/** @jsx jsx */

import { useState, useRef } from "react";
import {
  FullWidthInput,
  TextArea,
  ControllerSection,
  COLORS,
} from "../../style/styledComponents";
import { cloneDeep } from "lodash-es";
import { css, jsx } from "@emotion/react";
import XOptionsRadioButtons from "../PAGEBUILDER_UTILITY_COMPONENTS/XOptionsRadioButtons";
import ColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/ColorOptions";
import BlockExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockExpandableBar";
import ImageSelector from "../PAGEBUILDER_UTILITY_COMPONENTS/ImageSelector";
import CategoryExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/CategoryExpandableBar";
import { BLOCKNAMES, BLOCKS } from "../../blocks";
import BlockColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockColorOptions";
import RichTextEditor from "../PAGEBUILDER_UTILITY_COMPONENTS/RichTextEditor";
import VerticalPaddingSliders from "../PAGEBUILDER_UTILITY_COMPONENTS/VerticalPaddingSliders";
import { Button } from "../../style/styledComponents";
import IoIosArrowDown from "../../../images/icons/angle-down-solid";
import IoIosArrowUp from "../../../images/icons/angle-up-solid";

interface Props {
  content: any;
  index: number;
  handleBlockChange: Function;
  disabledStyle: string;
}

export const vacaturesBlockInit = {
  vacatureAmt: 0,
  vacatures: [],
  titleColor: 0,
  textColor: 0,
  bgColor: 0,
  vPaddingTop: 30,
  vPaddingBottom: 30,
};

export const VacaturesBlockControl = ({
  content,
  index,
  handleBlockChange,
  disabledStyle,
}: Props) => {
  const [selectedVacature, setSelectedVacature] = useState(0);
  const selectedVacatureRef = useRef(0);

  const handleValueChange = (key: string, value: any) => {
    const newContent = cloneDeep(content);
    newContent[key] = value;
    handleBlockChange({ ...newContent }, index);
  };

  function handleVacatureSelect(index: number) {
    setSelectedVacature(index);
    selectedVacatureRef.current = index;
  }

  const initVacature = {
    subtitle: "",
    textShort: "",
    textLong: "",
  };

  type Vacature = {
    subtitle: string;
    textShort: string;
    textLong: string;
  };

  function handleAddVacature() {
    const newVacatures = cloneDeep(content.vacatures);
    newVacatures.push({ ...initVacature });
    handleValueChange("vacatures", newVacatures);
  }

  function handleRemoveVacature() {
    if (content.vacatures.length > 0) {
      const newVacatures = cloneDeep(content.vacatures).slice(0, -1);
      if (selectedVacatureRef.current < content.vacatures.length - 1) {
        selectedVacatureRef.current = content.vacatures.length - 2;
        setSelectedVacature(content.vacatures.length - 2);
      }
      handleValueChange("vacatures", newVacatures);
    }
  }

  function handleEditVacature(keyName: string, val: any) {
    const newVacatures = cloneDeep(content.vacatures);
    newVacatures[selectedVacatureRef.current][keyName] = val;
    handleValueChange("vacatures", newVacatures);
  }

  function moveForward(index: number) {
    const newArr = [...content.vacatures];
    const item = newArr.splice(index, 1);
    newArr.splice(index - 1, 0, ...item);
    handleValueChange("vacatures", newArr);
    setSelectedVacature(index - 1);
    selectedVacatureRef.current = index - 1;
  }

  function moveBackward(index: number) {
    const newArr = [...content.vacatures];
    const item = newArr.splice(index, 1);
    newArr.splice(index + 1, 0, ...item);
    handleValueChange("vacatures", newArr);
    setSelectedVacature(index + 1);
    selectedVacatureRef.current = index + 1;
  }
  console.log(selectedVacatureRef.current);
  console.log(content.vacatures);
  return (
    <>
      <ControllerSection>
        <label htmlFor="title">Title</label>
        <FullWidthInput
          type="text"
          value={content.title}
          onChange={(e) => handleValueChange("title", e.target.value)}
        />
      </ControllerSection>
      <Button
        css={css`
          margin-top: 10px;
        `}
        bgColor={COLORS.variation3}
        onClick={handleAddVacature}
      >
        Add vacature
      </Button>
      <Button bgColor={COLORS.variation3} onClick={handleRemoveVacature}>
        Remove vacature
      </Button>

      {!content.vacatures || content.vacatures.length === 0 ? (
        <i
          css={css`
            margin-bottom: 20px;
            display: block;
          `}
        >
          No vacatures added yet!
        </i>
      ) : (
        <>
          <div>Select which vacature you want to edit:</div>
          <div
            css={css`
              display: flex;
            `}
          >
            {content.vacatures.map((vacature: Vacature, index: number) => {
              return (
                <div
                  css={css`
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    margin: 10px 10px 10px 0;
                    text-align: center;
                    cursor: pointer;
                    border-radius: 5px;
                    color: ${index === selectedVacatureRef.current
                      ? COLORS.accent1
                      : COLORS.black};
                    background-color: ${index === selectedVacatureRef.current
                      ? COLORS.black
                      : "none"};
                  `}
                  onClick={() => handleVacatureSelect(index)}
                >
                  {index + 1}
                </div>
              );
            })}
          </div>
        </>
      )}

      {selectedVacatureRef.current <= content.vacatures?.length - 1 && (
        <div>
          <div
            css={css`
              display: flex;
            `}
          >
            <Button
              onClick={() => moveForward(selectedVacatureRef.current)}
              disabled={selectedVacatureRef.current === 0}
            >
              Move forward
            </Button>
            <Button
              css={css`
                margin-left: 10px;
              `}
              onClick={() => moveBackward(selectedVacatureRef.current)}
              disabled={
                selectedVacatureRef.current === content.vacatures?.length - 1
              }
            >
              Move backward
            </Button>
          </div>

          <ControllerSection>
            <label htmlFor="subtitle">Subtitle</label>
            <FullWidthInput
              type="text"
              value={content.vacatures[selectedVacatureRef.current].subtitle}
              onChange={(e) => handleEditVacature("subtitle", e.target.value)}
            />
          </ControllerSection>
          <ControllerSection>
            <span>Short Text</span>
            <RichTextEditor
              value={content.vacatures[selectedVacatureRef.current].textShort}
              propName="textShort"
              handleValueChange={handleEditVacature}
            />
          </ControllerSection>
          <ControllerSection>
            <span>
              Expanded Text (the part that should appear when the use clicks
              'lees meer')
            </span>
            <RichTextEditor
              value={content.vacatures[selectedVacatureRef.current].textLong}
              propName="textLong"
              handleValueChange={handleEditVacature}
            />
          </ControllerSection>
        </div>
      )}

      <CategoryExpandableBar title="BLOCK OPTIONS" index={index}>
        <VerticalPaddingSliders
          vPaddingBottom={content.vPaddingBottom}
          vPaddingTop={content.vPaddingTop}
          handleValueChange={handleValueChange}
        />
      </CategoryExpandableBar>
      <BlockColorOptions
        titleColor={content.titleColor}
        bgColor={content.bgColor}
        textColor={content.textColor}
        handleValueChange={handleValueChange}
      />
    </>
  );
};
export default VacaturesBlockControl;
