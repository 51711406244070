import { COLORS } from "./style/styledComponents";
import BlogPostsPreviewControl, {
  blogPostsPreviewInit,
} from "./CMSComponents/CONTROLLER_COMPONENTS/BlogPostsPreviewBlockControl";
import HeroBlockControl, {
  heroBlockInit,
} from "./CMSComponents/CONTROLLER_COMPONENTS/HeroBlockControl";
import TextImgBlockControl, {
  textImgBlockInit,
} from "./CMSComponents/CONTROLLER_COMPONENTS/TextImgBlockControl";
import TwoTextColumnsControl, {
  twoTextColumnsInit,
} from "./CMSComponents/CONTROLLER_COMPONENTS/TwoTextColumnsBlockControl";
import CreditsBlockControl, {
  creditsBlockInit,
} from "./CMSComponents/CONTROLLER_COMPONENTS/CreditsBlockControl";
import FictiePreviewBlockControl, {
  fictiePreviewBlockInit,
} from "./CMSComponents/CONTROLLER_COMPONENTS/FictiePreviewBlockControl";
import TextBoxControl, {
  textBoxInit,
} from "./CMSComponents/CONTROLLER_COMPONENTS/TextBoxBlockControl";
import InstagramBlockControl, {
  instagramBlockInit,
} from "./CMSComponents/CONTROLLER_COMPONENTS/InstagramBlockControl";
import StaffBlockControl, {
  staffBlockInit,
} from "./CMSComponents/CONTROLLER_COMPONENTS/StaffBlockControl";
import ImageGalleryControl, {
  ImageGalleryInit,
} from "./CMSComponents/CONTROLLER_COMPONENTS/ImageGalleryControl";
import HeroBlock from "../components/BLOCKS/HeroBlock";
import TextImgBlock from "../components/BLOCKS/TextImgBlock";
import TwoTextColumnsBlock from "../components/BLOCKS/TwoTextColumnsBlock";
import TextBoxBlock from "../components/BLOCKS/TextBoxBlock";
import CreditsBlock from "../components/BLOCKS/CreditsBlock";
import BlogPostsPreviewBlock from "../components/BLOCKS/BlogPostsPreviewBlock";
import FictiePreviewBlock from "../components/BLOCKS/FictiePreviewBlock";
import InstagramBlock from "../components/BLOCKS/InstagramBlock";
import StaffBlock from "../components/BLOCKS/StaffBlock";
import ImageGalleryBlock from "../components/BLOCKS/ImageGalleryBlock";
import FlexibleImageGalleryBlock from "../components/BLOCKS/FlexibleImageGalleryBlock";
import WerkLightPreviewBlockControl, {
  werkLightPreviewBlockInit,
} from "./CMSComponents/CONTROLLER_COMPONENTS/WerkLightPreviewBlockControl";
import WerkLightPreviewBlock from "../components/BLOCKS/WerkLightPreviewBlock";
import FlexibleImageGalleryBlockControl, {
  FlexibleImageGalleryBlockInit,
} from "./CMSComponents/CONTROLLER_COMPONENTS/FlexibleImageGalleryBlockControl";
import VideoGalleryControl, {
  VideoGalleryInit,
} from "./CMSComponents/CONTROLLER_COMPONENTS/VideoGalleryControl";
import VideoGalleryBlock from "../components/BLOCKS/VideoGalleryBlock";
import ButtonCTABlockControl, {
  buttonCTABlockInit,
} from "./CMSComponents/CONTROLLER_COMPONENTS/ButtonCTABlockControl";
import ButtonCTABlock from "../components/BLOCKS/ButtonCTABlock";
import TitleBlockControl, {
  titleBlockInit,
} from "./CMSComponents/CONTROLLER_COMPONENTS/TitleBlockControl";
import TitleBlock from "../components/BLOCKS/TitleBlock";
import MapBlockControl, {
  mapBlockInit,
} from "./CMSComponents/CONTROLLER_COMPONENTS/MapBlockControl";
import MapBlock from "../components/BLOCKS/MapBlock";
import HeroVideoBlock from "../components/BLOCKS/HeroVideoBlock";
import HeroVideoBlockControl, {
  heroVideoBlockInit,
} from "./CMSComponents/CONTROLLER_COMPONENTS/HeroVideoBlockControl";
import ImageVideoBlockControl, {
  imageVideoBlockInit,
} from "./CMSComponents/CONTROLLER_COMPONENTS/ImageVideoBlockControl";
import ImageVideoBlock from "../components/BLOCKS/ImageVideoBlock";
import AllBlogPostsBlockControl, {
  allBlogPostsBlockInit,
} from "./CMSComponents/CONTROLLER_COMPONENTS/AllBlogPostsBlockControl";
import AllBlogPostsBlock from "../components/BLOCKS/AllBlogPostsBlock";
import LatestWorkBlockControl, {
  LatestWorkBlockInit,
} from "./CMSComponents/CONTROLLER_COMPONENTS/LatestWorkBlockControl";
import LatestWorkBlock from "../components/BLOCKS/LatestWorkBlock";
import {
  ProductieProcesBlockControl,
  productieProcesBlockInit,
} from "./CMSComponents/CONTROLLER_COMPONENTS/ProductieProcesBlockControl";
import ProductieProcesBlock from "../components/BLOCKS/ProductieProcesBlock";
import TestimonialBlockControl, {
  testimonialBlockInit,
} from "./CMSComponents/CONTROLLER_COMPONENTS/TestimonialBlockControl";
import TestimonialsBlock from "../components/BLOCKS/TestimonialsBlock";
import ContactFormBlockControl, {
  contactFormBlockInit,
} from "./CMSComponents/CONTROLLER_COMPONENTS/ContactFormBlockControl";
import ContactFormBlock from "../components/BLOCKS/ContactFormBlock";
import KostenVideosBlockControl, {
  KostenVideosBlockInit,
} from "./CMSComponents/CONTROLLER_COMPONENTS/KostenVideosBlockControl";
import KostenVideosBlock from "../components/BLOCKS/KostenVideosBlock";
import VacaturesBlockControl, {
  vacaturesBlockInit,
} from "./CMSComponents/CONTROLLER_COMPONENTS/VacaturesBlockControl";
import VacaturesBlock from "../components/BLOCKS/VacaturesBlock";
import LogoBlockControl, {
  logoBlockInit,
} from "./CMSComponents/CONTROLLER_COMPONENTS/LogoBlockControl";
import LogoBlock from "../components/BLOCKS/LogoBlock";

export const BLOCKNAMES = {
  textImgBlock: "Text-Image Block",
  heroBlock: "Hero-Image / Title Block",
  heroVideoBlock: "Hero Video Block",
  blogPostsPreview: "Preview Blog Posts",
  twoTextColumns: "Two Text Columns",
  textBoxBlock: "Text-box Block",
  creditsBlock: "Credits Text Block",
  fictiePreviewBlock: "Fictie Preview Block",
  instagramBlock: "Instagram Posts Block",
  staffBlock: "Staff / Products Block",
  imageGalleryBlock: "Image Gallery Block",
  imageVideoBlock: "Single Image / Video",
  werkLightPreviewBlock: "Werk/Light preview Block",
  flexibleImageGalleryBlock: "Flexible Image Gallery Block",
  videoGalleryBlock: "Video Gallery Block",
  linkCTABlock: "Link / CTA",
  titleBlock: "Title Block",
  mapBlock: "Google Maps Block",
  allBlogPostsBlock: "Blog Posts Overview",
  LatestWorkBlock: "Latest Work Block",
  productieProcesBlock: "Productieproces Block",
  testimonialsBlock: "Testimonials Block",
  contactFormBlock: "Contact Form Block",
  kostenVideosBlock: "Kosten Videos Block",
  vacaturesBlock: "Vacatures Block",
  logoBlock: "Logo Block",
};

export type BlockType = {
  blockName: string;
  description: string;
  blockContent: string | BlockContentType;
  color: string;
  controlComponent: Function;
  layoutComponent: Function;
  category: string;
};

export const blockCategories = {
  text: "Text",
  image: "Image / Video",
  custom: "Custom",
};

export type BlockContentType = any;

export const BLOCKS: BlockType[] = [
  {
    blockName: BLOCKNAMES.heroBlock,
    color: COLORS.variation2,
    description:
      "A Block big images or videos, with an optional title text displayed over it",
    blockContent: { ...heroBlockInit },
    controlComponent: HeroBlockControl,
    layoutComponent: HeroBlock,
    category: blockCategories.image,
  },
  {
    blockName: BLOCKNAMES.textImgBlock,
    color: COLORS.variation1,
    description: "A Block with an image on one side, and text on the other",
    blockContent: { ...textImgBlockInit },
    controlComponent: TextImgBlockControl,
    layoutComponent: TextImgBlock,
    category: blockCategories.image,
  },
  {
    blockName: BLOCKNAMES.twoTextColumns,
    color: COLORS.variation4,
    description: "Two text columns, with optional titles and background image.",
    blockContent: {
      ...twoTextColumnsInit,
    },
    controlComponent: TwoTextColumnsControl,
    layoutComponent: TwoTextColumnsBlock,
    category: blockCategories.text,
  },
  {
    blockName: BLOCKNAMES.textBoxBlock,
    color: COLORS.variation7,
    description: "Regular text, can also be used for titles.",
    blockContent: {
      ...textBoxInit,
    },
    controlComponent: TextBoxControl,
    layoutComponent: TextBoxBlock,
    category: blockCategories.text,
  },
  {
    blockName: BLOCKNAMES.titleBlock,
    color: COLORS.variation4,
    description: "Simple block for titles.",
    blockContent: {
      ...titleBlockInit,
    },
    controlComponent: TitleBlockControl,
    layoutComponent: TitleBlock,
    category: blockCategories.text,
  },
  {
    blockName: BLOCKNAMES.creditsBlock,
    color: COLORS.variation5,
    description: "Two centered text columns, ideal for credits",
    blockContent: {
      ...creditsBlockInit,
    },
    controlComponent: CreditsBlockControl,
    layoutComponent: CreditsBlock,
    category: blockCategories.text,
  },
  {
    blockName: BLOCKNAMES.blogPostsPreview,
    color: COLORS.variation3,
    description: "A list of previews of the latest blog posts",
    blockContent: {
      ...blogPostsPreviewInit,
    },
    controlComponent: BlogPostsPreviewControl,
    layoutComponent: BlogPostsPreviewBlock,
    category: blockCategories.custom,
  },
  {
    blockName: BLOCKNAMES.fictiePreviewBlock,
    color: COLORS.variation6,
    description: "Customizable preview for portfolio 'Fictie'",
    blockContent: {
      ...fictiePreviewBlockInit,
    },
    controlComponent: FictiePreviewBlockControl,
    layoutComponent: FictiePreviewBlock,
    category: blockCategories.custom,
  },
  {
    blockName: BLOCKNAMES.werkLightPreviewBlock,
    color: COLORS.variation6,
    description:
      "Customizable preview for portfolio 'Werk' and 'Light', with intro text and theme switch",
    blockContent: {
      ...werkLightPreviewBlockInit,
    },
    controlComponent: WerkLightPreviewBlockControl,
    layoutComponent: WerkLightPreviewBlock,
    category: blockCategories.custom,
  },
  {
    blockName: BLOCKNAMES.instagramBlock,
    color: COLORS.variation7,
    description: "Shows the latest posts of an instagram account",
    blockContent: {
      ...instagramBlockInit,
    },
    controlComponent: InstagramBlockControl,
    layoutComponent: InstagramBlock,
    category: blockCategories.custom,
  },
  {
    blockName: BLOCKNAMES.staffBlock,
    color: COLORS.variation8,
    description:
      "List of images and labels, ideal for a list of staff or products",
    blockContent: {
      ...staffBlockInit,
    },
    controlComponent: StaffBlockControl,
    layoutComponent: StaffBlock,
    category: blockCategories.image,
  },
  {
    blockName: BLOCKNAMES.imageGalleryBlock,
    color: COLORS.variation1,
    description:
      "An image gallery, the user can click on each image to view a larger version",
    blockContent: {
      ...ImageGalleryInit,
    },
    controlComponent: ImageGalleryControl,
    layoutComponent: ImageGalleryBlock,
    category: blockCategories.image,
  },
  {
    blockName: BLOCKNAMES.flexibleImageGalleryBlock,
    color: COLORS.variation2,
    description: "An image gallery with customizable rows and image sizes",
    blockContent: {
      ...FlexibleImageGalleryBlockInit,
    },
    controlComponent: FlexibleImageGalleryBlockControl,
    layoutComponent: FlexibleImageGalleryBlock,
    category: blockCategories.image,
  },
  {
    blockName: BLOCKNAMES.videoGalleryBlock,
    color: COLORS.variation3,
    description: "A gallery of embedded video's",
    blockContent: {
      ...VideoGalleryInit,
    },
    controlComponent: VideoGalleryControl,
    layoutComponent: VideoGalleryBlock,
    category: blockCategories.image,
  },
  {
    blockName: BLOCKNAMES.linkCTABlock,
    color: COLORS.variation4,
    description: "A button for a link or CTA",
    blockContent: {
      ...buttonCTABlockInit,
    },
    controlComponent: ButtonCTABlockControl,
    layoutComponent: ButtonCTABlock,
    category: blockCategories.text,
  },
  {
    blockName: BLOCKNAMES.mapBlock,
    color: COLORS.variation6,
    description: "Embed a google maps location on your website",
    blockContent: {
      ...mapBlockInit,
    },
    controlComponent: MapBlockControl,
    layoutComponent: MapBlock,
    category: blockCategories.custom,
  },
  {
    blockName: BLOCKNAMES.heroVideoBlock,
    color: COLORS.variation5,
    description: "A big video player with playback controls",
    blockContent: {
      ...heroVideoBlockInit,
    },
    controlComponent: HeroVideoBlockControl,
    layoutComponent: HeroVideoBlock,
    category: blockCategories.image,
  },
  {
    blockName: BLOCKNAMES.imageVideoBlock,
    color: COLORS.variation3,
    description: "A single image or embedded Vimeo or Youtube video",
    blockContent: {
      ...imageVideoBlockInit,
    },
    controlComponent: ImageVideoBlockControl,
    layoutComponent: ImageVideoBlock,
    category: blockCategories.image,
  },
  {
    blockName: BLOCKNAMES.allBlogPostsBlock,
    color: COLORS.variation8,
    description: "A list of all blog posts, sortable by their tags",
    blockContent: {
      ...allBlogPostsBlockInit,
    },
    controlComponent: AllBlogPostsBlockControl,
    layoutComponent: AllBlogPostsBlock,
    category: blockCategories.custom,
  },
  {
    blockName: BLOCKNAMES.LatestWorkBlock,
    color: COLORS.variation8,
    description: "Links to latest Portfolio Items",
    blockContent: {
      ...LatestWorkBlockInit,
    },
    controlComponent: LatestWorkBlockControl,
    layoutComponent: LatestWorkBlock,
    category: blockCategories.custom,
  },
  {
    blockName: BLOCKNAMES.productieProcesBlock,
    color: COLORS.variation8,
    description: "Productieproces",
    blockContent: {
      ...productieProcesBlockInit,
    },
    controlComponent: ProductieProcesBlockControl,
    layoutComponent: ProductieProcesBlock,
    category: blockCategories.custom,
  },
  {
    blockName: BLOCKNAMES.testimonialsBlock,
    color: COLORS.variation2,
    description: "Testimonials",
    blockContent: {
      ...testimonialBlockInit,
    },
    controlComponent: TestimonialBlockControl,
    layoutComponent: TestimonialsBlock,
    category: blockCategories.custom,
  },
  {
    blockName: BLOCKNAMES.contactFormBlock,
    color: COLORS.variation4,
    description: "Contact Form",
    blockContent: {
      ...contactFormBlockInit,
    },
    controlComponent: ContactFormBlockControl,
    layoutComponent: ContactFormBlock,
    category: blockCategories.custom,
  },
  {
    blockName: BLOCKNAMES.kostenVideosBlock,
    color: COLORS.variation2,
    description: "3 Video links with text overlay",
    blockContent: {
      ...KostenVideosBlockInit,
    },
    controlComponent: KostenVideosBlockControl,
    layoutComponent: KostenVideosBlock,
    category: blockCategories.custom,
  },
  {
    blockName: BLOCKNAMES.vacaturesBlock,
    color: COLORS.variation1,
    description: "Multiple pages of expandable text",
    blockContent: {
      ...vacaturesBlockInit,
    },
    controlComponent: VacaturesBlockControl,
    layoutComponent: VacaturesBlock,
    category: blockCategories.custom,
  },
  {
    blockName: BLOCKNAMES.logoBlock,
    color: COLORS.variation3,
    description: "Displays 14 logo's",
    blockContent: {
      ...logoBlockInit,
    },
    controlComponent: LogoBlockControl,
    layoutComponent: LogoBlock,
    category: blockCategories.custom,
  },
];
