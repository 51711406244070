/** @jsx jsx */

import { cloneDeep } from "lodash-es";
import { css, jsx } from "@emotion/react";
import CategoryExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/CategoryExpandableBar";
import VideoSelector from "../PAGEBUILDER_UTILITY_COMPONENTS/VideoSelector";
import XOptionsRadioButtons from "../PAGEBUILDER_UTILITY_COMPONENTS/XOptionsRadioButtons";
import {
  ControllerSection,
  FullWidthInput,
} from "../../style/styledComponents";
import ImageSelector from "../PAGEBUILDER_UTILITY_COMPONENTS/ImageSelector";
import BlockColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockColorOptions";
import RichTextEditor from "../PAGEBUILDER_UTILITY_COMPONENTS/RichTextEditor";

interface Props {
  content: any;
  index: number;
  handleBlockChange: Function;
  disabledStyle: string;
}

export const heroVideoBlockInit = {
  video: {
    videoID: "",
    aspectRatioX: 16,
    aspectRatioY: 9,
  },
  title2: "",
  subtitle2: "",
  loadingImg: { imageID: "", vFocus: 50, hFocus: 50, crop: true },
  displayScrollIcon: false,
  height: 0,
  linkText: "Bekijk volledige video",
};

export const HeroVideoBlockControl = ({
  content,
  index,
  handleBlockChange,
  disabledStyle,
}: Props) => {
  const handleValueChange = (key: string, value: string | boolean | number) => {
    const newContent = cloneDeep(content);
    newContent[key] = value;
    handleBlockChange({ ...newContent }, index);
  };

  return (
    <>
      <CategoryExpandableBar title="VIDEO" index={index}>
        <label>VIDEO</label>
        <VideoSelector
          propName="video"
          currentValue={content.video}
          handleValueChange={handleValueChange}
          showCropControls
        />
        <ImageSelector
          propName="loadingImg"
          currentValue={
            content.loadingImg
              ? content.loadingImg
              : heroVideoBlockInit.loadingImg
          }
          handleValueChange={handleValueChange}
          label="Loading Image (first frame of video)"
          croppedByDefault
        />
        <ControllerSection>
          <span>Video Height</span>
          <XOptionsRadioButtons
            propName="height"
            currentVal={content.height}
            handleChange={handleValueChange}
            index={index}
            labels={["1/6", "1/4", "1/3", "1/2", "3/4", "Fill screen"]}
          />
        </ControllerSection>
        <ControllerSection>
          <input
            type="checkbox"
            checked={content.displayScrollIcon}
            onChange={() => {
              handleValueChange(
                "displayScrollIcon",
                !content.displayScrollIcon
              );
            }}
          ></input>
          <label>Display Scroll Icon</label>
        </ControllerSection>
      </CategoryExpandableBar>
      <CategoryExpandableBar title="TEXT" index={index}>
        <ControllerSection>
          <label htmlFor="title">Title</label>
          <RichTextEditor
            value={content.title2 ? content.title2 : ""}
            propName="title2"
            handleValueChange={handleValueChange}
          />
        </ControllerSection>
        <ControllerSection>
          <label htmlFor="subtitle">Subtitle</label>
          <RichTextEditor
            value={content.subtitle2 ? content.subtitle2 : ""}
            propName="subtitle2"
            handleValueChange={handleValueChange}
          />
        </ControllerSection>
        <ControllerSection>
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <input
              type="checkbox"
              checked={content.isMainTitle}
              onChange={() => {
                handleValueChange("isMainTitle", !content.isMainTitle);
              }}
            ></input>
            <label style={{ fontWeight: 700 }}>
              This block is the main title of this page (use H1 and H2 instead
              of H3 and H4 elements for the title)
            </label>
          </div>
        </ControllerSection>
        <ControllerSection>
          <label htmlFor="buttonText">
            Button text (leave empty to hide button)
          </label>
          <FullWidthInput
            name="buttonText"
            id="buttonText"
            type="text"
            value={content.buttonText}
            onChange={(e) => handleValueChange("buttonText", e.target.value)}
          />
        </ControllerSection>
      </CategoryExpandableBar>
      <BlockColorOptions
        titleColor={content.titleColor}
        bgColor={content.bgColor}
        textColor={content.textColor}
        handleValueChange={handleValueChange}
      />
    </>
  );
};
export default HeroVideoBlockControl;
