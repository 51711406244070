/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import React, { useEffect } from "react";
import HighlightBlock from "./HighlightBlock";

import {
  ButtonCTA,
  colors,
  getXPaddingAmtFromNumber,
  vPaddingMultiplier,
} from "../../styles/style";
import { fillInMissingBlockDataWithDefaults } from "../../cms/functions";

import { mapBlockInit } from "../../cms/CMSComponents/CONTROLLER_COMPONENTS/MapBlockControl";
import { ChangeEvent, useReducer, useRef, useState } from "react";
import VimeoVideoComponent from "../VimeoVideoComponent";
import ImageComponent from "../ImageComponent";

type Props = {
  blockContent: string;
  index: number;
  highlighted: boolean;
  pageTitleColor: number;
  breakPoint: number;
  showVideoLightbox: boolean;
};

type FormStateType = {
  name: string;
  company: string;
  phone: string;
  email: string;
  question: string;
};

function ContactFormBlock({
  blockContent,
  index,
  highlighted,
  pageTitleColor,
  breakPoint,
  showVideoLightbox,
}: Props) {
  const DATA = fillInMissingBlockDataWithDefaults(
    JSON.parse(blockContent),
    mapBlockInit
  );

  const initState: FormStateType = {
    name: "",
    company: "",
    phone: "",
    email: "",
    question: "",
  };

  const reducer = (state: FormStateType, update: Partial<FormStateType>) => {
    return { ...state, ...update };
  };
  const sizeRef = useRef();
  const updateRef = useRef(true);
  const [update, setUpdate] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [formState, dispatch] = useReducer(reducer, initState);
  const formContentRef = useRef(initState);
  const formRef = useRef<HTMLFormElement>(null);
  const [submitState, setSubmitState] = useState<
    "form" | "processing" | "success" | "error"
  >("form");

  useEffect(() => {
    const updateFunc = () => {
      updateRef.current = !updateRef.current;
      setUpdate(updateRef.current);
    };
    if (typeof window !== "undefined") {
      if (document.readyState === "complete") {
        setLoaded(true);
      }
      window.addEventListener("load", () => setLoaded(true));
      window.addEventListener("resize", updateFunc);
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("load", () => setLoaded(true));
        window.removeEventListener("resize", updateFunc);
      }
    };
  }, []);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch({ [e.target.name]: e.target.value });
    formContentRef.current = {
      ...formContentRef.current,
      [e.target.name]: e.target.value,
    };
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const data: { [x: string]: any } = { ...formContentRef.current };
    const url = `/.netlify/functions/sendContactFormMail?data=${JSON.stringify(
      data
    )}`;

    try {
      setSubmitState("processing");
      const response = await fetch(url);
      if (response.status === 200) {
        setSubmitState("success");
        if (typeof window !== "undefined") {
          window.dataLayer?.push({ event: "formSubmit" });
        }
      } else {
        console.log(response);
        setSubmitState("error");
        if (typeof window !== "undefined") {
          window.dataLayer?.push({ event: "formError" });
        }
      }
    } catch (e) {
      console.warn(e);
      setSubmitState("error");
    }
  };

  let xPaddingAmt = getXPaddingAmtFromNumber(DATA.contentWidth, breakPoint);
  return (
    <section
      id={"container" + index}
      className={`relative w-screen transition-colors flex flex-col justify-center  items-center overflow-hidden -mt-16 h-800px md:h-600px`}
      style={{
        backgroundColor: colors[DATA.bgColor],
        padding: `${DATA.vPaddingTop * vPaddingMultiplier}px ${xPaddingAmt}px ${
          DATA.vPaddingBottom * vPaddingMultiplier
        }px`,
      }}
    >
      {(DATA.video?.videoID || DATA.loadingImg?.imageID) && (
        <>
          <div
            ref={sizeRef}
            id={"container" + index}
            className={`absolute top-0 left-0 w-full h-full hidden sm:block  overflow-hidden z-0`}
          >
            {!showVideoLightbox && (
              <VimeoVideoComponent
                videoData={{
                  ...DATA.video,
                  vFocus: DATA.loadingImg?.vFocus,
                  hFocus: DATA.loadingImg?.hFocus,
                }}
                containerSize={{
                  x: sizeRef.current?.clientWidth,
                  y: sizeRef.current?.clientHeight,
                }}
                playing={true}
                setLoaded={setLoaded}
                keyStr={"contactFormVideo" + index}
              />
            )}
          </div>
          <div
            className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 overflow-hidden z-10 delay-1000`}
            style={{
              opacity: loaded ? 0 : 1,
              backgroundColor: colors[DATA.bgColor],
            }}
          >
            {DATA.loadingImg && (
              <ImageComponent
                imageData={DATA.loadingImg}
                crop={true}
                noPlugins
              />
            )}
          </div>
        </>
      )}
      {!DATA.smallLayout && (
        <div
          className="absolute rounded-full left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-0"
          style={{
            backgroundColor: colors[DATA.titleColor],
            width: "1100px",
            height: "1100px",
          }}
        ></div>
      )}
      {submitState === "form" && (
        <div
          className={
            DATA.smallLayout
              ? "lg:w-720px z-10 flex flex-col items-left justify-center my-16 md:my-0 ml-0 lg:ml-80 xl:ml-1/3screen relative"
              : "lg:w-720px z-10 flex flex-col items-center justify-center my-16 md:my-0"
          }
        >
          {DATA.smallLayout && (
            <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-0 lg:w-800px lg:opacity-80 opacity-100 bg-black lg:bg-blikGreen h-800px md:h-600px w-screen"></div>
          )}
          <h3
            className="z-10 text-4xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl font-title -ml-0.75 sm:-ml-0.5 lg:-ml-1 font-normal"
            style={{ color: colors[DATA.textColor] }}
          >
            Vrijblijvend kennismaken?
          </h3>
          <h4
            className="z-10 lg:text-lg mt-4"
            style={{ color: colors[DATA.textColor] }}
          >
            Kom geheel vrijblijvend kennis met ons maken en vertel ons waar je
            naar op zoek bent. Dat kan zowel telefonisch als bij ons op kantoor.
            Vul onderstaand formulier in en wij nemen contact op om een afspraak
            in te plannen.
          </h4>
          <form
            id="contactForm"
            onSubmit={handleSubmit}
            className="md:flex relative mt-10 w-full  max-w-screen"
          >
            <div
              className={`md:mr-4 max-w-screen ${
                DATA.smallLayout ? "md:w-300px" : "md:w-350px"
              } w-full`}
            >
              <TextInput
                name="name"
                onChange={onChange}
                value={formState.name}
                placeHolder="Naam"
                color={colors[DATA.textColor]}
              />
              <TextInput
                name="company"
                onChange={onChange}
                value={formState.company}
                placeHolder="Bedrijf"
                color={colors[DATA.textColor]}
              />
              <TextInput
                name="email"
                type="email"
                onChange={onChange}
                value={formState.email}
                placeHolder="Mail"
                color={colors[DATA.textColor]}
              />
              <TextInput
                name="phone"
                onChange={onChange}
                value={formState.phone}
                placeHolder="Telefoonnummer"
                color={colors[DATA.textColor]}
              />
            </div>
            <div
              className={`md:ml-4 ${
                DATA.smallLayout ? "md:w-300px" : "md:w-350px"
              } w-full max-w-screen`}
            >
              <TextAreaInput
                name="question"
                placeholder="Vraag"
                onChange={onChange}
                value={formState.question}
                color={colors[DATA.textColor]}
              />
              <SubmitButton
                color1={colors[DATA.textColor]}
                color2={colors[DATA.titleColor]}
              />
            </div>
          </form>
        </div>
      )}
      {submitState === "processing" && (
        <div className="z-10 flex flex-col items-center justify-center my-16 md:my-0">
          <h2 style={{ color: colors[DATA.textColor] }}>
            Je vraag wordt verzonden, even geduld alsjeblieft!
          </h2>
        </div>
      )}
      {submitState === "success" && (
        <div className="z-10 flex flex-col items-center justify-center my-16 md:my-0">
          <h1 style={{ color: colors[DATA.textColor] }}>Super leuk!</h1>

          <h2 style={{ color: colors[DATA.textColor] }}>
            We nemen zo snel mogelijk contact met je op
          </h2>
        </div>
      )}
      {submitState === "error" && (
        <div className="z-10 flex flex-col items-center justify-center my-16 md:my-0">
          <h1 style={{ color: colors[DATA.textColor] }}>
            Sorry, er is iets misgegaan!
          </h1>
          <h2 style={{ color: colors[DATA.textColor] }}>
            Je vraag kon op dit moment niet verstuurd worden, probeer het later
            opnieuw.
          </h2>
        </div>
      )}
      {highlighted && <HighlightBlock />}
    </section>
  );
}

export default ContactFormBlock;

type InputProps = {
  placeHolder: string;
  name: string;
  value: string;
  color: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  type?: "email" | "phone";
};

export function TextInput({
  placeHolder,
  name,
  onChange,
  value,
  color,
  type,
}: InputProps) {
  return (
    <input
      className="w-full block h-10 mb-4 font-nav px-6 focus:outline-none bg-transparent placeholder-white placeholder-opacity-70 rounded-full border-2 "
      style={{ color: color, borderColor: color }}
      type={type ? type : "text"}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeHolder}
      required={true}
    ></input>
  );
}

type TextAreaInputProps = {
  placeholder: string;
  name: string;
  value: string;
  color: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
};

export function TextAreaInput({
  placeholder,
  name,
  value,
  onChange,
  color,
}: TextAreaInputProps) {
  return (
    <textarea
      placeholder={placeholder}
      style={{
        color: color,
        borderColor: color,
        height: "152px",
        marginBottom: "10px",
      }}
      className="w-full border-slate-300 rounded-3xl  font-nav p-4 resize-none focus:outline-none bg-transparent placeholder-white placeholder-opacity-70 border-2"
      rows={3}
      name={name}
      onChange={onChange}
      value={value}
      required
    ></textarea>
  );
}

function SubmitButton({ color1, color2 }: any) {
  const [hover, setHover] = useState(false);

  return (
    <button
      role="submit"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className="w-full text-center h-10 border-2 font-nav rounded-full block transition-colors mt-8 md:mt-0"
      style={{
        color: !hover ? color2 : color1,
        backgroundColor: !hover ? color1 : color2,
        borderColor: color1,
      }}
    >
      Verstuur
    </button>
  );
}
