/** @jsx jsx */

import React from "react";
import {
  FullWidthInput,
  Input,
  TextArea,
  ControllerSection,
  COLORS,
  ExplanationP,
} from "../../style/styledComponents";
import HoverHelp from "../PAGEBUILDER_UTILITY_COMPONENTS/HoverHelp";
import { css, jsx } from "@emotion/react";

import vimeoHint from "../../img/vimeoHint.jpg";

type Props = {
  propName: string;
  handleValueChange: Function;
  currentValue: videoData;
  showCropControls?: boolean;
};

export type videoData = {
  videoID: number;
  aspectRatioX: number;
  aspectRatioY: number;
  hFocus?: number;
  vFocus?: number;
};

export default function VideoSelector({
  currentValue,
  propName,
  handleValueChange,
  showCropControls,
}: Props) {
  function handleSubValueChange(
    key: "videoID" | "aspectRatioX" | "aspectRatioY" | "hFocus" | "vFocus",
    value: number
  ) {
    let newState = { ...currentValue };
    newState[key] = value;
    handleValueChange(propName, newState);
  }

  return (
    <>
      <ControllerSection>
        <label>Video ID</label>
        <HoverHelp title="Where can I find my video's ID-number?">
          <div className="w-72">
            <img src={vimeoHint} className="w-full bg-white rounded-md" />
            <ExplanationP>
              Go to your video in Vimeo. The URL in your browser will end in a
              number - copy and paste that number here.
            </ExplanationP>
          </div>
        </HoverHelp>
        <FullWidthInput
          type="text"
          value={currentValue.videoID}
          onChange={(e) => handleSubValueChange("videoID", e.target.value)}
        />{" "}
      </ControllerSection>
      <ControllerSection>
        <label>Video Aspect Ratio</label>
        <div
          css={css`
            display: flex;
            width: 100px;
          `}
        >
          <input
            type="number"
            css={css`
              width: 50px;
            `}
            value={currentValue.aspectRatioX}
            onChange={(e) =>
              handleSubValueChange("aspectRatioX", parseInt(e.target.value))
            }
          />
          <div>:</div>
          <input
            type="number"
            css={css`
              width: 50px;
            `}
            value={currentValue.aspectRatioY}
            onChange={(e) =>
              handleSubValueChange("aspectRatioY", parseInt(e.target.value))
            }
          />
        </div>
      </ControllerSection>
      {showCropControls && (
        <>
          <ControllerSection>
            <label htmlFor="hFocus">
              Horizontal Video Focus:{" "}
              {typeof currentValue.hFocus !== "undefined"
                ? currentValue.hFocus
                : 50}
              %
            </label>
            <ExplanationP>
              dictates which part of the video is shown when it is cropped
              horizontally
            </ExplanationP>
            <Input
              type="range"
              id="hFocusSlider"
              min={0}
              max={100}
              step={1}
              value={
                typeof currentValue.hFocus !== "undefined"
                  ? currentValue.hFocus
                  : 50
              }
              onChange={(e) =>
                handleSubValueChange("hFocus", parseInt(e.target.value, 10))
              }
            ></Input>
          </ControllerSection>
          <ControllerSection>
            <label htmlFor="vFocus">
              Vertical Video Focus:{" "}
              {typeof currentValue.vFocus !== "undefined"
                ? currentValue.vFocus
                : 50}
              %
            </label>
            <ExplanationP>
              dictates which part of the video is shown when it is cropped
              vertically
            </ExplanationP>
            <Input
              type="range"
              id="vFocusSlider"
              min={0}
              max={100}
              step={1}
              value={
                typeof currentValue.vFocus !== "undefined"
                  ? currentValue.vFocus
                  : 50
              }
              onChange={(e) =>
                handleSubValueChange("vFocus", parseInt(e.target.value, 10))
              }
            ></Input>
          </ControllerSection>
        </>
      )}
    </>
  );
}
