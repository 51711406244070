import React, { ReactNode } from "react";

type Props = {
  isH1?: boolean;
  children?: ReactNode;
  [x: string]: any;
};

export default function H1H3Switch({ isH1, children, ...rest }: Props) {
  return (
    <>{isH1 ? <h1 {...rest}>{children}</h1> : <h3 {...rest}>{children}</h3>}</>
  );
}
