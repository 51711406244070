/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import ImageComponent from "../ImageComponent";
import HighlightBlock from "./HighlightBlock";
import { useState } from "react";
import { ButtonCTA, colors, vPaddingMultiplier } from "../../styles/style";
import { fillInMissingBlockDataWithDefaults } from "../../cms/functions";
import { creditsBlockInit } from "../../cms/CMSComponents/CONTROLLER_COMPONENTS/CreditsBlockControl";
import Expand from "react-expand-animated";
import { motion } from "framer-motion";
import useScrollInViewAnimation from "../../hooks/UseScrollInViewAnimation";
import ANIMATIONS from "../../styles/animations";

type Props = {
  blockContent: string;
  index: number;
  highlighted: boolean;
  pageTitleColor: number;
};

function CreditsBlock({
  blockContent,
  index,
  highlighted,
  pageTitleColor,
}: Props) {
  const [exp, setExp] = useState(false);
  const [hover, setHover] = useState(false);
  const transitions = ["height", "opacity", "background"];

  const DATA = fillInMissingBlockDataWithDefaults(
    JSON.parse(blockContent),
    creditsBlockInit
  );

  let widthClass = "w-full";
  if (DATA.textBoxWidth === 1) widthClass = "md:w-5/6";
  if (DATA.textBoxWidth === 2) widthClass = "md:w-3/4";
  if (DATA.textBoxWidth === 3) widthClass = "md:w-1/2";

  const lineAmt = DATA.lineAmt ? DATA.lineAmt : 8;

  const [titleRef, titleControls] = useScrollInViewAnimation(true);
  const [itemsRef, itemsControls] = useScrollInViewAnimation(true);

  return (
    <section
      id={"container" + index}
      className={`relative  w-screen  transition-colors`}
      style={{
        backgroundColor: colors[DATA.bgColor],
        paddingTop: `${DATA.vPaddingTop * vPaddingMultiplier}px`,
        paddingBottom: `${DATA.vPaddingBottom * vPaddingMultiplier}px`,
      }}
    >
      <motion.div
        ref={titleRef}
        animate={titleControls}
        variants={ANIMATIONS.fadeScale}
        initial="hidden"
      >
        <h3
          className={`text-center m-auto text-4xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl font-title -ml-0.75 sm:-ml-0.5 lg:-ml-1 font-normal`}
          style={{
            color:
              DATA.titleColor === 0
                ? colors[pageTitleColor]
                : colors[DATA.titleColor],
          }}
        >
          {DATA.title}
        </h3>
        <h4
          className={`text-center m-auto text-2xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-4xl font-title sm:-ml-0.5 font-normal`}
          style={{
            color: colors[DATA.textColor],
          }}
        >
          {DATA.subtitle}
        </h4>
      </motion.div>
      <div
        ref={itemsRef}
        style={{
          color: colors[DATA.textColor],
        }}
      >
        <div className="flex sm:mt-8 mt-4">
          <motion.div
            initial="hidden"
            variants={ANIMATIONS.staggerContainerFast}
            animate={itemsControls}
            className="w-1/2 px-2 text-xs sm:text-base"
          >
            {DATA.textLines.map((line: string[], index: number) => {
              if (index < lineAmt) {
                return (
                  <motion.span
                    variants={ANIMATIONS.fade}
                    className="text-right block h-6 whitespace-nowrap"
                    key={"credits" + index}
                  >
                    {line[0]}
                  </motion.span>
                );
              } else return "";
            })}
            <Expand open={exp} duration={200} transitions={transitions}>
              {DATA.textLines.map((line: string[], index: number) => {
                if (index >= lineAmt) {
                  return (
                    <motion.span
                      variants={ANIMATIONS.fade}
                      className="text-right block h-6 whitespace-nowrap"
                      key={"credits" + index}
                    >
                      {line[0]}
                    </motion.span>
                  );
                } else return "";
              })}
            </Expand>
          </motion.div>
          <motion.div
            initial="hidden"
            variants={ANIMATIONS.staggerContainerFast}
            animate={itemsControls}
            className="w-1/2 px-2  text-xs sm:text-base"
          >
            {DATA.textLines.map((line: string[], index: number) => {
              if (index < lineAmt) {
                return (
                  <motion.span
                    variants={ANIMATIONS.fade}
                    className="  block h-6 whitespace-nowrap"
                    key={"credits" + index}
                  >
                    {line[1]}
                  </motion.span>
                );
              } else return "";
            })}
            <Expand open={exp} duration={200} transitions={transitions}>
              {DATA.textLines.map((line: string[], index: number) => {
                if (index >= lineAmt) {
                  return (
                    <motion.span
                      variants={ANIMATIONS.fade}
                      className="block h-6 whitespace-nowrap"
                      key={"credits" + index}
                    >
                      {line[1]}
                    </motion.span>
                  );
                } else return "";
              })}
            </Expand>
          </motion.div>
        </div>
      </div>
      {DATA.textLines.length > lineAmt && !exp && (
        <div className="flex justify-center mt-4">
          <ButtonCTA
            text="..."
            size={1}
            color1={DATA.bgColor}
            color2={DATA.textColor}
            selected={hover}
            onClick={() => setExp(true)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          />
        </div>
      )}

      {DATA.imageData && (
        <div
          className={`absolute top-0 left-0 w-full h-full z-0 overflow-hidden`}
          style={{ pointerEvents: "none" }}
        >
          <ImageComponent imageData={DATA.imageData} crop={true} />
        </div>
      )}

      {highlighted && <HighlightBlock />}
    </section>
  );
}

export default CreditsBlock;
