import React from "react";
import CategoryExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/CategoryExpandableBar";
import {
  Input,
  Button,
  Container,
  ExplanationP,
  ControllerSection,
  SmallMargin,
  TextArea,
} from "../../style/styledComponents";
import ColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/ColorOptions";

type Props = {
  textColor: number;
  titleColor: number;
  bgColor: number;
  handleValueChange: Function;
};

export default function BlockColorOptions({
  textColor,
  titleColor,
  bgColor,
  handleValueChange,
}: Props) {
  return (
    <CategoryExpandableBar title="COLORS" index={0}>
      <ControllerSection>
        <span>Text Color:</span>
        <br />
        <ColorOptions
          enableInherit
          propName="textColor"
          currentVal={textColor}
          handleColorChange={handleValueChange}
        />
      </ControllerSection>
      <ControllerSection>
        <span>Title Color:</span>
        <br />
        <ColorOptions
          enableInherit
          propName="titleColor"
          currentVal={titleColor}
          handleColorChange={handleValueChange}
        />
      </ControllerSection>
      <ControllerSection>
        <span>Background Color:</span>
        <br />
        <ColorOptions
          enableInherit
          propName="bgColor"
          currentVal={bgColor}
          handleColorChange={handleValueChange}
        />
      </ControllerSection>
    </CategoryExpandableBar>
  );
}
