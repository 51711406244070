/** @jsx jsx */

import { useState } from "react";
import {
  FullWidthInput,
  TextArea,
  ControllerSection,
  COLORS,
  ExplanationP,
  SmallMargin,
} from "../../style/styledComponents";
import { cloneDeep } from "lodash-es";
import { css, jsx } from "@emotion/react";
import XOptionsRadioButtons from "../PAGEBUILDER_UTILITY_COMPONENTS/XOptionsRadioButtons";
import ColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/ColorOptions";
import BlockExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockExpandableBar";
import ImageSelector from "../PAGEBUILDER_UTILITY_COMPONENTS/ImageSelector";
import CategoryExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/CategoryExpandableBar";
import { BLOCKNAMES, BLOCKS } from "../../blocks";
import BlockColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockColorOptions";
import RichTextEditor from "../PAGEBUILDER_UTILITY_COMPONENTS/RichTextEditor";
import VerticalPaddingSliders from "../PAGEBUILDER_UTILITY_COMPONENTS/VerticalPaddingSliders";

interface Props {
  content: any;
  index: number;
  handleBlockChange: Function;
  disabledStyle: string;
}

export const buttonCTABlockInit = {
  text: "Button Text",
  url: "",
  buttonSize: 0,
  linkType: 0,
  textSize: 0,
  imageData: { imageID: "", crop: true, vFocus: 50, hFocus: 50 },
  titleColor: 0,
  textColor: 0,
  bgColor: 0,
  vPaddingTop: 30,
  vPaddingBottom: 30,
};

export const ButtonCTABlockControl = ({
  content,
  index,
  handleBlockChange,
  disabledStyle,
}: Props) => {
  const handleValueChange = (key: string, value: string | boolean | number) => {
    const newContent = cloneDeep(content);
    newContent[key] = value;
    handleBlockChange({ ...newContent }, index);
  };

  return (
    <>
      <CategoryExpandableBar title="BUTTON" index={index}>
        <ControllerSection>
          <label>Text</label>
          <FullWidthInput
            type="text"
            value={content.text}
            onChange={(e) => handleValueChange("text", e.target.value)}
          />
        </ControllerSection>

        <ControllerSection>
          <label htmlFor="url">URL</label>
          <ExplanationP>
            If this is an internal link, enter just the last part of the url
            here - for instance "portfolio" links to
            "www.myawesomewebsite.com/portfolio". For an external link enter the
            full URL
          </ExplanationP>
          <FullWidthInput
            type="text"
            value={content.url}
            onChange={(e) => handleValueChange("url", e.target.value)}
          />
          <SmallMargin />
          <XOptionsRadioButtons
            labels={["Internal link", "External link"]}
            currentVal={content.linkType}
            handleChange={handleValueChange}
            propName="linkType"
            index={index}
          />
        </ControllerSection>

        <ControllerSection>
          <span>Text Size</span>
          <XOptionsRadioButtons
            propName="textSize"
            currentVal={content.textSize}
            handleChange={handleValueChange}
            index={index}
            labels={["Normal", "Large", "XL"]}
          />
        </ControllerSection>
        <ControllerSection>
          <label>Button size:</label>
          <XOptionsRadioButtons
            labels={["Normal", "Small"]}
            currentVal={content.buttonSize}
            handleChange={handleValueChange}
            propName="buttonSize"
            index={index}
          />
        </ControllerSection>
      </CategoryExpandableBar>
      <CategoryExpandableBar title="BACKGROUND IMAGE" index={index}>
        <ControllerSection>
          <ImageSelector
            currentValue={content.imageData}
            handleValueChange={handleValueChange}
            label="Background Image:"
            propName="imageData"
            croppedByDefault
          />
        </ControllerSection>
      </CategoryExpandableBar>
      <CategoryExpandableBar title="BLOCK OPTIONS" index={index}>
        <VerticalPaddingSliders
          vPaddingBottom={content.vPaddingBottom}
          vPaddingTop={content.vPaddingTop}
          handleValueChange={handleValueChange}
        />
      </CategoryExpandableBar>
      <BlockColorOptions
        titleColor={content.titleColor}
        bgColor={content.bgColor}
        textColor={content.textColor}
        handleValueChange={handleValueChange}
      />
    </>
  );
};
export default ButtonCTABlockControl;
