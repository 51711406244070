/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import ImageComponent from "../ImageComponent";
import HighlightBlock from "./HighlightBlock";

import {
  colors,
  getTextAlignFromNumber,
  maxContentWidth,
  vPaddingMultiplier,
  xPaddingAmtLarge,
  xPaddingAmtMobile,
} from "../../styles/style";
import { fillInMissingBlockDataWithDefaults } from "../../cms/functions";
import { textBoxInit } from "../../cms/CMSComponents/CONTROLLER_COMPONENTS/TextBoxBlockControl";
import TitleColorAccents from "../TitleColorAccents";
import ColorAccentsText from "../ColorAccentsText";
import DecorationCircle from "../DecorationCircle";
import H1H3Switch from "../H1H3Switch";
import H2H4Switch from "../H2H4Switch";

type Props = {
  blockContent: string;
  index: number;
  highlighted: boolean;
  pageTitleColor: number;
  breakPoint: number;
};

function TextBoxBlock({
  blockContent,
  index,
  highlighted,
  pageTitleColor,
  breakPoint,
}: Props) {
  const DATA = fillInMissingBlockDataWithDefaults(
    JSON.parse(blockContent),
    textBoxInit
  );

  let textAlignClass = getTextAlignFromNumber(DATA.textAlign);

  let widthClass = "w-full";
  if (DATA.textBoxWidth === 1) widthClass = "md:w-3/4";
  if (DATA.textBoxWidth === 2) widthClass = "md:w-1/2";
  if (DATA.textBoxWidth === 3) widthClass = "md:w-1/3";

  let marginXClass = "m-auto";
  if (DATA.textBoxPosition === 0) marginXClass = "ml-0 mr-auto";
  if (DATA.textBoxPosition === 2) marginXClass = "ml-auto mr-0";

  let textSizeClass = "";
  if (DATA.textSize === 1) textSizeClass = "text-lg";
  if (DATA.textSize === 2) textSizeClass = "text-xl";

  return (
    <section
      id={"container" + index}
      className={`relative  w-screen transition-colors z-0`}
      style={{
        backgroundColor: colors[DATA.bgColor],
        padding: `${DATA.vPaddingTop * vPaddingMultiplier}px ${
          DATA.textBoxPosition === 0 && breakPoint < 0
            ? xPaddingAmtLarge
            : xPaddingAmtMobile
        }px ${DATA.vPaddingBottom * vPaddingMultiplier}px`,
      }}
    >
      <div style={{ maxWidth: maxContentWidth + "px" }} className="mx-auto">
        <div
          id={"textbox" + index}
          className={`z-10 ${widthClass} ${textAlignClass} ${marginXClass}`}
          style={{
            padding: `0 `,
          }}
        >
          <H1H3Switch
            isH1={DATA.isMainTitle}
            className="relative z-0 inline-block text-4xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl font-title -ml-0.75 sm:-ml-0.5 lg:-ml-1 font-normal"
          >
            {DATA.displayCircle && (
              <DecorationCircle
                bgColor={
                  DATA.titleColor === 0 ? pageTitleColor : DATA.titleColor
                }
                maxMovement={-60}
                growthSize={6.8}
                offsetX={index < 3 ? 97 : 40}
                offsetY={33}
                growthSizeMobile={4.1}
                offsetXMobile={index < 3 ? 81 : 60}
                offsetYMobile={20}
                noAnimation={index < 3}
              />
            )}
            <ColorAccentsText
              text={DATA.title}
              textColor={DATA.textColor}
              accentColor={
                DATA.titleColor === 0 ? pageTitleColor : DATA.titleColor
              }
            />
          </H1H3Switch>
          <H2H4Switch
            isH2={DATA.isMainTitle}
            className="relative z-0 text-2xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-4xl font-title sm:-ml-0.5 font-normal"
          >
            <ColorAccentsText
              text={DATA.subtitle}
              textColor={DATA.textColor}
              accentColor={
                DATA.titleColor === 0 ? pageTitleColor : DATA.titleColor
              }
            />
          </H2H4Switch>

          <div
            className={`mt-8 ${textSizeClass}`}
            style={{
              color: colors[DATA.textColor],
            }}
          >
            <ColorAccentsText
              text={DATA.richText}
              textColor={DATA.textColor}
              accentColor={
                DATA.titleColor === 0 ? pageTitleColor : DATA.titleColor
              }
            />
          </div>
        </div>

        {DATA.imageData && (
          <div
            className={`absolute top-0 left-0 w-full h-full z-0 overflow-hidden`}
          >
            <ImageComponent imageData={DATA.imageData} crop={true} />
          </div>
        )}
      </div>
      {highlighted && <HighlightBlock />}
    </section>
  );
}

export default TextBoxBlock;
