/** @jsx jsx */
import { useRef, useEffect, useState } from "react";
import { css, jsx } from "@emotion/react";
import {
  colors,
  clipLeft,
  clipRight,
  imgPaddingAmt,
  getTextAlignFromNumber,
  vPaddingMultiplier,
  getXPaddingAmtFromNumber,
  xPaddingAmtMobile,
  maxContentWidth,
} from "../../styles/style";
import HighlightBlock from "./HighlightBlock";
import { fillInMissingBlockDataWithDefaults } from "../../cms/functions";
import { textImgBlockInit } from "../../cms/CMSComponents/CONTROLLER_COMPONENTS/TextImgBlockControl";
import VideoPreview from "../VideoPreview";
import useCmsProofBreakpoints from "../../hooks/useCmsProofBreakpoints";
import DecorationCircle from "../DecorationCircle";
import ColorAccentsText from "../ColorAccentsText";
import H1H3Switch from "../H1H3Switch";
import H2H4Switch from "../H2H4Switch";

type Props = {
  blockContent: string;
  index: number;
  highlighted: boolean;
  pageTitleColor: number;
  breakPoint: number;
  setShowVideoLightbox: Function;
  previewMode?: boolean;
};

function TextImgBlock({
  blockContent,
  index,
  highlighted,
  pageTitleColor,
  breakPoint,
  setShowVideoLightbox,
  previewMode,
}: Props) {
  const textImageBoxRef = useRef();
  const mobileVideoRef = useRef();

  let gatsbyBreakpoints = useCmsProofBreakpoints();

  const DATA = fillInMissingBlockDataWithDefaults(
    JSON.parse(blockContent),
    textImgBlockInit
  );
  let textWidthClass = "";
  let imgWidthClass = "";

  switch (DATA.imageWidth) {
    case 0:
      textWidthClass = "lg:w-5/6 w-full";
      imgWidthClass = "lg:w-1/6 w-full";
      break;
    case 1:
      textWidthClass = "lg:w-4/6 w-full";
      imgWidthClass = "lg:w-2/6 w-full";
      break;
    case 2:
      textWidthClass = "lg:w-3/6 w-full";
      imgWidthClass = "lg:w-3/6 w-full";
      break;
    case 3:
      textWidthClass = "lg:w-2/6 w-full";
      imgWidthClass = "lg:w-4/6 w-full";
      break;
    case 4:
      textWidthClass = "lg:w-1/6 w-full";
      imgWidthClass = "lg:w-5/6 w-full";
      break;
  }
  let textAlignClass = getTextAlignFromNumber(DATA.textAlign);

  let textSizeClass = "";
  if (DATA.textSize === 1) textSizeClass = "text-lg";
  if (DATA.textSize === 2) textSizeClass = "text-xl";

  let xPaddingAmt = getXPaddingAmtFromNumber(DATA.contentWidth, breakPoint);

  let textPaddingAmt = DATA.textPadding * 2;
  if (breakPoint === 0 || breakPoint === 1) textPaddingAmt = 5;

  const [update, setUpdate] = useState(0);

  function resize() {
    setUpdate(window.innerWidth);
  }
  useEffect(() => {
    setUpdate(1);

    if (typeof window !== "undefined") {
      window.addEventListener("resize", resize);
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", resize);
      }
    };
  }, []);
  // console.log(DATA.video);
  return (
    <section
      className={`relative transition-colors flex items-center lg:flex-nowrap ${textAlignClass} ${
        DATA.imageLeft ? "flex-col lg:flex-row-reverse" : "flex-col lg:flex-row"
      }`}
      style={{
        backgroundColor: colors[DATA.bgColor],
        paddingTop: `${DATA.vPaddingTop * vPaddingMultiplier}px`,
        paddingBottom: `${DATA.vPaddingBottom * vPaddingMultiplier}px`,
      }}
    >
      <div
        className={`mx-auto relative transition-colors flex lg:flex-nowrap lg:items-center ${textAlignClass} ${
          DATA.imageLeft
            ? "flex-col lg:flex-row-reverse"
            : "flex-col lg:flex-row"
        }`}
        style={{ maxWidth: maxContentWidth + "px" }}
      >
        <div
          id={"textImagebox" + index}
          ref={textImageBoxRef}
          className={`z-10 relative self-center ${textWidthClass}`}
          style={{
            minWidth: "200px",
            color: colors[DATA.textColor],
            paddingTop: `${textPaddingAmt}px`,
            paddingBottom: `${textPaddingAmt}px`,
            paddingLeft: DATA.imageLeft
              ? `${xPaddingAmtMobile}px`
              : `${xPaddingAmt}px`,
            paddingRight: !DATA.imageLeft
              ? `${xPaddingAmtMobile}px`
              : `${xPaddingAmt}px`,
          }}
        >
          <div className="relative">
            {DATA.displayCircle && (
              <DecorationCircle
                bgColor={
                  DATA.titleColor === 0 ? pageTitleColor : DATA.titleColor
                }
                maxMovement={-60}
                growthSize={8}
                offsetX={45}
                offsetXMobile={23}
                offsetYMobile={20}
                growthSizeMobile={4.2}
                offsetY={30}
              />
            )}
            <H1H3Switch
              isH1={DATA.isMainTitle}
              className="relative z-50 text-4xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl font-title -ml-0.75 sm:-ml-0.5 lg:-ml-1 font-normal"
              style={{ wordBreak: "break-word" }}
            >
              <ColorAccentsText
                text={DATA.title}
                textColor={DATA.textColor}
                accentColor={
                  DATA.titleColor === 0 ? pageTitleColor : DATA.titleColor
                }
              />
            </H1H3Switch>
            <H2H4Switch
              isH2={DATA.isMainTitle}
              className="relative z-50 text-2xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-4xl font-title sm:-ml-0.5 font-normal"
            >
              <ColorAccentsText
                text={DATA.subtitle}
                textColor={DATA.textColor}
                accentColor={
                  DATA.titleColor === 0 ? pageTitleColor : DATA.titleColor
                }
              />
            </H2H4Switch>
          </div>
          <div className={`mt-4 ${textSizeClass}`}>
            <ColorAccentsText
              text={DATA.text}
              textColor={DATA.textColor}
              accentColor={
                DATA.titleColor === 0 ? pageTitleColor : DATA.titleColor
              }
              linksInSameTab={DATA.linksInSameTab}
            />
          </div>
        </div>
        {!gatsbyBreakpoints.md ? (
          <div
            className={`${imgWidthClass} inline-flex z-20 relative  overflow-hidden ${
              DATA.imagePadding ? `p-${imgPaddingAmt}` : `p-0`
            } `}
            style={{
              clipPath: DATA.imageLeft
                ? clipRight[DATA.clipStyle]
                : clipLeft[DATA.clipStyle],
              height: DATA.video
                ? (DATA.video.aspectRatioY / DATA.video.aspectRatioX) *
                    (textImageBoxRef.current?.offsetWidth - xPaddingAmt) +
                  "px"
                : textImageBoxRef.current?.offsetHeight + "px",
              paddingLeft: DATA.imageLeft ? xPaddingAmt : 0,
              paddingRight: DATA.imageLeft ? 0 : xPaddingAmt,
            }}
          >
            <VideoPreview
              setShowVideoLightbox={setShowVideoLightbox}
              imageData={DATA.imageData}
              videoData={DATA.video}
              idStr={"textImgBlock-" + index}
              dontCropVideo={!DATA.imageData.crop}
              dontPreviewVideo={DATA.dontPreviewVideo}
            />

            {/* {DATA.video.videoID ? (
            <VimeoVideoComponentInline
              videoData={DATA.video}
              containerSize={textboxSize}
            />
          ) : (
            <ImageComponent
              imageData={DATA.imageData}
              crop={DATA.imageData.crop}
            />
          )} */}
          </div>
        ) : (
          <div
            className={`w-full inline-flex relative  overflow-hidden my-4  ${
              DATA.imagePadding ? `p-${imgPaddingAmt}` : `p-0`
            } `}
            style={{
              padding: `0 ${xPaddingAmtMobile}px`,
            }}
          >
            <div
              ref={mobileVideoRef}
              className="h-72 sm:h-96"
              style={{
                height: DATA.video
                  ? (DATA.video.aspectRatioY / DATA.video.aspectRatioX) *
                      textImageBoxRef.current?.offsetWidth +
                    "px"
                  : "",
              }}
            >
              <VideoPreview
                setShowVideoLightbox={setShowVideoLightbox}
                imageData={DATA.imageData}
                videoData={DATA.video}
                idStr={"textImgBlock-" + index}
                dontPreviewVideo={DATA.dontPreviewVideo}
              />
              {/* {DATA.video.videoID ? (
            <VimeoVideoComponentInline videoData={DATA.video} />
          ) : (
            <ImageComponent
              imageData={DATA.imageData}
              crop={DATA.imageData.crop}
            />
          )} */}
            </div>
          </div>
        )}
      </div>
      {highlighted && <HighlightBlock />}
    </section>
  );
}

export default TextImgBlock;
