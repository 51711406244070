import React from "react";
import ImageComponent from "./ImageComponent";

import FaFacebookF from "../images/icons/facebook-f-brands";
import FaInstagram from "../images/icons/instagram-brands";
import FaLinkedinIn from "../images/icons/linkedin-in-brands";

import { colors } from "../styles/style";
import { useStaticQuery, graphql, Link } from "gatsby";
import { findFooterData } from "../functions";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

type Props = {
  textColor: number;
  titleColor: number;
  bgColor: number;
  setIsShowingCookiePopup: Function;
};

export default function Footer({
  textColor,
  titleColor,
  bgColor,
  setIsShowingCookiePopup,
}: Props) {
  const data = useStaticQuery(
    graphql`
      query FooterQuery {
        allMarkdownRemark {
          edges {
            node {
              frontmatter {
                footerBuilder {
                  email
                  facebookLink
                  instagramLink
                  linkedInLink
                  logoImage {
                    imageID
                    vFocus
                    hFocus
                  }
                  telephone
                }
              }
              fileAbsolutePath
            }
          }
        }
      }
    `
  );
  const footerData = findFooterData(data.allMarkdownRemark.edges);
  const gatsbyBreakpoints = useBreakpoint();

  return (
    <footer style={{ color: colors[textColor] }}>
      {footerData.logoImage && (
        <div className="m-auto h-24 w-24 hoverFlip mt-8 mb-4 relative">
          <Link to="/" className="absolute w-full h-full inset-0 block z-10">
            <ImageComponent
              imageData={footerData.logoImage}
              crop={false}
              noPlugins
            />
          </Link>
        </div>
      )}
      <a
        href={`tel:${footerData.telephone}`}
        className="block text-center text-xl"
        style={{ marginBottom: gatsbyBreakpoints.sm ? "2rem" : "0.5rem" }}
      >
        {footerData.telephone}
      </a>{" "}
      <a
        target="_blank"
        href={`mailto:${footerData.email}`}
        className="block text-center text-xl"
      >
        {footerData.email}
      </a>
      <div className="flex m-auto w-40 justify-between my-6">
        <a href={`${footerData.facebookLink}`} target="_blank">
          {/* <AiOutlineFacebook color={colors[textColor]} className="w-8 h-8" /> */}
          <SocialButton
            icon={FaFacebookF}
            textColor={textColor}
            bgColor={bgColor}
          />
          <label style={{ fontSize: 0 }}>Facebook Link</label>
        </a>
        <a href={`${footerData.linkedInLink}`} target="_blank">
          {/* <AiOutlineLinkedin color={colors[textColor]} className="w-8 h-8" /> */}
          <SocialButton
            icon={FaLinkedinIn}
            textColor={textColor}
            bgColor={bgColor}
          />
          <label style={{ fontSize: 0 }}>LinkedIn Link</label>
        </a>
        <a href={`${footerData.instagramLink}`} target="_blank">
          {/* <AiOutlineInstagram color={colors[textColor]} className="w-8 h-8" /> */}
          <SocialButton
            icon={FaInstagram}
            textColor={textColor}
            bgColor={bgColor}
          />
          <label style={{ fontSize: 0 }}>Instagram Link</label>
        </a>
      </div>
      <Link
        to={"/voorwaarden"}
        className="block text-center pb-4"
        style={{ color: colors[titleColor] }}
      >
        Voorwaarden en Privacy
      </Link>
      <span
        className="block text-center pb-4"
        style={{ color: colors[titleColor] }}
        onClick={() => setIsShowingCookiePopup(true)}
      >
        Cookie-instellingen
      </span>
    </footer>
  );
}

type socialIconProps = {
  icon: any;
  textColor: number;
  bgColor: number;
};

function SocialButton({ icon, textColor, bgColor }: socialIconProps) {
  const Icon = icon;
  return (
    <div className="w-10 h-10 flex relative justify-center items-center">
      <div
        className={"w-10 h-10 absolute inset-0 hoverFade rounded-full"}
        style={{ backgroundColor: colors[textColor] }}
      ></div>
      <Icon
        color={colors[bgColor]}
        className={"w-6 h-6 z-10 pointer-events-none"}
      />
    </div>
  );
}
