/** @jsx jsx */

import { ReactNode, useState } from "react";
import { css, jsx } from "@emotion/react";
import {
  ROUNDNESS,
  COLORS,
  MARGIN,
  SHADOWS,
} from "../../style/styledComponents";
import IoIosArrowDown from "../../../images/icons/angle-down-solid";
import IoIosArrowUp from "../../../images/icons/angle-up-solid";
import Expand from "react-expand-animated";

type Props = {
  title: string;
  index?: number;
  children: ReactNode;
};

export default function CategoryExpandableBar({
  title,
  index,
  children,
}: Props) {
  const [exp, setExp] = useState(false);

  const transitions = ["height", "opacity", "background"];

  return (
    <div
      css={css`
        width: 100%;
        background-color: ${COLORS.accent1};
        border-radius: ${ROUNDNESS};
      `}
    >
      <div
        css={css`
          height: 2rem;
          display: flex;
          align-items: center;
          position: relative;
          background-color: ${COLORS.accent2};
          border-radius: ${ROUNDNESS};
          box-shadow: ${SHADOWS.medium};
          padding: 0 ${MARGIN};
          &:hover {
            cursor: pointer;
          }
        `}
        onClick={() => setExp(!exp)}
      >
        {exp ? (
          <IoIosArrowUp
            css={css`
              height: 20px;
              width: 20px;
              transition: transform 0.3s;
              &:hover {
                cursor: pointer;
                transform: scale(1.2);
              }
            `}
          />
        ) : (
          <IoIosArrowDown
            css={css`
              height: 20px;
              width: 20px;
              transition: transform 0.3s;

              &:hover {
                cursor: pointer;
                transform: scale(1.2);
              }
            `}
          />
        )}
        <h2
          css={css`
            margin: 0;
          `}
        >
          {title}
        </h2>
      </div>
      <div
        css={css`
          padding: 0 ${MARGIN};
        `}
      >
        {" "}
        <Expand open={exp} duration={200} transitions={transitions}>
          <div
            css={css`
              padding: 15px ${MARGIN};
            `}
          >
            {children}
          </div>
        </Expand>
      </div>
    </div>
  );
}
