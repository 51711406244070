/** @jsx jsx */

import { useState } from "react";
import {
  FullWidthInput,
  TextArea,
  ControllerSection,
  Input,
  COLORS,
  ExplanationP,
} from "../../style/styledComponents";
import { cloneDeep } from "lodash-es";
import { css, jsx } from "@emotion/react";
import XOptionsRadioButtons from "../PAGEBUILDER_UTILITY_COMPONENTS/XOptionsRadioButtons";
import ColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/ColorOptions";
import BlockExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockExpandableBar";
import ImageSelector from "../PAGEBUILDER_UTILITY_COMPONENTS/ImageSelector";
import CategoryExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/CategoryExpandableBar";
import { BLOCKNAMES, BLOCKS } from "../../blocks";
import BlockColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockColorOptions";
import VerticalPaddingSliders from "../PAGEBUILDER_UTILITY_COMPONENTS/VerticalPaddingSliders";
import VideoSelector from "../PAGEBUILDER_UTILITY_COMPONENTS/VideoSelector";
import RichTextEditor from "../PAGEBUILDER_UTILITY_COMPONENTS/RichTextEditor";

interface Props {
  content: any;
  index: number;
  handleBlockChange: Function;
}

export const textImgBlockInit = {
  title: "Title Text",
  subtitle: "subtitle",
  text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  textAlign: 0,
  contentWidth: 0,
  textSize: 0,
  imageData: { imageID: "", crop: true, vFocus: 50, hFocus: 50 },
  video: {
    videoID: "",
    aspectRatioX: 16,
    aspectRatioY: 9,
  },
  imageWidth: 2,
  clipStyle: 0,
  textColor: 0,
  titleColor: 0,
  bgColor: 0,
  imageLeft: false,
  textPadding: 60,
  imagePadding: false,
  vPaddingTop: 30,
  vPaddingBottom: 30,
};

export const TextImgBlockControl = ({
  content,
  index,
  handleBlockChange,
}: Props) => {
  const handleValueChange = (key: string, value: string | boolean | number) => {
    const newContent = cloneDeep(content);
    newContent[key] = value;
    handleBlockChange({ ...newContent }, index);
  };

  return (
    <>
      <CategoryExpandableBar title="TEXT" index={index}>
        <ControllerSection>
          <label htmlFor="title">Title</label>
          <FullWidthInput
            name="title"
            id="title"
            type="text"
            value={content.title}
            onChange={(e) => handleValueChange("title", e.target.value)}
          />
        </ControllerSection>
        <ControllerSection>
          <input
            type="checkbox"
            checked={content.displayCircle}
            onChange={() => {
              handleValueChange("displayCircle", !content.displayCircle);
            }}
          ></input>
          <label>Display Animated Circle</label>
        </ControllerSection>

        <ControllerSection>
          <label htmlFor="subtitle">Subtitle</label>
          <FullWidthInput
            name="subtitle"
            id="subtitle"
            type="text"
            value={content.subtitle}
            onChange={(e) => handleValueChange("subtitle", e.target.value)}
          />{" "}
        </ControllerSection>
        <ControllerSection>
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <input
              type="checkbox"
              checked={content.isMainTitle}
              onChange={() => {
                handleValueChange("isMainTitle", !content.isMainTitle);
              }}
            ></input>
            <label style={{ fontWeight: 700 }}>
              This block is the main title of this page (use H1 and H2 instead
              of H3 and H4 elements for the title)
            </label>
          </div>
        </ControllerSection>
        {/* 
        <ControllerSection>
          <label htmlFor="text">Text</label>
          <TextArea
            name="text"
            id="text"
            rows={5}
            value={content.text}
            onChange={(e) => handleValueChange("text", e.target.value)}
          ></TextArea>
        </ControllerSection> */}
        <ControllerSection>
          <span>Text Left</span>
          <RichTextEditor
            value={content.text}
            propName="text"
            handleValueChange={handleValueChange}
          />
        </ControllerSection>
        <ControllerSection>
          <input
            type="checkbox"
            checked={content.linksInSameTab}
            onChange={() => {
              handleValueChange("linksInSameTab", !content.linksInSameTab);
            }}
            id="linksInSameTab"
          ></input>
          <label htmlFor="linksInSameTab">
            - Open links in text in same browser tab
          </label>
        </ControllerSection>

        <ControllerSection>
          <span>Text Size</span>
          <XOptionsRadioButtons
            propName="textSize"
            currentVal={content.textSize}
            handleChange={handleValueChange}
            index={index}
            labels={["Normal", "Large", "XL"]}
          />
        </ControllerSection>

        <ControllerSection>
          <span>Text Align</span>
          <XOptionsRadioButtons
            propName="textAlign"
            currentVal={content.textAlign}
            handleChange={handleValueChange}
            index={index}
            labels={["Left", "Center", "Right"]}
          />
        </ControllerSection>
        <ControllerSection>
          <label
            css={css`
              display: block;
            `}
            htmlFor="minHeight"
          >
            Vertical space around text:
          </label>
          <input
            type="range"
            id="minHeightSlider"
            min={10}
            max={100}
            step={10}
            value={content.textPadding}
            onChange={(e) =>
              handleValueChange("textPadding", parseInt(e.target.value, 10))
            }
          ></input>
        </ControllerSection>
      </CategoryExpandableBar>

      <CategoryExpandableBar title="IMAGE" index={index}>
        <ControllerSection>
          <ImageSelector
            currentValue={content.imageData}
            handleValueChange={handleValueChange}
            label="Image:"
            propName="imageData"
          />
        </ControllerSection>

        <ControllerSection>
          <span>Image Size</span>
          <XOptionsRadioButtons
            propName="imageWidth"
            currentVal={content.imageWidth}
            handleChange={handleValueChange}
            index={index}
            labels={["small", "medium", "large", "XL", "XXL"]}
          />
        </ControllerSection>

        <ControllerSection>
          <input
            type="checkbox"
            checked={content.imageLeft}
            onChange={() => {
              handleValueChange("imageLeft", !content.imageLeft);
            }}
            id="imageLeft"
          ></input>
          <label htmlFor="imageLeft">Image left of text</label>
        </ControllerSection>
        <ControllerSection>
          <span>Border Style:</span>
          <XOptionsRadioButtons
            propName="clipStyle"
            currentVal={content.clipStyle}
            handleChange={handleValueChange}
            index={index}
            labels={[
              "Normal",
              "Slanted #1",
              "Slanted #2",
              "Round #1",
              "Point In",
              "Point Out",
            ]}
          />
        </ControllerSection>

        <ControllerSection>
          <input
            id="imagePaddingCheckbox"
            type="checkbox"
            checked={content.imagePadding}
            onChange={() => {
              handleValueChange("imagePadding", !content.imagePadding);
            }}
          ></input>
          <label htmlFor="imagePaddingCheckbox">Image padding</label>
        </ControllerSection>
      </CategoryExpandableBar>
      <CategoryExpandableBar title="VIDEO" index={index}>
        <label>VIDEO</label>
        <ExplanationP>
          Paste the ID-number of a video here to give the block a video. The
          image from the 'Image' section of this block will still funtion as the
          thumbnail for the video. You can still control its size with the image
          controls.
        </ExplanationP>
        <VideoSelector
          propName="video"
          currentValue={content.video}
          handleValueChange={handleValueChange}
        />
        {/* <ControllerSection>
          <input
            type="checkbox"
            checked={content.dontCropVideo}
            onChange={() => {
              handleValueChange("dontCropVideo", !content.dontCropVideo);
            }}
          ></input>
          <label>
            Always show full video (never crop) - Aspect ratio of video must be
            the same as preview image!
          </label>
        </ControllerSection> */}
        <ControllerSection>
          <input
            type="checkbox"
            checked={content.dontPreviewVideo}
            onChange={() => {
              handleValueChange("dontPreviewVideo", !content.dontPreviewVideo);
            }}
            id="dontPreviewVideo"
          ></input>
          <label htmlFor="dontPreviewVideo">
            - Don't preview video when hovered
          </label>
        </ControllerSection>
      </CategoryExpandableBar>
      <CategoryExpandableBar title="BLOCK OPTIONS" index={index}>
        <ControllerSection>
          <span>Content Width:</span>
          <XOptionsRadioButtons
            propName="contentWidth"
            currentVal={content.contentWidth}
            handleChange={handleValueChange}
            index={index}
            labels={["Full-width", "Extra padding"]}
          />
        </ControllerSection>
        <VerticalPaddingSliders
          vPaddingBottom={content.vPaddingBottom}
          vPaddingTop={content.vPaddingTop}
          handleValueChange={handleValueChange}
        />
      </CategoryExpandableBar>
      <BlockColorOptions
        titleColor={content.titleColor}
        bgColor={content.bgColor}
        textColor={content.textColor}
        handleValueChange={handleValueChange}
      />
    </>
  );
};
export default TextImgBlockControl;
