import React, { useEffect } from "react";
import { motion } from "framer-motion";
import IoClose from "../images/icons/close-outline";
import IoMdArrowRoundBack from "../images/icons/arrow-back-outline";
import IoMdArrowRoundForward from "../images/icons/arrow-forward-outline";

import ImageComponent from "./ImageComponent";

type LightboxProps = {
  currentIndex: number;
  images: any[];
  changeIndex: Function;
  setShowLightbox: Function;
};

export default function ImageLightbox({
  currentIndex,
  images,
  changeIndex,
  setShowLightbox,
}: LightboxProps) {
  function handleKey(e: KeyboardEvent) {
    if (e.key === "ArrowLeft") changeIndex(-1);
    if (e.key === "ArrowRight") changeIndex(1);
    if (e.key === "Escape") setShowLightbox(false);
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKey);
    return () => window.removeEventListener("keydown", handleKey);
  });

  const motionProps = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <motion.div
      {...motionProps}
      transition={{ duration: 0.5 }}
      className="fixed w-screen h-screen top-0 left-0 select-none"
      style={{ backgroundColor: "rgba(0,0,0,0.9)", zIndex: 9999 }}
      onClick={() => setShowLightbox(false)}
    >
      <div
        style={{ width: "80vw", height: "80vh" }}
        className="absolute flex left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
      >
        <ImageComponent imageData={images[currentIndex].image} crop={false} />
      </div>
      <IoMdArrowRoundForward
        color="rgb(255,255,255)"
        className="absolute w-12 h-12 top-1/2 right-8 opacity-50 hover:opacity-100 active:opacity-10 transition-opacity"
        onClick={(e: MouseEvent) => {
          e.stopPropagation();
          changeIndex(1);
        }}
      />
      <IoMdArrowRoundBack
        color="rgb(255,255,255)"
        className="absolute w-12 h-12 top-1/2 left-8 opacity-50 hover:opacity-100 active:opacity-10 transition-opacity"
        onClick={(e: MouseEvent) => {
          e.stopPropagation();
          changeIndex(-1);
        }}
      />
      <IoClose
        color="rgb(255,255,255)"
        className="absolute w-12 h-12 top-4 right-4 opacity-50 hover:opacity-100 active:opacity-10 transition-opacity"
        onClick={() => setShowLightbox(false)}
      />
    </motion.div>
  );
}
