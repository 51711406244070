import React, { Children, ReactNode, useEffect, useRef } from "react";
import { colors } from "../styles/style";

type props = {
  text: string;
  accentColor: number;
  textColor: number;
  style?: { [x: string]: string | number };
  linksInSameTab?: boolean;
  [x: string]: any;
  children?: ReactNode;
};

export default function ColorAccentsText({
  text,
  accentColor,
  textColor,
  style,
  linksInSameTab,
  children,
}: props) {
  const ref = useRef(null);
  const SPLIT_STRING1 = "~~";
  const SPLIT_STRING2 = "/~~";
  const processedText = text
    .replaceAll(SPLIT_STRING2, "</mark>")
    .replaceAll(SPLIT_STRING1, "<mark>");

  useEffect(() => {
    const elems = ref.current.querySelectorAll("mark");
    elems.forEach((el) => (el.style.color = colors[accentColor]));

    if (linksInSameTab) {
      const links = ref.current.querySelectorAll("a");
      links.forEach((el) => (el.target = ""));
    }
  }, [text]);

  return (
    <>
      {children}
      <div
        ref={ref}
        className="z-50 relative"
        dangerouslySetInnerHTML={{ __html: processedText }}
        style={{ color: colors[textColor], ...style }}
      ></div>
    </>
  );
}
