/** @jsx jsx */

import React, { useEffect, useState, useRef } from "react";
import { css, jsx } from "@emotion/react";

import HighlightBlock from "./HighlightBlock";

import {
  colors,
  getTextAlignFromNumber,
  getXPaddingAmtFromNumber,
  maxContentWidth,
  vPaddingMultiplier,
} from "../../styles/style";
import { fillInMissingBlockDataWithDefaults } from "../../cms/functions";
import { textBoxInit } from "../../cms/CMSComponents/CONTROLLER_COMPONENTS/TextBoxBlockControl";
import VideoPreview from "../VideoPreview";

type Props = {
  blockContent: string;
  index: number;
  highlighted: boolean;
  pageTitleColor: number;
  breakPoint: number;
  setShowVideoLightbox: Function;
};

function ImageVideoBlock({
  blockContent,
  index,
  highlighted,
  pageTitleColor,
  breakPoint,
  setShowVideoLightbox,
}: Props) {
  const DATA = fillInMissingBlockDataWithDefaults(
    JSON.parse(blockContent),
    textBoxInit
  );

  let textAlignClass = getTextAlignFromNumber(DATA.imagePosition);

  let widthClass = "w-full xl:1/2";
  let heightClass = "h-72 xl:h-120";
  if (DATA.imageSize === 0 && breakPoint !== 0) heightClass = "h-96";

  if (DATA.imageSize === 1) {
    widthClass = "md:w-3/4 xl:1/3";
    if (breakPoint !== 0) heightClass = "h-96 xl:h-120";
  }
  if (DATA.imageSize === 2) {
    widthClass = "md:w-1/2 xl:1/4";
  }
  if (DATA.imageSize === 3) {
    widthClass = "md:w-1/3 xl:1/5";
    breakPoint !== 0
      ? (heightClass = "h-72 xl:h-120")
      : (heightClass = "h-48 xl:h-120");
  }

  let marginXClass = "m-auto";
  if (DATA.imagePosition === 0) marginXClass = "ml-0 mr-auto";
  if (DATA.imagePosition === 2) marginXClass = "ml-auto mr-0";

  let xPaddingAmt = getXPaddingAmtFromNumber(DATA.contentWidth, breakPoint);

  return (
    <section
      id={"container" + index}
      className={`relative  w-screen transition-colors`}
      style={{
        backgroundColor: colors[DATA.bgColor],
        padding: `${DATA.vPaddingTop * vPaddingMultiplier}px ${xPaddingAmt}px ${
          DATA.vPaddingBottom * vPaddingMultiplier
        }px`,
      }}
    >
      <div
        id={"imageBox" + index}
        className={`z-10 ${widthClass} ${textAlignClass} ${marginXClass}`}
        style={{
          padding: `0 `,
          maxWidth: maxContentWidth + "px",
        }}
      >
        <div className={`${heightClass} w-full mx-auto`}>
          {!DATA.youtubeData ? (
            <VideoPreview
              setShowVideoLightbox={setShowVideoLightbox}
              idStr={"imagevideoblock" + index}
              imageData={DATA.imageData}
              videoData={DATA.videoData}
            />
          ) : (
            <iframe
              src={`https://www.youtube.com/embed/${DATA.youtubeData}`}
              className={`w-full  ${heightClass}`}
              allowFullScreen
            />
          )}
        </div>
        <div
          className={`${textAlignClass}`}
          style={{
            color: colors[DATA.textColor],
          }}
        >
          <i>{DATA.captionText}</i>
        </div>
      </div>

      {highlighted && <HighlightBlock />}
    </section>
  );
}

export default ImageVideoBlock;
