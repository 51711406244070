import { Link } from "gatsby";
import React, { useState, useEffect, useRef } from "react";
import { menuItem } from "../cms/CMSComponents/HeaderBuilder";
import { colors } from "../styles/style";
import ImageComponent from "./ImageComponent";
import { imageData } from "../cms/CMSComponents/PAGEBUILDER_UTILITY_COMPONENTS/ImageSelector";
import Expand from "react-expand-animated";
import useCmsProofBreakpoints from "../hooks/useCmsProofBreakpoints";

type Props = {
  menuItems: menuItem[];
  titleColor: number;
  bgColor: number;
  logoImage: imageData;
};

const TOP_MENU_MARGIN = 200;
const SCROLL_TIMEOUT = 2500;

export default function DesktopMenu({
  menuItems,
  titleColor,
  bgColor,
  logoImage,
}: Props) {
  const [showMenu, setShowMenu] = useState(true);
  const showMenuRef = useRef(true);
  const lastScrollPos = useRef(0);
  const timeOutRef = useRef<any>();
  const splitIndex = Math.ceil(menuItems.length / 2 - 1);

  const toggleMenu = (val: boolean) => {
    if (showMenuRef.current !== val) {
      setShowMenu(val);
      showMenuRef.current = val;
    }
  };

  const handleScroll = () => {
    let menuState = showMenuRef.current;
    if (window.scrollY < TOP_MENU_MARGIN) {
      menuState = true;
      clearTimeout(timeOutRef.current);
    } else {
      if (!timeOutRef.current) menuState = false;
    }
    if (window.scrollY < lastScrollPos.current) {
      menuState = true;
      clearTimeout(timeOutRef.current);
      timeOutRef.current = setTimeout(() => {
        if (window.scrollY > TOP_MENU_MARGIN) toggleMenu(false);
      }, SCROLL_TIMEOUT);
    }

    toggleMenu(menuState);
    lastScrollPos.current = window.scrollY;
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  return (
    <nav
      style={{
        color: colors[titleColor],
        backgroundColor: colors[bgColor],
        top: showMenu ? 0 : "-100%",
        transition: "top 0.5s",
      }}
      className="justify-center fixed w-screen left-0 m-auto items-center hidden lg:flex "
    >
      <div className="w-1/2 flex justify-end">
        {menuItems.map((item, index) => {
          if (index <= splitIndex)
            return (
              <MenuItem
                text={item.text}
                linkUrl={item.linkUrl}
                children={item.children}
                bgColor={bgColor}
                key={"menu" + index}
                titleColor={titleColor}
              />
            );
        })}
      </div>
      <Link to="/" className="mx-12">
        <div className="w-24 h-24 m-auto hoverFlip">
          <ImageComponent imageData={logoImage} crop={false} noPlugins />
        </div>
      </Link>
      <div className="w-1/2 flex justify-start">
        {menuItems.map((item, index) => {
          if (index > splitIndex)
            return (
              <MenuItem
                text={item.text}
                linkUrl={item.linkUrl}
                children={item.children}
                bgColor={bgColor}
                key={"menu" + index}
                titleColor={titleColor}
              />
            );
        })}
      </div>
      <a
        style={{ color: colors[2], backgroundColor: colors[3] }}
        href="tel:0302769186"
        className="absolute right-6 top-10 transform  px-2 py-1 rounded-md select-text"
      >
        030 276 91 86
      </a>
    </nav>
  );
}

type DesktopMenuItem = menuItem & {
  bgColor: number;
  titleColor: number;
};

function MenuItem({
  children,
  text,
  linkUrl,
  bgColor,
  titleColor,
}: DesktopMenuItem) {
  const [exp, setExp] = useState(false);
  const transitions = ["opacity"];

  const [hoverClass, setHoverClass] = useState("hoverMenuFXExit");

  return (
    <div
      className="relative rounded-b-lg pb-1 mt-4 font-nav "
      onMouseEnter={() => {
        setExp(true);
        setHoverClass("hoverMenuFX");
      }}
      onMouseLeave={() => {
        setExp(false);
        setHoverClass("hoverMenuFXExit");
      }}
      style={{
        height: "fit-content",
        marginLeft: "3%",
        marginRight: "3%",
      }}
    >
      <Link className="text-3xl mx-2" to={linkUrl}>
        {text}
      </Link>

      {/* <Expand open={exp} duration={200} transitions={transitions}>
        <div
          className="absolute w-full top-12 left-0 rounded-md"
          style={{ backgroundColor: colors[bgColor] }}
        >
          {children.map((item, index) => (
            <Link
              key={index}
              className="text-xl py-2 text-center block hoverScaleXL"
              to={item.linkUrl}
            >
              {item.text}
            </Link>
          ))}
        </div>
      </Expand> */}
      <div
        style={{ color: colors[bgColor], backgroundColor: colors[titleColor] }}
        className={`absolute rounded-lg w-full h-10 left-0 top-0 pointer-events-none pb-2 ${hoverClass}`}
      ></div>
    </div>
  );
}
