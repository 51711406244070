import React, { useState, useEffect, useRef } from "react";
import { colors } from "../styles/style";
import { motion, AnimatePresence } from "framer-motion";
import ANIMATIONS from "../styles/animations";
import useIntersection from "../hooks/useInterSection";

type props = any;

const MIN_ANIM_LENGTH = 20;
const VAR_ANIM_LENGTH = 60;

export default function AnimatedTypingTitle({
  DATA,
  pageTitleColor,
  isVisible,
}: props) {
  const [letterCount, setLetterCount] = useState(1);

  const [showSubtitle, setShowSubtitle] = useState(false);
  const letterCountRef = useRef(1);

  const increaseLetterCount = () => {
    letterCountRef.current++;
    setLetterCount(letterCountRef.current);
    if (letterCountRef.current < DATA.title.length) {
      setTimeout(
        increaseLetterCount,
        MIN_ANIM_LENGTH + Math.round(Math.random() * VAR_ANIM_LENGTH)
      );
    } else {
      setShowSubtitle(true);
    }
  };

  useEffect(() => {
    if (isVisible && letterCountRef.current === 1) increaseLetterCount();
  }, [isVisible]);

  return (
    <>
      {DATA.isMainTitle ? (
        <>
          <h1
            className={`z-10 ${
              DATA.titleSize === 1
                ? "text-4xl"
                : "text-4xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl"
            } font-title -ml-0.75 sm:-ml-0.5 lg:-ml-1 font-normal`}
            style={{
              color:
                DATA.titleColor === 0
                  ? colors[pageTitleColor]
                  : colors[DATA.titleColor],
            }}
          >
            {DATA.italicTitle ? (
              <i>{DATA.title.slice(0, letterCount)}</i>
            ) : (
              DATA.title.slice(0, letterCount)
            )}
          </h1>
          <AnimatePresence>
            {showSubtitle && (
              <motion.h2
                initial={{ opacity: 0, scale: 0, y: 0 }}
                animate={{ opacity: 1, scale: 1, y: 0 }}
                exit={{ opacity: 0, scale: 0, y: 0 }}
                transition={{ duration: 0.9, type: "spring", delay: 0.3 }}
                className={`z-10 ${
                  DATA.titleSize === 1
                    ? "text-2xl"
                    : "text-2xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-4xl"
                } font-title sm:-ml-0.5 font-normal`}
                style={{
                  color: colors[DATA.textColor],
                }}
              >
                {DATA.subtitle}
              </motion.h2>
            )}
          </AnimatePresence>
          {!showSubtitle && DATA.subtitle && (
            <h2
              className={`z-10 ${
                DATA.titleSize === 1
                  ? "text-2xl"
                  : "text-2xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-4xl"
              } font-title sm:-ml-0.5 font-normal opacity-0`}
            >
              ..
            </h2>
          )}
        </>
      ) : (
        <>
          <h3
            className={`z-10 ${
              DATA.titleSize === 1
                ? "text-4xl"
                : "text-4xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl"
            } font-title -ml-0.75 sm:-ml-0.5 lg:-ml-1 font-normal`}
            style={{
              color:
                DATA.titleColor === 0
                  ? colors[pageTitleColor]
                  : colors[DATA.titleColor],
            }}
          >
            {DATA.italicTitle ? (
              <i>{DATA.title.slice(0, letterCount)}</i>
            ) : (
              DATA.title.slice(0, letterCount)
            )}
          </h3>
          <AnimatePresence>
            {showSubtitle && (
              <motion.h4
                initial={{ opacity: 0, scale: 0, y: 0 }}
                animate={{ opacity: 1, scale: 1, y: 0 }}
                exit={{ opacity: 0, scale: 0, y: 0 }}
                transition={{ duration: 0.9, type: "spring", delay: 0.3 }}
                className={`z-10 ${
                  DATA.titleSize === 1
                    ? "text-2xl"
                    : "text-2xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-4xl"
                } font-title sm:-ml-0.5 font-normal`}
                style={{
                  color: colors[DATA.textColor],
                }}
              >
                {DATA.subtitle}
              </motion.h4>
            )}
          </AnimatePresence>
          {!showSubtitle && DATA.subtitle && (
            <h4
              className={`z-10 ${
                DATA.titleSize === 1
                  ? "text-2xl"
                  : "text-2xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-4xl"
              } font-title sm:-ml-0.5 font-normal opacity-0`}
            >
              ..
            </h4>
          )}
        </>
      )}
    </>
  );
}
