import { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function useScrollInViewAnimation(everyTime?: boolean) {
  const controls = useAnimation();
  const { ref, inView } = useInView();
  const hasAnimated = useRef(false);

  useEffect(() => {
    if (!hasAnimated.current || everyTime) {
      if (inView) {
        controls.start("visible");
        hasAnimated.current = true;
      }
      if (!inView) {
        controls.start("hidden");
      }
    }
  }, [controls, inView]);

  return [ref, controls];
}
