import React from "react";
import { CLOUDINARY_CLOUD_NAME } from "../cms/variables";
import { Cloudinary } from "@cloudinary/url-gen";
import { AdvancedImage, placeholder } from "@cloudinary/react";
import useCmsProofBreakpoints from "../hooks/useCmsProofBreakpoints";

type Props = {
  imageData: {
    imageID: string;
    vFocus: number;
    hFocus: number;
    crop: boolean;
    alt?: string;
  };
  crop?: boolean;
  noPlugins?: boolean;
  previewMode?: boolean;
};

const ImageComponent = React.memo(function ImageComponent({
  imageData,
  crop,
  noPlugins,
  previewMode,
}: Props) {
  const cld = new Cloudinary({
    cloud: {
      cloudName: CLOUDINARY_CLOUD_NAME,
    },
  });
  const img = cld.image(imageData.imageID);
  let gatsbyBreakpoints = useCmsProofBreakpoints();

  return (
    <>
      {imageData.imageID && (
        <>
          <AdvancedImage
            cldImg={img}
            alt={imageData.alt ? imageData.alt : imageData.imageID}
            plugins={[]}
            className="m-auto"
            style={{
              alignSelf: crop ? "auto" : "center",
              maxHeight: "100%",
              maxWidth: "100%",
              height: crop ? "100%" : "",
              width: crop ? "100%" : "",
              objectFit: crop ? "cover" : "contain",
              objectPosition: `${imageData.hFocus}% ${imageData.vFocus}%`,
            }}
          />
        </>
      )}
    </>
  );
});

export default ImageComponent;
