/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import ImageComponent from "../ImageComponent";
import HighlightBlock from "./HighlightBlock";
import { useState } from "react";
import {
  ButtonCTA,
  colors,
  getXPaddingAmtFromNumber,
  vPaddingMultiplier,
  xPaddingAmtMobile,
} from "../../styles/style";
import { fillInMissingBlockDataWithDefaults } from "../../cms/functions";
import { creditsBlockInit } from "../../cms/CMSComponents/CONTROLLER_COMPONENTS/CreditsBlockControl";
import Expand from "react-expand-animated";
import { motion } from "framer-motion";
import useScrollInViewAnimation from "../../hooks/UseScrollInViewAnimation";
import ANIMATIONS from "../../styles/animations";
import { testimonialBlockInit } from "../../cms/CMSComponents/CONTROLLER_COMPONENTS/TestimonialBlockControl";
import { logoBlockInit } from "../../cms/CMSComponents/CONTROLLER_COMPONENTS/LogoBlockControl";

type Props = {
  blockContent: string;
  index: number;
  highlighted: boolean;
  pageTitleColor: number;
  breakPoint: number;
};

function LogoBlock({
  blockContent,
  index,
  highlighted,
  pageTitleColor,
  breakPoint,
}: Props) {
  const [selectedPage, setSelectedPage] = useState(0);

  const DATA = fillInMissingBlockDataWithDefaults(
    JSON.parse(blockContent),
    logoBlockInit
  );

  let widthClass = "w-full";
  if (DATA.textBoxWidth === 1) widthClass = "md:w-5/6";
  if (DATA.textBoxWidth === 2) widthClass = "md:w-3/4";
  if (DATA.textBoxWidth === 3) widthClass = "md:w-1/2";

  const logoArr = [
    DATA.img1,
    DATA.img2,
    DATA.img3,
    DATA.img4,
    DATA.img5,
    DATA.img6,
    DATA.img7,
    DATA.img8,
    DATA.img9,
    DATA.img10,
    DATA.img11,
    DATA.img12,
    DATA.img13,
    DATA.img14,
  ];

  const pageArr = ["", "", "", ""];

  const mobileLogoSize = "40vw";
  let xPaddingAmt = getXPaddingAmtFromNumber(DATA.contentWidth, breakPoint);

  return (
    <section
      id={"container" + index}
      className={`relative  w-screen  transition-colors flex flex-col justify-center`}
      style={{
        backgroundColor: colors[DATA.bgColor],
        padding: `${DATA.vPaddingTop * vPaddingMultiplier}px ${xPaddingAmt}px ${
          DATA.vPaddingBottom * vPaddingMultiplier
        }px`,
      }}
    >
      <h3
        className={`text-center m-auto text-4xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl font-title font-normal`}
        style={{
          color: colors[DATA.textColor],
        }}
      >
        {DATA.title}
      </h3>
      {/* DESKTOP VERSION */}
      <div className="sm:block hidden mt-6">
        <div className="w-full flex justify-around items-center flex-wrap">
          <DesktopLogo imageData={logoArr[0]} />
          <DesktopLogo imageData={logoArr[1]} />
          <DesktopLogo imageData={logoArr[2]} />
          <DesktopLogo imageData={logoArr[3]} />
          <DesktopLogo imageData={logoArr[4]} />
          <DesktopLogo imageData={logoArr[5]} />
          <DesktopLogo imageData={logoArr[6]} />
        </div>
        <div className="w-full flex justify-around items-center flex-wrap">
          <DesktopLogo imageData={logoArr[7]} />
          <DesktopLogo imageData={logoArr[8]} />
          <DesktopLogo imageData={logoArr[9]} />
          <DesktopLogo imageData={logoArr[10]} />
          <DesktopLogo imageData={logoArr[11]} />
          <DesktopLogo imageData={logoArr[12]} />
          <DesktopLogo imageData={logoArr[13]} />
        </div>
      </div>

      {/* MOBILE VERSION */}
      <div className="sm:hidden block mt-4">
        <div className="w-full flex justify-around items-center flex-wrap">
          <MobileLogo imageData={logoArr[selectedPage * 4]} />
          <MobileLogo imageData={logoArr[1 + selectedPage * 4]} />
          <MobileLogo imageData={logoArr[2 + selectedPage * 4]} />
          <MobileLogo imageData={logoArr[3 + selectedPage * 4]} />
        </div>
        <div className="flex mt-8 justify-center">
          {pageArr.map((page: any, index: number) => (
            <div
              key={index}
              className="w-8 h-8 flex justify-center items-center"
              role="button"
              onClick={() => setSelectedPage(index)}
            >
              <div
                className="w-3 h-3 block rounded-full"
                style={{
                  backgroundColor:
                    index === selectedPage ? colors[3] : colors[2],
                }}
              ></div>
            </div>
          ))}
        </div>
      </div>

      {highlighted && <HighlightBlock />}
    </section>
  );
}

function MobileLogo({ imageData }: any) {
  return (
    <div style={{ width: "40%" }}>
      {imageData && <ImageComponent imageData={imageData} />}
    </div>
  );
}

function DesktopLogo({ imageData }: any) {
  return (
    <div style={{ width: "11%" }}>
      {imageData && <ImageComponent imageData={imageData} />}
    </div>
  );
}

export default LogoBlock;
