/** @jsx jsx */

import { useState } from "react";
import {
  FullWidthInput,
  TextArea,
  ControllerSection,
  COLORS,
  SmallMargin,
} from "../../style/styledComponents";
import { cloneDeep } from "lodash-es";
import { css, jsx } from "@emotion/react";
import XOptionsRadioButtons from "../PAGEBUILDER_UTILITY_COMPONENTS/XOptionsRadioButtons";
import ImageSelector from "../PAGEBUILDER_UTILITY_COMPONENTS/ImageSelector";
import CategoryExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/CategoryExpandableBar";
import BlockColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockColorOptions";
import VerticalPaddingSliders from "../PAGEBUILDER_UTILITY_COMPONENTS/VerticalPaddingSliders";

interface Props {
  content: any;
  index: number;
  handleBlockChange: Function;
  disabledStyle: string;
}

export const titleBlockInit = {
  title: "Title Text",
  subtitle: "subtitle",
  italicTitle: false,
  textAlign: 0,
  titleSize: 0,
  imageData: { imageID: "", crop: true, vFocus: 50, hFocus: 50 },
  hFocus: 50,
  vFocus: 50,
  titleColor: 0,
  textColor: 0,
  bgColor: 0,
  vPaddingTop: 30,
  vPaddingBottom: 30,
};

export const TitleBlockControl = ({
  content,
  index,
  handleBlockChange,
  disabledStyle,
}: Props) => {
  const handleValueChange = (key: string, value: string | boolean | number) => {
    const newContent = cloneDeep(content);
    newContent[key] = value;
    handleBlockChange({ ...newContent }, index);
  };

  return (
    <>
      <CategoryExpandableBar title="TEXT" index={index}>
        <ControllerSection>
          <label htmlFor="title1">Title</label>
          <FullWidthInput
            type="text"
            value={content.title}
            onChange={(e) => handleValueChange("title", e.target.value)}
          />
          <SmallMargin />
          <input
            type="checkbox"
            checked={content.italicTitle}
            onChange={() => {
              handleValueChange("italicTitle", !content.italicTitle);
            }}
          ></input>
          <label>Italic title</label>
        </ControllerSection>

        <ControllerSection>
          <label htmlFor="subtitle">Subtitle</label>
          <FullWidthInput
            type="text"
            value={content.subtitle}
            onChange={(e) => handleValueChange("subtitle", e.target.value)}
          />{" "}
        </ControllerSection>
        <ControllerSection>
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <input
              type="checkbox"
              checked={content.isMainTitle}
              onChange={() => {
                handleValueChange("isMainTitle", !content.isMainTitle);
              }}
            ></input>
            <label style={{ fontWeight: 700 }}>
              This block is the main title of this page (use H1 and H2 instead
              of H3 and H4 elements for the title)
            </label>
          </div>
        </ControllerSection>
        <ControllerSection>
          <input
            type="checkbox"
            checked={content.animateTitle}
            onChange={() => {
              handleValueChange("animateTitle", !content.animateTitle);
            }}
          ></input>
          <label>Animate Title</label>
        </ControllerSection>
        <ControllerSection>
          <span>Title Size</span>
          <XOptionsRadioButtons
            propName="titleSize"
            currentVal={content.titleSize}
            handleChange={handleValueChange}
            index={index}
            labels={["Normal", "Small"]}
          />
        </ControllerSection>

        <ControllerSection>
          <span>Text Align</span>
          <XOptionsRadioButtons
            propName="textAlign"
            currentVal={content.textAlign}
            handleChange={handleValueChange}
            index={index}
            labels={["left", "center", "right"]}
          />
        </ControllerSection>
      </CategoryExpandableBar>
      <CategoryExpandableBar title="BACKGROUND IMAGE" index={index}>
        <ControllerSection>
          <ImageSelector
            currentValue={content.imageData}
            handleValueChange={handleValueChange}
            label="Background Image:"
            propName="imageData"
            croppedByDefault
          />
        </ControllerSection>
      </CategoryExpandableBar>
      <CategoryExpandableBar title="BLOCK OPTIONS" index={index}>
        <VerticalPaddingSliders
          vPaddingBottom={content.vPaddingBottom}
          vPaddingTop={content.vPaddingTop}
          handleValueChange={handleValueChange}
        />
      </CategoryExpandableBar>
      <BlockColorOptions
        titleColor={content.titleColor}
        bgColor={content.bgColor}
        textColor={content.textColor}
        handleValueChange={handleValueChange}
      />
    </>
  );
};
export default TitleBlockControl;
