import React, { useRef, useState, useEffect } from "react";
import useIntersectionCallback from "../hooks/useInterSectionCallback";
import { colors } from "../styles/style";
import { cubicOut } from "eases";

type props = {
  bgColor: number;
  growthSize: number;
  offsetY: number;
  offsetX: number;
  offsetXMobile: number;
  offsetYMobile: number;
  growthSizeMobile: number;
  maxMovement: number;
  noAnimation: boolean;
};

const scaleArea = 0.45,
  moveArea = 0.5,
  moveOffset = 2,
  growth = 70,
  minOpacity = 0,
  opacityGrowth = 1,
  opacityArea = 0.5,
  maxMovement = 150;

export default function DecorationCircle({
  bgColor,
  growthSize = 90,
  offsetY = 24,
  offsetX = 0,
  maxMovement = 150,
  offsetXMobile,
  noAnimation,
  growthSizeMobile,
  offsetYMobile,
}: props) {
  const [ref, yFactor] = useIntersectionCallback();
  const [refMobile, yFactorMobile] = useIntersectionCallback();
  const maxOpacity = 0.7;

  const getFactor = (fadeArea: number, yFactor: number) => {
    if (noAnimation) {
      return 1;
    } else {
      if (yFactor <= fadeArea) {
        return cubicOut(yFactor / fadeArea);
      } else if (1 - yFactor <= fadeArea) {
        return cubicOut((1 - yFactor) / fadeArea);
      } else if (yFactor >= fadeArea && 1 - yFactor > fadeArea) {
        return 1;
      } else {
        return 0;
      }
    }
  };

  const getContinueFactor = (fadeArea: number, yFactor: number) => {
    if (noAnimation) {
      return 1;
    } else {
      if (yFactor <= fadeArea) {
        return -1 + cubicOut(yFactor / fadeArea);
      } else if (1 - yFactor <= fadeArea) {
        return 1 - cubicOut((1 - yFactor) / fadeArea);
      } else if (yFactor >= fadeArea && 1 - yFactor > fadeArea) {
        return 0;
      } else {
        return 0;
      }
    }
  };

  return (
    <>
      <div
        ref={refMobile}
        className=" rounded-full absolute sm:hidden block"
        style={{
          top: offsetYMobile / 16 + "rem",
          backgroundColor: colors[bgColor],
          zIndex: 0,
          width: `${
            (getFactor(scaleArea, yFactorMobile) * growthSizeMobile) / 2 +
            growthSizeMobile / 2
          }rem`,
          height: `${
            (getFactor(scaleArea, yFactorMobile) * growthSizeMobile) / 2 +
            growthSizeMobile / 2
          }rem`,
          transform: "translate(-50%, -50%)",
          left: `${
            (getContinueFactor(moveArea, yFactorMobile) * maxMovement +
              moveOffset +
              offsetXMobile) /
            16
          }rem`,
          opacity: `${Math.min(
            maxOpacity,
            getFactor(opacityArea, yFactorMobile) * opacityGrowth + minOpacity
          )}`,
        }}
      ></div>
      <div
        ref={ref}
        className=" rounded-full absolute hidden sm:block"
        style={{
          top: offsetY / 16 + "rem",
          backgroundColor: colors[bgColor],
          zIndex: 0,
          width: `${
            (getFactor(scaleArea, yFactor) * growthSize) / 2 + growthSize / 2
          }rem`,
          height: `${
            (getFactor(scaleArea, yFactor) * growthSize) / 2 + growthSize / 2
          }rem`,
          transform: "translate(-50%, -50%)",
          left: `${
            (getContinueFactor(moveArea, yFactor) * maxMovement +
              moveOffset +
              offsetX) /
            16
          }rem`,
          opacity: `${Math.min(
            maxOpacity,
            getFactor(opacityArea, yFactor) * opacityGrowth + minOpacity
          )}`,
        }}
      ></div>
    </>
  );
}
