import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import { FooterType } from "./FooterBuilder";
import { BreakpointProvider } from "gatsby-plugin-breakpoints";

const PreviewComponent = React.memo(function PreviewComponent(props: any) {
  let page = props.entry.toJS().data.pageBuilder;

  /*
  function handleMessage(event: any) {
    console.log(event);
  }

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  });
  */

  return (
    <>
      {page ? (
        <Layout
          page={page}
          highlightedBlock={page.highlightedBlock}
          previewMode
        />
      ) : (
        <span className="mx-8 my-8 text-xl">
          Add Blocks to start building your page!
        </span>
      )}
    </>
  );
});

export default PreviewComponent;

const exampleBlogPost = {
  blogOptions: JSON.stringify({
    previewTitle: "Your New Blog Post",
    previewText: "Your actual blog posts will appear here on the live website",
    createdAt: "2021-10-06",
    tags: "blogposts,demo",
    slug: "/",
    previewImage: { crop: true, hFocus: 50, vFocus: 50, imageID: "" },
  }),
};

export const exampleBlogPosts = [
  { ...exampleBlogPost },
  { ...exampleBlogPost },
  { ...exampleBlogPost },
  { ...exampleBlogPost },
  { ...exampleBlogPost },
  { ...exampleBlogPost },
  { ...exampleBlogPost },
];
