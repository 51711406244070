import { useBreakpoint } from "gatsby-plugin-breakpoints";

export default function useCmsProofBreakpoints() {
  let gatsbyBreakpoints = { sm: false, md: false };
  if (typeof window !== "undefined") {
    const preview = document.getElementById("preview-pane");
    if (!preview) gatsbyBreakpoints = useBreakpoint();
  } else {
    gatsbyBreakpoints = useBreakpoint();
  }
  return gatsbyBreakpoints;
}
