import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";
import { useStaticQuery, graphql, Link } from "gatsby";
import { findHeaderData } from "../functions";

type Props = {
  titleColor: number;
  bgColor: number;
  breakPoint: number;
};

export default function Header({ titleColor, bgColor, breakPoint }: Props) {
  const data = useStaticQuery(
    graphql`
      query HeaderQuery {
        allMarkdownRemark {
          edges {
            node {
              frontmatter {
                headerBuilder {
                  logoImage {
                    imageID
                    vFocus
                    hFocus
                  }
                  menuItems {
                    linkUrl
                    text
                    children {
                      linkUrl
                      text
                    }
                  }
                }
              }
              fileAbsolutePath
            }
          }
        }
      }
    `
  );
  const headerData = findHeaderData(data.allMarkdownRemark.edges);

  return (
    <header className="w-screen relative " style={{ zIndex: 9999 }}>
      <DesktopMenu
        logoImage={headerData.logoImage}
        menuItems={headerData.menuItems}
        bgColor={bgColor}
        titleColor={titleColor}
      />

      <MobileMenu
        logoImage={headerData.logoImage}
        menuItems={headerData.menuItems}
        bgColor={bgColor}
        titleColor={titleColor}
      />
    </header>
  );
}
