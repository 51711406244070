/** @jsx jsx */

import { useState } from "react";
import { css, jsx } from "@emotion/react";
import ImageComponent from "../ImageComponent";
import HighlightBlock from "./HighlightBlock";

import {
  colors,
  getTextAlignFromNumber,
  getXPaddingAmtFromNumber,
  maxContentWidth,
  portfolioRowHeight,
  vPaddingMultiplier,
} from "../../styles/style";
import { fillInMissingBlockDataWithDefaults } from "../../cms/functions";
import { VideoGalleryInit } from "../../cms/CMSComponents/CONTROLLER_COMPONENTS/VideoGalleryControl";
import { PortfolioPreviewItem } from "../PortfolioPreview";
import { sortPortfolioByDate } from "../../functions";

import useScrollInViewAnimation from "../../hooks/UseScrollInViewAnimation";
import ANIMATIONS from "../../styles/animations";
import { motion } from "framer-motion";
import TitleColorAccents from "../TitleColorAccents";
import { Link } from "gatsby";
import ColorAccentsText from "../ColorAccentsText";

type Props = {
  blockContent: string;
  pagePortfolioOptions: string | null;
  index: number;
  highlighted: boolean;
  pageTitleColor: number;
  breakPoint: number;
  setShowVideoLightbox: Function;
  werkPages: any[];
  fictiePages: any[];
  lightPages: any[];
};

function KostenVideosBlock({
  blockContent,
  index,
  highlighted,
  pageTitleColor,
  breakPoint,
  setShowVideoLightbox,
  pagePortfolioOptions,
  werkPages,
  fictiePages,
  lightPages,
}: Props) {
  const DATA = fillInMissingBlockDataWithDefaults(
    JSON.parse(blockContent),
    VideoGalleryInit
  );

  const [refTitle, controlsTitle] = useScrollInViewAnimation();
  const [refImg, controlsImg] = useScrollInViewAnimation();

  let xPaddingAmt = getXPaddingAmtFromNumber(1, breakPoint);

  return (
    <section
      id={"container" + index}
      className={`relative  w-screen transition-colors`}
      style={{
        backgroundColor: colors[DATA.bgColor],
        padding: `${DATA.vPaddingTop * vPaddingMultiplier}px ${xPaddingAmt}px ${
          DATA.vPaddingBottom * vPaddingMultiplier
        }px`,
      }}
    >
      <motion.div
        ref={refTitle}
        initial="hidden"
        animate={controlsTitle}
        variants={ANIMATIONS.fadeScale}
        className="flex flex-col items-center"
      >
        <h3 className="text-4xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl font-title -ml-0.75 sm:-ml-0.5 lg:-ml-1 font-normal">
          <ColorAccentsText
            center
            textColor={DATA.textColor}
            accentColor={
              DATA.titleColor === 0 ? pageTitleColor : DATA.titleColor
            }
            text={DATA.title}
          />
        </h3>
      </motion.div>
      <motion.div
        ref={refImg}
        initial="hidden"
        animate={controlsImg}
        variants={ANIMATIONS.staggerContainerDelay}
        className="md:flex w-full justify-between mx-auto"
        style={{ maxWidth: maxContentWidth + "px" }}
      >
        <div
          className="md:w-1/3 w-full md:mr-2 h-full mt-8 relative"
          style={{ height: portfolioRowHeight + "px" }}
        >
          <PortfolioPreviewItem
            title=""
            subtitle=""
            previewImage={DATA.video1Image}
            previewVideo={DATA.video1Video}
            slug={DATA.video1Link}
            setShowVideoLightbox={() => {}}
            textColor={0}
            index={0}
            rowIndex={0}
            blockIndex={index}
          />
          <div className="w-full absolute text-center top-1/2 transform -translate-y-1/2 z-50 pointer-events-none text-3xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-4xl font-title sm:-ml-0.5 font-bold">
            {DATA.video1Text}
          </div>
        </div>

        <div
          className="md:w-1/3 w-full md:mx-2 h-full mt-8 relative"
          style={{ height: portfolioRowHeight + "px" }}
        >
          <PortfolioPreviewItem
            title=""
            subtitle=""
            previewImage={DATA.video2Image}
            previewVideo={DATA.video2Video}
            slug={DATA.video2Link}
            setShowVideoLightbox={() => {}}
            textColor={0}
            index={0}
            rowIndex={0}
            blockIndex={index}
          />
          <h3 className="w-full absolute text-center top-1/2 transform -translate-y-1/2 z-50 pointer-events-none">
            {DATA.video2Text}
          </h3>
        </div>

        <div
          className="md:w-1/3 w-full md:ml-2 h-full mt-8 relative"
          style={{ height: portfolioRowHeight + "px" }}
        >
          <PortfolioPreviewItem
            title=""
            subtitle=""
            previewImage={DATA.video3Image}
            previewVideo={DATA.video3Video}
            slug={DATA.video3Link}
            setShowVideoLightbox={() => {}}
            textColor={0}
            index={0}
            rowIndex={0}
            blockIndex={index}
          />
          <h3 className="w-full absolute text-center top-1/2 transform -translate-y-1/2 z-50 pointer-events-none">
            {DATA.video3Text}
          </h3>
        </div>
      </motion.div>
      <Link
        to={DATA.bottomLink}
        className="w-full mx-auto text-center block mt-4 hover:underline"
      >
        {DATA.bottomText}
      </Link>
      {DATA.imageData && (
        <div
          className={`absolute top-0 left-0 w-full h-full  overflow-hidden`}
          style={{ zIndex: -10 }}
        >
          <ImageComponent imageData={DATA.imageData} crop={true} />
        </div>
      )}

      {highlighted && <HighlightBlock />}
    </section>
  );
}

export default KostenVideosBlock;
