/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import { useState, useEffect, useRef } from "react";
import HighlightBlock from "./HighlightBlock";
import {
  ButtonCTA,
  colors,
  getTextAlignFromNumber,
  getXPaddingAmtFromNumber,
  maxContentWidth,
  vPaddingMultiplier,
} from "../../styles/style";
import { Link } from "gatsby";
import { fillInMissingBlockDataWithDefaults } from "../../cms/functions";
import { blogPostsPreviewInit } from "../../cms/CMSComponents/CONTROLLER_COMPONENTS/BlogPostsPreviewBlockControl";
import BlogPostPreview from "../BlogPostPreview";
import { allBlogPostsBlockInit } from "../../cms/CMSComponents/CONTROLLER_COMPONENTS/AllBlogPostsBlockControl";
import { cloneDeep } from "lodash-es";
import { sortBlogPostsByDate } from "../../functions";
import { motion } from "framer-motion";
import useScrollInViewAnimation from "../../hooks/UseScrollInViewAnimation";
import ANIMATIONS from "../../styles/animations";
import VimeoVideoComponent from "../VimeoVideoComponent";
import ImageComponent from "../ImageComponent";

type Props = {
  blockContent: string;
  index: number;
  highlighted: boolean;
  blogPosts: any[];
  pageTitleColor: number;
  breakPoint: number;
};

function AllBlogPostsBlock({
  blockContent,
  index,
  highlighted,
  blogPosts,
  pageTitleColor,
  breakPoint,
}: Props) {
  const [tags, setTags] = useState<{ [X: string]: any }>({});
  const tagsRef = useRef<{ [X: string]: any }>({});
  const [filteredBlogPosts, setFilteredBlogposts] = useState<any[]>([]);
  const DATA = fillInMissingBlockDataWithDefaults(
    JSON.parse(blockContent),
    allBlogPostsBlockInit
  );

  let xPaddingAmt = getXPaddingAmtFromNumber(DATA.contentWidth, breakPoint);

  useEffect(() => {
    const blogPostsInstance = cloneDeep(blogPosts);
    blogPostsInstance.forEach((post: any) => {
      post.blogOptions = JSON.parse(post.blogOptions);
      post.blogOptions.tags = post.blogOptions.tags.split(",");
      post.blogOptions.tags.forEach((tag: string) => {
        if (tag) tagsRef.current[tag] = false;
      });
      setTags({ ...tagsRef.current });
    });

    setFilteredBlogposts(sortBlogPostsByDate(blogPostsInstance));
  }, []);

  const [itemsRef, itemsControls] = useScrollInViewAnimation(true);

  const updateTags = (tag: string) => {
    tagsRef.current[tag] = !tagsRef.current[tag];

    const blogPostsInstance = cloneDeep(blogPosts);
    blogPostsInstance.forEach((post: any) => {
      post.blogOptions = JSON.parse(post.blogOptions);
      post.blogOptions.tags = post.blogOptions.tags.split(",");
    });

    const selectedTags = Object.keys(tagsRef.current).filter((tag) => {
      return tagsRef.current[tag];
    });

    const filteredPosts = blogPostsInstance.filter((post: any) => {
      return selectedTags.every((t) => post.blogOptions.tags.includes(t));
    });

    setTags({ ...tagsRef.current });
    setFilteredBlogposts(filteredPosts);
  };

  return (
    <section
      id={"container" + index}
      className={`relative  w-screen transition-colors flex flex-col items-center`}
      style={{
        backgroundColor: colors[DATA.bgColor],
        padding: `${DATA.vPaddingTop * vPaddingMultiplier}px ${xPaddingAmt}px ${
          DATA.vPaddingBottom * vPaddingMultiplier
        }px`,
        color: colors[DATA.textColor],
      }}
    >
      <div
        className="w-full mx-auto flex flex-col items-center"
        style={{ maxWidth: maxContentWidth + "px" }}
      >
        <div>
          <h1
            className="block text-center"
            style={{
              color:
                DATA.titleColor === 0
                  ? colors[pageTitleColor]
                  : colors[DATA.titleColor],
            }}
          >
            {DATA.title}
          </h1>
          <h2
            className="block text-center mb-4"
            style={{
              color:
                DATA.titleColor === 0
                  ? colors[pageTitleColor]
                  : colors[DATA.titleColor],
            }}
          >
            {DATA.subTitle}
          </h2>
        </div>
        <h4
          className="inline-block text-center mt-4 text-2xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-4xl font-title sm:-ml-0.5 font-normal"
          style={{
            color: colors[DATA.titleColor],
          }}
        >
          Tags
        </h4>

        <div className="flex flex-wrap w-full justify-center">
          {Object.keys(tags).map((tag, index) => {
            return (
              <div className="mx-3 mt-2" key={"blogpost" + index}>
                <ButtonCTA
                  selected={tags[tag]}
                  text={tag}
                  size={1}
                  color1={DATA.bgColor}
                  color2={DATA.titleColor}
                  onClick={() => updateTags(tag)}
                ></ButtonCTA>
              </div>
            );
          })}
        </div>
        <div>
          {filteredBlogPosts.map((post: any, index) => {
            //  if (index <= DATA.amtOfPosts) {
            return (
              <motion.div variants={ANIMATIONS.fadeScaleSubtle} key={index}>
                <BlogPostPreview
                  title={post.blogOptions.previewTitle}
                  previewText={post.blogOptions.previewText}
                  image={post.blogOptions.previewImage}
                  tags={post.blogOptions.tags}
                  pageTitleColor={pageTitleColor}
                  slug={post.slug}
                  linkText={DATA.linkText1}
                  textColor={DATA.textColor}
                  titleColor={DATA.titleColor}
                  key={index}
                />
              </motion.div>
            );
            //  }
          })}
        </div>
        <Link className="relative" to="/blog">
          <h2
            className="inline-block text-center  hoverScaleXL"
            style={{
              color:
                DATA.titleColor === 0
                  ? colors[pageTitleColor]
                  : colors[DATA.titleColor],
            }}
          >
            {DATA.linkText2}
          </h2>
        </Link>
      </div>
      {highlighted && <HighlightBlock />}
    </section>
  );
}

export default AllBlogPostsBlock;
