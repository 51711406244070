/** @jsx jsx */
import { Component, useState, useRef } from "react";
import {
  Input,
  ExplanationP,
  ControllerSection,
  SmallMargin,
  COLORS,
  SHADOWS,
} from "../../style/styledComponents";
import { jsx, css } from "@emotion/react";
import { cloneDeep } from "lodash-es";
import IoIosArrowDown from "../../../images/icons/angle-down-solid";
import IoIosArrowUp from "../../../images/icons/angle-up-solid";

import AiOutlineClose from "../../../images/icons/xmark-solid";
import AiOutlineArrowUp from "../../../images/icons/arrow-up-solid";
import AiOutlineArrowDown from "../../../images/icons/arrow-down-solid";
import Expand from "react-expand-animated";
import ImageSelector, { imageData } from "./ImageSelector";
import VideoSelector, { videoData } from "./VideoSelector";
import CategoryExpandableBar from "./CategoryExpandableBar";
import XOptionsRadioButtons from "./XOptionsRadioButtons";
import { any, string } from "prop-types";

type PortfolioPreviewProps = {
  handleValueChange: Function;
  propName: string;
  currentVal: PortfolioRow[];
  onlyImages?: boolean;
};

export const PortfolioRowNames = {
  threeSameSize: "Three Same Size",
  twoSameSize: "Two Same Size",
  oneLarge: "One Item, filling the whole row",
  oneLargeOneSmall: "One large and one small Item",
  oneXLTwoSmall: "One extra high item, with two small ones next to it",
};

type PortfolioRow = {
  rowName: string;
  rowHeight: number;
  switchOrder: boolean;
  children: portfolioPreviewItem[];
};

export type portfolioPreviewItem = {
  previewTitle: string;
  previewSubtitle: string;
  previewImage: imageData;
  previewVideo: videoData;
  link: string;
};

const initPortfolioItem = {
  previewTitle: "Title",
  previewSubtitle: "PreviewSubtitle",
  previewImage: {
    imageID: "",
    crop: true,
    hFocus: 50,
    vFocus: 50,
  },
  previewVideo: {
    videoID: "",
    aspectRatioX: 16,
    aspectRatioY: 9,
  },
  link: "",
};

export default function PortfolioPreviewBuilder({
  handleValueChange,
  propName,
  currentVal,
  onlyImages,
}: PortfolioPreviewProps) {
  const formRef = useRef(null);

  function handleAddRow(rowName: string) {
    if (formRef.current) {
      formRef.current.value = "";
    }
    const newChildren = cloneDeep(currentVal);
    const newRow: PortfolioRow = {
      rowName: rowName,
      switchOrder: false,
      children: [],
      rowHeight: 0,
    };

    switch (rowName) {
      case PortfolioRowNames.twoSameSize:
        newRow.children = [{ ...initPortfolioItem }, { ...initPortfolioItem }];
        break;
      case PortfolioRowNames.oneLarge:
        newRow.children = [{ ...initPortfolioItem }];
        break;
      case PortfolioRowNames.threeSameSize:
        newRow.children = [
          { ...initPortfolioItem },
          { ...initPortfolioItem },
          { ...initPortfolioItem },
        ];
        break;
      case PortfolioRowNames.oneXLTwoSmall:
        newRow.children = [
          { ...initPortfolioItem },
          { ...initPortfolioItem },
          { ...initPortfolioItem },
        ];
        break;
      case PortfolioRowNames.oneLargeOneSmall:
        newRow.children = [{ ...initPortfolioItem }, { ...initPortfolioItem }];
        break;
    }
    newChildren.push({ ...newRow });
    handleValueChange(propName, [...newChildren]);
  }

  function handleDeleteRow(deleteIndex: number) {
    const newChildren = cloneDeep(currentVal);
    newChildren.splice(deleteIndex, 1);
    handleValueChange(propName, [...newChildren]);
  }

  function handleMoveRow(index: number, amt: number) {
    if (
      !(
        (index === 0 && amt === -1) ||
        (index === currentVal.length - 1 && amt === 1)
      )
    ) {
      const newChildren = cloneDeep(currentVal);
      const movedItem = newChildren.splice(index, 1);
      newChildren.splice(index + amt, 0, movedItem[0]);
      handleValueChange(propName, [...newChildren]);
    }
  }
  function handleRowChildUpdate(
    key: "previewTitle" | "previewSubtitle" | "previewImage" | "previewVideo",
    value: any,
    parentIndex: number,
    childIndex: number
  ) {
    const newChildren = cloneDeep(currentVal);
    newChildren[parentIndex].children[childIndex][key] = value;

    handleValueChange(propName, [...newChildren]);
  }

  function handleRowUpdate(key: "switchOrder", value: any, rowIndex: number) {
    const newChildren = cloneDeep(currentVal);
    newChildren[rowIndex][key] = value;
    handleValueChange(propName, [...newChildren]);
  }

  return (
    <ControllerSection>
      <label
        css={css`
          display: block;
        `}
      >
        Rows:
      </label>

      <ExplanationP>Add Row:</ExplanationP>
      <form>
        <select
          css={css`
            width: 100%;
            min-width: none;
          `}
          ref={formRef}
          onChange={(e) => handleAddRow(e.target.value)}
        >
          <option value="" disabled selected hidden>
            Choose row type...
          </option>
          {Object.keys(PortfolioRowNames).map((rowName) => {
            return (
              <option value={PortfolioRowNames[rowName]}>
                {PortfolioRowNames[rowName]}
              </option>
            );
          })}
        </select>
      </form>
      <SmallMargin />
      <div>
        {currentVal.map((row, index) => {
          return (
            <>
              <PortfolioRow
                rowData={row}
                index={index}
                handleDeleteRow={handleDeleteRow}
                handleMoveRow={handleMoveRow}
                handleRowUpdate={handleRowUpdate}
                handleRowChildUpdate={handleRowChildUpdate}
                amtOfItems={currentVal.length}
                onlyImages={onlyImages}
              />
            </>
          );
        })}
      </div>
    </ControllerSection>
  );
}

type PortfolioRowProps = {
  rowData: PortfolioRow;
  index: number;
  handleDeleteRow: Function;
  handleMoveRow: Function;
  handleRowUpdate: Function;
  handleRowChildUpdate: Function;
  amtOfItems: number;
  onlyImages?: boolean;
};

function PortfolioRow({
  rowData,
  index,
  handleDeleteRow,
  handleMoveRow,
  handleRowUpdate,
  handleRowChildUpdate,
  amtOfItems,
  onlyImages,
}: PortfolioRowProps) {
  const transitions = ["height", "opacity", "background"];
  const [exp, setExp] = useState(false);

  function handleValueChange(propName: string, value: any) {
    handleRowUpdate(propName, value, index);
  }

  return (
    <div>
      <div
        css={css`
          background-color: ${COLORS.variation6};
          box-shadow: ${SHADOWS.medium};
          //border: 1px solid black;
          border-radius: 8px;
          margin-bottom: 8px;
          margin-top: 6px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 5px 10px;
        `}
      >
        <div
          css={css`
            display: flex;
            width: 50px;
          `}
        >
          {exp ? (
            <IoIosArrowUp
              css={css`
                height: 16px;
                width: 16px;
                transition: transform 0.3s;
                align-self: center;
                &:hover {
                  cursor: pointer;
                  transform: scale(1.2);
                }
              `}
              onClick={() => setExp(false)}
            />
          ) : (
            <IoIosArrowDown
              css={css`
                height: 16px;
                width: 16px;
                transition: transform 0.3s;
                align-self: center;
                &:hover {
                  cursor: pointer;
                  transform: scale(1.2);
                }
              `}
              onClick={() => setExp(true)}
            />
          )}
        </div>
        <span
          css={css`
            display: block;
          `}
        >
          {rowData.rowName}
        </span>
        <div
          css={css`
            display: flex;
            width: 80px;
            justify-content: space-between;
          `}
        >
          <div
            css={css`
              display: flex;
              width: 50px;
              justify-content: space-between;
              margin-right: 30px;
              align-self: center;
            `}
          >
            <AiOutlineArrowDown
              onClick={() => handleMoveRow(index, 1)}
              css={css`
                width: 20px;
                height: 20px;
                opacity: ${index === amtOfItems - 1 ? 0.5 : 1};
                &:hover {
                  cursor: ${index === amtOfItems - 1 ? "auto" : "pointer"};
                }
              `}
            />
            <AiOutlineArrowUp
              onClick={() => handleMoveRow(index, -1)}
              css={css`
                width: 20px;
                height: 20px;
                opacity: ${index === 0 ? 0.5 : 1};
                &:hover {
                  cursor: ${index === 0 ? "auto" : "pointer"};
                }
              `}
            />
          </div>
          <AiOutlineClose
            css={css`
              height: 16px;
              width: 16px;
              transition: transform 0.3s;
              align-self: center;
              &:hover {
                cursor: pointer;
                transform: scale(1.2);
              }
            `}
            onClick={() => handleDeleteRow(index)}
          />
        </div>
      </div>
      <Expand open={exp} duration={200} transitions={transitions}>
        <div
          css={css`
            width: 100%;
            padding: 4px 10px 4px 0;
          `}
        >
          <ControllerSection>
            <input
              type="checkbox"
              checked={rowData.switchOrder}
              onChange={(e) =>
                handleValueChange("switchOrder", e.target.checked)
              }
            ></input>
            <span> - Reverse row order</span>
          </ControllerSection>
          <ControllerSection>
            <span>Row height:</span>
            <XOptionsRadioButtons
              propName="rowHeight"
              handleChange={handleValueChange}
              labels={["Normal", "Large"]}
              index={index}
              currentVal={rowData.rowHeight}
            />
          </ControllerSection>
          {rowData.children.map((item, childIndex) => {
            return (
              <>
                <PortfolioPreviewItem
                  childIndex={childIndex}
                  parentIndex={index}
                  itemData={item}
                  handleRowChildUpdate={handleRowChildUpdate}
                  onlyImages={onlyImages}
                  key={`${index}-${childIndex}`}
                />
              </>
            );
          })}
        </div>
      </Expand>
    </div>
  );
}

type PortfolioItemProps = {
  childIndex: number;
  parentIndex: number;
  itemData: portfolioPreviewItem;
  handleRowChildUpdate: Function;
  onlyImages?: boolean;
};

function PortfolioPreviewItem({
  childIndex,
  parentIndex,
  itemData,
  handleRowChildUpdate,
  onlyImages,
}: PortfolioItemProps) {
  function handleChange(
    propName: "previewVideo" | "previewImage",
    value: imageData | videoData
  ) {
    handleRowChildUpdate(propName, value, parentIndex, childIndex);
  }
  return (
    <CategoryExpandableBar title={`item #${childIndex + 1}`}>
      {!onlyImages && (
        <>
          <ControllerSection>
            <label>Links To:</label>
            <ExplanationP>
              Link to the project page. Leave out the first part of the URL:
              e.g. for www.awesometestsite.com/fictie/demo enter only
              "/fictie/demo"
            </ExplanationP>
            <Input
              type="text"
              value={itemData.link}
              onChange={(e) =>
                handleRowChildUpdate(
                  "link",
                  e.target.value,
                  parentIndex,
                  childIndex
                )
              }
            ></Input>
          </ControllerSection>
          <ControllerSection>
            <label>Preview Title</label>
            <Input
              type="text"
              value={itemData.previewTitle}
              onChange={(e) =>
                handleRowChildUpdate(
                  "previewTitle",
                  e.target.value,
                  parentIndex,
                  childIndex
                )
              }
            ></Input>
          </ControllerSection>
          <ControllerSection>
            <label>Preview Subtitle</label>
            <Input
              type="text"
              value={itemData.previewSubtitle}
              onChange={(e) =>
                handleRowChildUpdate(
                  "previewSubtitle",
                  e.target.value,
                  parentIndex,
                  childIndex
                )
              }
            ></Input>
          </ControllerSection>
        </>
      )}
      <ControllerSection>
        <ImageSelector
          label={onlyImages ? "Image" : "Preview Image"}
          currentValue={itemData.previewImage}
          handleValueChange={handleChange}
          propName="previewImage"
          croppedByDefault
        />
      </ControllerSection>

      <ControllerSection>
        <label>Preview Video</label>
        <VideoSelector
          currentValue={itemData.previewVideo}
          handleValueChange={handleChange}
          propName="previewVideo"
        />
      </ControllerSection>
    </CategoryExpandableBar>
  );
}
