/** @jsx jsx */
import React, { useState, useRef } from "react";
import { css, jsx } from "@emotion/react";
import ImageComponent from "../ImageComponent";
import HighlightBlock from "./HighlightBlock";

import {
  colors,
  getTextAlignFromNumber,
  getXPaddingAmtFromNumber,
  maxContentWidth,
  vPaddingMultiplier,
} from "../../styles/style";
import { fillInMissingBlockDataWithDefaults } from "../../cms/functions";
import { staffBlockInit } from "../../cms/CMSComponents/CONTROLLER_COMPONENTS/StaffBlockControl";
import VimeoVideoComponent from "../VimeoVideoComponent";
import { motion } from "framer-motion";
import useScrollInViewAnimation from "../../hooks/UseScrollInViewAnimation";
import ANIMATIONS from "../../styles/animations";
import TitleColorAccents from "../TitleColorAccents";
import useIntersection from "../../hooks/useInterSection";
import ColorAccentsText from "../ColorAccentsText";

type Props = {
  blockContent: string;
  index: number;
  highlighted: boolean;
  pageTitleColor: number;
  breakPoint: number;
};

function StaffBlock({
  blockContent,
  index,
  highlighted,
  pageTitleColor,
  breakPoint,
}: Props) {
  const DATA = fillInMissingBlockDataWithDefaults(
    JSON.parse(blockContent),
    staffBlockInit
  );

  let textAlignClass = getTextAlignFromNumber(DATA.titlePosition);
  let xPaddingAmt = getXPaddingAmtFromNumber(DATA.contentWidth, breakPoint);

  const [titleRef, titleControls] = useScrollInViewAnimation();
  const [itemsRef, itemsControls] = useScrollInViewAnimation();

  let textSizeClass = "";
  if (DATA.textSize === 1 && breakPoint !== 0) textSizeClass = "text-lg";
  if (DATA.textSize === 2 && breakPoint !== 0) textSizeClass = "text-xl";

  return (
    <section
      id={"container" + index}
      className={`relative  w-screen transition-colors z-0`}
      style={{
        backgroundColor: colors[DATA.bgColor],
        padding: `${DATA.vPaddingTop * vPaddingMultiplier}px ${xPaddingAmt}px ${
          DATA.vPaddingBottom * vPaddingMultiplier
        }px`,
      }}
    >
      <motion.div
        initial="hidden"
        animate={titleControls}
        variants={ANIMATIONS.fadeScale}
        ref={titleRef}
        className="flex flex-col items-center"
      >
        <h3 className="relative text-center text-4xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl font-title -ml-0.75 sm:-ml-0.5 lg:-ml-1 font-normal">
          <ColorAccentsText
            text={DATA.title}
            textColor={DATA.textColor}
            accentColor={
              DATA.titleColor === 0 ? pageTitleColor : DATA.titleColor
            }
          />
        </h3>
        <h4 className="relative text-center text-2xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-4xl font-title sm:-ml-0.5 font-normal">
          <ColorAccentsText
            text={DATA.subtitle}
            textColor={DATA.textColor}
            accentColor={
              DATA.titleColor === 0 ? pageTitleColor : DATA.titleColor
            }
          />
        </h4>
      </motion.div>
      <motion.div
        initial="hidden"
        animate={itemsControls}
        variants={ANIMATIONS.staggerContainer}
        ref={itemsRef}
        className={`${textSizeClass} w-full sm:flex sm:flex-wrap justify-center mx-auto`}
        style={{
          color: colors[DATA.textColor],
          maxWidth: maxContentWidth + "px",
        }}
      >
        {DATA.items.map((item: any, itemIndex: number) => {
          return (
            <StaffItem
              item={item}
              itemIndex={itemIndex}
              textSizeClass={textSizeClass}
              key={`staff${index}-${itemIndex}`}
              breakPoint={breakPoint}
            />
          );
        })}
      </motion.div>

      {DATA.imageData && (
        <div
          className={`absolute top-0 left-0 w-full h-full z-0 overflow-hidden pointer-events-none`}
        >
          <ImageComponent imageData={DATA.imageData} crop={true} />
        </div>
      )}

      {highlighted && <HighlightBlock />}
    </section>
  );
}

type StaffItemProps = {
  item: any;
  itemIndex: number;
  textSizeClass: string;
  breakPoint: number;
};

function StaffItem({
  item,
  itemIndex,
  textSizeClass,
  breakPoint,
}: StaffItemProps) {
  const [hover, setHover] = useState(false);
  const sizeRef = useRef();
  const [isVisible, yFactor] = useIntersection(sizeRef.current);

  return (
    <div
      className={`${textSizeClass} flex flex-col items-center m-auto sm:mx-8 xl:mx-20  mt-12 `}
      style={{}}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        ref={sizeRef}
        id={"staffItem" + itemIndex}
        className="overflow-hidden relative sm:h-80 sm:w-80 h-64 w-64 rounded-full"
      >
        {item.video && item.video.videoID && (
          <div
            className="w-full h-full absolute left-0 top-0 pointer-events-none rounded-full overflow-hidden"
            style={{ zIndex: hover ? 10 : 0 }}
          >
            <VimeoVideoComponent
              videoData={item.video}
              containerSize={{
                x: sizeRef.current?.offsetWidth,
                y: sizeRef.current?.offsetHeight,
              }}
              playing={breakPoint === 0 ? isVisible : hover}
            />
          </div>
        )}
        <div
          className="w-full h-full absolute left-0 top-0 z-0 rounded-full overflow-hidden"
          style={{ zIndex: hover ? 0 : 10 }}
        >
          <ImageComponent imageData={item.image} crop={true} />
        </div>
      </div>
      <span className="mt-2 text-xl sm:text-2xl  text-center font-black">
        {item.label1}
      </span>
      <span className="text-center">{item.label2}</span>
    </div>
  );
}

export default StaffBlock;
