/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import HighlightBlock from "./HighlightBlock";

import {
  colors,
  getXPaddingAmtFromNumber,
  vPaddingMultiplier,
} from "../../styles/style";

import { fillInMissingBlockDataWithDefaults } from "../../cms/functions";
import { fictiePreviewBlockInit } from "../../cms/CMSComponents/CONTROLLER_COMPONENTS/FictiePreviewBlockControl";
import PortfolioPreview from "../PortfolioPreview";

type Props = {
  blockContent: string;
  index: number;
  highlighted: boolean;
  pageTitleColor: number;
  breakPoint: number;
};

function FictiePreviewBlock({
  blockContent,
  index,
  highlighted,
  pageTitleColor,
  breakPoint,
}: Props) {
  const DATA = fillInMissingBlockDataWithDefaults(
    JSON.parse(blockContent),
    fictiePreviewBlockInit
  );

  let xPaddingAmt = getXPaddingAmtFromNumber(DATA.contentWidth, breakPoint);
  return (
    <section
      id={"container" + index}
      className={`relative  w-screen transition-colors flex flex-col items-center`}
      style={{
        backgroundColor: colors[DATA.bgColor],
        padding: `${DATA.vPaddingTop * vPaddingMultiplier}px ${xPaddingAmt}px ${
          DATA.vPaddingBottom * vPaddingMultiplier
        }px`,
        color: colors[DATA.textColor],
      }}
    >
      <PortfolioPreview rows={DATA.fictieRows} breakPoint={breakPoint} />

      {highlighted && <HighlightBlock />}
    </section>
  );
}

export default FictiePreviewBlock;
