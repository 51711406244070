import { useState, useEffect, useRef } from "react";

export const useIntersection = (element: HTMLElement, offset: number = 0) => {
  const [isVisible, setIsVisible] = useState(false);
  const [yFactor, setYFactor] = useState(0);
  const visibleRef = useRef(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
      visibleRef.current = entry.isIntersecting;
    });

    const handleScroll = () => {
      setYFactor(
        (element.getBoundingClientRect().top + offset) / window.innerHeight
      );
    };

    if (element && typeof window !== "undefined") {
      observer.observe(element);
      document.addEventListener("scroll", handleScroll);
      handleScroll();
    }

    return () => {
      if (element && typeof window !== "undefined") {
        observer.unobserve(element);
        document.removeEventListener("scroll", handleScroll);
      }
    };
  }, [element]);

  return [isVisible, yFactor];
};

export default useIntersection;
