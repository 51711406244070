/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, { useState, useEffect, useRef } from "react";
import { AdvancedImage } from "@cloudinary/react";
import {
  Button,
  COLORS,
  ControllerSection,
  ExplanationP,
  FullWidthInput,
  Input,
} from "../../style/styledComponents";
import { Cloudinary } from "@cloudinary/url-gen";
import { CLOUDINARY_CLOUD_NAME, CLOUDINARY_USERNAME } from "../../variables";

type Props = {
  currentValue: imageData;
  handleValueChange: Function;
  label: string;
  propName: string;
  croppedByDefault?: boolean;
  noExtraOptions?: boolean;
};

export type imageData = {
  imageID: string;
  hFocus: number;
  vFocus: number;
  alt?: number;
  crop: boolean;
  imageURL?: string;
};

export default function ImageSelector({
  currentValue,
  handleValueChange,
  label,
  propName,
  croppedByDefault,
  noExtraOptions,
}: Props) {
  const [img, setImg] = useState<any>();
  const signatureRef = useRef("");
  const timeStampRef = useRef(0);
  const timeoutRef = useRef<any>(null);
  let cld: any;
  if (typeof window !== "undefined") {
    cld = new Cloudinary({
      cloud: {
        cloudName: CLOUDINARY_CLOUD_NAME,
      },
    });
  }

  function refreshSignature() {
    timeoutRef.current = setTimeout(getSignature, 3000000);
  }

  async function getSignature() {
    timeStampRef.current = Date.now();
    const url = `/.netlify/functions/cloudinary?timeStamp=${timeStampRef.current}`;
    try {
      const response = await fetch(url);
      const data = await response.text();
      signatureRef.current = data;
    } catch (err) {
      console.log(err);
    }
  }

  async function selectMedia() {
    if (typeof window !== "undefined") {
      //@ts-ignore
      window.cloudinary.openMediaLibrary(
        {
          cloud_name: CLOUDINARY_CLOUD_NAME,
          username: CLOUDINARY_USERNAME,
          api_key: "381767834996851",
          timestamp: timeStampRef.current,
          signature: signatureRef.current,
          remove_header: true,
          max_files: "1",
          multiple: false,
          insert_caption: "Select",
        },
        {
          insertHandler: (data: any) => {
            handleSubValueChange("imageID", data.assets[0].public_id);
            //handleSubValueChange("imageURL", data.assets[0].url);
          },
        }
      );
    }
  }

  function handleSubValueChange(
    key: "imageID" | "vFocus" | "hFocus" | "crop" | "imageURL" | "alt",
    value: string | number | boolean
  ) {
    let newState: any = { ...currentValue };
    newState[key] = value;
    handleValueChange(propName, newState);
  }

  function removeImage() {
    handleSubValueChange("imageID", "");
    setImg(undefined);
  }
  useEffect(() => {
    getSignature();
    timeoutRef.current = setTimeout(refreshSignature, 3000000);
    if (currentValue?.imageID) {
      setImg(cld.image(currentValue.imageID));
    }
    return () => clearTimeout(timeoutRef.current);
  }, [currentValue?.imageID]);

  return (
    <>
      <span>{label}</span>
      <div
        css={css`
          height: 200px;
          width: 200px;
        `}
      >
        {img ? (
          <AdvancedImage cldImg={img} style={{ maxHeight: "100%" }} />
        ) : (
          <span>-no image selected-</span>
        )}
      </div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          padding-top: 10px;
          width: 260px;
        `}
      >
        <Button bgColor={COLORS.variation7} onClick={selectMedia}>
          {img ? "Change Image" : "Select Image"}
        </Button>
        {img && (
          <Button bgColor={COLORS.variation3} onClick={removeImage}>
            Remove Image
          </Button>
        )}
      </div>

      {!noExtraOptions && (
        <>
          {!croppedByDefault && (
            <ControllerSection>
              <input
                id="cropCheckbox"
                type="checkbox"
                checked={currentValue.crop}
                onChange={() => {
                  handleSubValueChange("crop", !currentValue.crop);
                }}
              ></input>
              <label htmlFor="cropCheckbox">Cover entire container</label>
              <ExplanationP>
                If this is checked, the image will be cropped so it always
                covers its entire container. If not, the entire image will
                always be shown, but it will not cover the entire container.
              </ExplanationP>
            </ControllerSection>
          )}
          <ControllerSection>
            <label>Alt text for image:</label>
            <FullWidthInput
              type="text"
              value={currentValue.alt}
              onChange={(e) => handleSubValueChange("alt", e.target.value)}
            />
          </ControllerSection>
          <ControllerSection>
            <label htmlFor="hFocus">
              Horizontal Image Focus: {currentValue.hFocus}%
            </label>
            <ExplanationP>
              dictates which part of the image is shown when it is cropped
              horizontally
            </ExplanationP>
            <Input
              type="range"
              id="hFocusSlider"
              min={0}
              max={100}
              disabled={!currentValue.crop && !croppedByDefault}
              step={1}
              value={currentValue.hFocus}
              onChange={(e) =>
                handleSubValueChange("hFocus", parseInt(e.target.value, 10))
              }
            ></Input>
          </ControllerSection>
          <ControllerSection>
            <label htmlFor="vFocus">
              Vertical Image Focus: {currentValue.vFocus}%
            </label>
            <ExplanationP>
              dictates which part of the image is shown when it is cropped
              vertically
            </ExplanationP>
            <Input
              type="range"
              id="vFocusSlider"
              min={0}
              disabled={!currentValue.crop && !croppedByDefault}
              max={100}
              step={1}
              value={currentValue.vFocus}
              onChange={(e) =>
                handleSubValueChange("vFocus", parseInt(e.target.value, 10))
              }
            ></Input>
          </ControllerSection>
        </>
      )}
    </>
  );
}

export function initMedia() {}
