import React, { useEffect, useState } from "react";
import cursorDefault from "../images/cursors/cursor_default_2.png";
import cursorDown from "../images/cursors/cursor_down_2.png";

export default function MouseController() {
  const [mousePos, setMousePos] = useState({ x: -100, y: -100 });

  const updateMousePos = (e: MouseEvent) => {
    setMousePos({ x: e.clientX, y: e.clientY });
  };

  const mouseDown = () => {
    const mouse = document.getElementById("mouseImage");
    mouse.style.backgroundImage = `url('${cursorDown}')`;
  };

  const mouseUp = () => {
    const mouse = document.getElementById("mouseImage");
    mouse.style.backgroundImage = `url('${cursorDefault}')`;
  };

  const dispatchMouseEvent = (e: MouseEvent, element: HTMLIFrameElement) => {
    if (element.contentWindow) {
      const clRect = element.getBoundingClientRect();

      element.contentWindow.parent.postMessage("hoi", "*");
      const event = new CustomEvent("iframeMouseMoveMessage", {
        bubbles: true,
        cancelable: false,
        detail: {
          x: e.clientX + clRect.left,
          y: e.clientY + clRect.top,
        },
      });
      element.dispatchEvent(event);
      console.log(element.contentWindow.parent);
    }
  };

  useEffect(() => {
    /*
    const iframes = document.querySelectorAll("iframe");

    iframes.forEach((element) => {
      console.log(element.contentDocument);
      element.contentDocument
        ?.querySelector("body")
        .addEventListener("mousemove", (e) => dispatchMouseEvent(e, element));
    });

    window.addEventListener("iframeMouseMoveMessage", (e) => {
      console.log(e);
    });

    console.log(iframes);
    */
    mouseUp();
    if (typeof window !== "undefined") {
      window.addEventListener("mousemove", updateMousePos);
      window.addEventListener("mousedown", mouseDown);
      window.addEventListener("mouseup", mouseUp);
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("mousemove", updateMousePos);
        window.removeEventListener("mousedown", mouseDown);
        window.removeEventListener("mouseup", mouseUp);
      }
    };
  }, []);

  return (
    <div
      id="mouseImage"
      className="fixed bg-cover transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
      style={{
        width: "32px",
        height: "32px",
        left: mousePos.x + "px",
        top: mousePos.y + "px",
        zIndex: 99999,
      }}
    ></div>
  );
}
