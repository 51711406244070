import React, { ReactElement, useState, useEffect, useRef } from "react";
import { BlockType, BLOCKNAMES } from "../cms/blocks";
import { AnimatePresence } from "framer-motion";
import { BREAKPOINTS, colors } from "../styles/style";
import Footer from "./Footer";
import Header from "./Header";
import { PageType } from "../cms/CMSComponents/PageBuilder";
import SEO from "./SEO";
import { graphql, useStaticQuery } from "gatsby";
import {
  findBlogPosts,
  findWerkPages,
  findLightPages,
  findFictiePages,
} from "../functions";
import { exampleBlogPosts } from "../cms/CMSComponents/PreviewComponent";
import ImageLightBox from "./ImageLightbox";
import { imageData } from "../cms/CMSComponents/PAGEBUILDER_UTILITY_COMPONENTS/ImageSelector";
import MouseController from "./MouseController";
import VideoLightbox from "./VideoLightbox";

import BlogPostsPreviewBlock from "./BLOCKS/BlogPostsPreviewBlock";
import ButtonCTABlock from "./BLOCKS/ButtonCTABlock";
import CreditsBlock from "./BLOCKS/CreditsBlock";
import FictiePreviewBlock from "./BLOCKS/FictiePreviewBlock";

import FlexibleImageGalleryBlock from "./BLOCKS/FlexibleImageGalleryBlock";

import HeroBlock from "./BLOCKS/HeroBlock";
import HeroVideoBlock from "./BLOCKS/HeroVideoBlock";
import ImageGalleryBlock from "./BLOCKS/ImageGalleryBlock";
import ImageVideoBlock from "./BLOCKS/ImageVideoBlock";
import InstagramBlock from "./BLOCKS/InstagramBlock";
import MapBlock from "./BLOCKS/MapBlock";
import StaffBlock from "./BLOCKS/StaffBlock";
import TextBoxBlock from "./BLOCKS/TextBoxBlock";
import TextImgBlock from "./BLOCKS/TextImgBlock";
import TitleBlock from "./BLOCKS/TitleBlock";
import TwoTextColumnsBlock from "./BLOCKS/TwoTextColumnsBlock";
import VideoGalleryBlock from "./BLOCKS/VideoGalleryBlock";
import WerkLightPreviewBlock from "./BLOCKS/WerkLightPreviewBlock";
import AllBlogPostsBlock from "./BLOCKS/AllBlogPostsBlock";
import LatestWorkBlock from "./BLOCKS/LatestWorkBlock";
import ProductieProcesBlock from "./BLOCKS/ProductieProcesBlock";
import TestimonialsBlock from "./BLOCKS/TestimonialsBlock";
import CookieConsentPopup from "./CookieConsentPopup";
import ContactFormBlock from "./BLOCKS/ContactFormBlock";
import KostenVideosBlock from "./BLOCKS/KostenVideosBlock";
import VacaturesBlock from "./BLOCKS/VacaturesBlock";
import LogoBlock from "./BLOCKS/LogoBlock";

interface Props {
  page: PageType;
  highlightedBlock?: number | null;
  previewMode?: boolean;
}

export default function Layout({
  page,
  highlightedBlock = null,
  previewMode,
}: Props): ReactElement {
  // find out which breakpoint the window size is on: -1 = largest, 0 = smallest / mobile, 1 = medium
  const [breakPoint, setBreakPoint] = useState(-1);
  const [overrideBackgroundColor, setOverrideBackgroundColor] = useState(false);
  const [showLightbox, setShowLightbox] = useState(false);
  const [isShowingCookiePopup, setIsShowingCookiePopup] = useState(false);
  const [showVideoLightbox, setShowVideoLightbox] = useState(null);
  const [lightBoxImages, setLightBoxImages] = useState<imageData[]>([]);
  const lightBoxImageAmt = useRef(0);
  const [currentLightboxIndex, setCurrentLightboxIndex] = useState(0);
  const currentLightboxIndexRef = useRef(0);

  const changeLightboxIndex = (amt: number) => {
    currentLightboxIndexRef.current += amt;
    if (currentLightboxIndexRef.current < 0)
      currentLightboxIndexRef.current = lightBoxImageAmt.current - 1;
    if (currentLightboxIndexRef.current >= lightBoxImageAmt.current)
      currentLightboxIndexRef.current = 0;
    setCurrentLightboxIndex(currentLightboxIndexRef.current);
  };

  const toggleLightbox = (index: number, images: imageData[]) => {
    lightBoxImageAmt.current = images.length;
    setLightBoxImages(images);
    setShowLightbox(true);
    currentLightboxIndexRef.current = index;
    setCurrentLightboxIndex(index);
  };

  const data = !previewMode
    ? useStaticQuery(graphql`
        query layoutQuery {
          allMarkdownRemark {
            edges {
              node {
                frontmatter {
                  pageBuilder {
                    blocks {
                      blockContent
                      blockName
                    }
                    title
                    slug
                    pageTextColor
                    pageTitleColor
                    pageBgColor
                    showFooter
                    showHeader
                    metaDescription
                    blogOptions
                    portfolioOptions
                  }
                }
                fileAbsolutePath
              }
            }
          }
        }
      `)
    : {};

  const blogPosts = !previewMode
    ? findBlogPosts(data.allMarkdownRemark.edges)
    : exampleBlogPosts;
  const werkPages = !previewMode
    ? findWerkPages(data.allMarkdownRemark.edges)
    : [];
  const lightPages = !previewMode
    ? findLightPages(data.allMarkdownRemark.edges)
    : [];
  const fictiePages = !previewMode
    ? findFictiePages(data.allMarkdownRemark.edges)
    : [];

  useEffect(() => {
    function checkWindowSize() {
      if (typeof window !== "undefined") {
        const iframe = document.querySelector("#preview-pane");
        if (iframe) {
          setBreakPoint(
            BREAKPOINTS.findIndex(
              (breakPoint) => iframe.contentWindow.innerWidth < breakPoint
            )
          );
        } else {
          setBreakPoint(
            BREAKPOINTS.findIndex(
              (breakPoint) => window.innerWidth < breakPoint
            )
          );
        }
      }
    }
    checkWindowSize();
    if (typeof window !== "undefined") {
      window.addEventListener("resize", checkWindowSize);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", checkWindowSize);
      }
    };
  }, []);

  return (
    <div
      className="w-full transition-colors duration-700 lg:pt-20 hideCursor"
      style={{
        cursor: previewMode ? "auto" : "",
        color: colors[page.pageTextColor],
        /*
        backgroundColor: overrideBackgroundColor
          ? colors[1]
          : colors[page.pageBgColor],

        */
        backgroundColor: colors[page.pageBgColor],
        minHeight: "100vh",
      }}
    >
      {}
      {!previewMode && (
        <SEO title={page.title} description={page.metaDescription} />
      )}
      {!previewMode && (
        <CookieConsentPopup
          isShowingCookiePopup={isShowingCookiePopup}
          setIsShowingCookiePopup={setIsShowingCookiePopup}
        />
      )}
      {page.showHeader && !previewMode && (
        <Header
          titleColor={page.pageTitleColor}
          bgColor={page.pageBgColor}
          breakPoint={breakPoint}
        />
      )}
      {page.blocks.map((block: BlockType, index: number) => {
        let LayoutBlock = () => {
          return <></>;
        };
        if (block.blockName === BLOCKNAMES.blogPostsPreview)
          LayoutBlock = BlogPostsPreviewBlock;
        if (block.blockName === BLOCKNAMES.creditsBlock)
          LayoutBlock = CreditsBlock;
        if (block.blockName === BLOCKNAMES.fictiePreviewBlock)
          LayoutBlock = FictiePreviewBlock;
        if (block.blockName === BLOCKNAMES.flexibleImageGalleryBlock)
          LayoutBlock = FlexibleImageGalleryBlock;
        if (block.blockName === BLOCKNAMES.heroBlock) LayoutBlock = HeroBlock;
        if (block.blockName === BLOCKNAMES.heroVideoBlock)
          LayoutBlock = HeroVideoBlock;
        if (block.blockName === BLOCKNAMES.imageGalleryBlock)
          LayoutBlock = ImageGalleryBlock;
        if (block.blockName === BLOCKNAMES.imageVideoBlock)
          LayoutBlock = ImageVideoBlock;
        if (block.blockName === BLOCKNAMES.instagramBlock)
          LayoutBlock = InstagramBlock;
        if (block.blockName === BLOCKNAMES.linkCTABlock)
          LayoutBlock = ButtonCTABlock;
        if (block.blockName === BLOCKNAMES.mapBlock) LayoutBlock = MapBlock;
        if (block.blockName === BLOCKNAMES.staffBlock) LayoutBlock = StaffBlock;
        if (block.blockName === BLOCKNAMES.textBoxBlock)
          LayoutBlock = TextBoxBlock;
        if (block.blockName === BLOCKNAMES.textImgBlock)
          LayoutBlock = TextImgBlock;
        if (block.blockName === BLOCKNAMES.titleBlock) LayoutBlock = TitleBlock;
        if (block.blockName === BLOCKNAMES.twoTextColumns)
          LayoutBlock = TwoTextColumnsBlock;
        if (block.blockName === BLOCKNAMES.videoGalleryBlock)
          LayoutBlock = VideoGalleryBlock;
        if (block.blockName === BLOCKNAMES.werkLightPreviewBlock)
          LayoutBlock = WerkLightPreviewBlock;
        if (block.blockName === BLOCKNAMES.allBlogPostsBlock)
          LayoutBlock = AllBlogPostsBlock;
        if (block.blockName === BLOCKNAMES.LatestWorkBlock)
          LayoutBlock = LatestWorkBlock;
        if (block.blockName === BLOCKNAMES.productieProcesBlock)
          LayoutBlock = ProductieProcesBlock;
        if (block.blockName === BLOCKNAMES.testimonialsBlock)
          LayoutBlock = TestimonialsBlock;
        if (block.blockName === BLOCKNAMES.contactFormBlock)
          LayoutBlock = ContactFormBlock;
        if (block.blockName === BLOCKNAMES.kostenVideosBlock)
          LayoutBlock = KostenVideosBlock;
        if (block.blockName === BLOCKNAMES.vacaturesBlock)
          LayoutBlock = VacaturesBlock;
        if (block.blockName === BLOCKNAMES.logoBlock) LayoutBlock = LogoBlock;

        return (
          <LayoutBlock
            blockContent={block.blockContent}
            index={index}
            pageTitleColor={page.pageTitleColor}
            blogPosts={blogPosts}
            highlighted={highlightedBlock === index}
            key={`block${index}`}
            setOverrideBackgroundColor={setOverrideBackgroundColor}
            breakPoint={breakPoint}
            toggleLightbox={toggleLightbox}
            setShowVideoLightbox={setShowVideoLightbox}
            showVideoLightbox={showVideoLightbox}
            previewMode={previewMode}
            werkPages={werkPages}
            lightPages={lightPages}
            fictiePages={fictiePages}
            pagePortfolioOptions={page.portfolioOptions}
          />
        );

        /*
        const blockIndex = BLOCKS.findIndex(
          (searchBlock) => searchBlock.blockName === block.blockName
        );
        if (blockIndex >= 0) {
          const LayoutBlock = BLOCKS[blockIndex].layoutComponent;
          return (
            <LayoutBlock
              blockContent={block.blockContent}
              index={index}
              pageTitleColor={page.pageTitleColor}
              blogPosts={blogPosts}
              highlighted={highlightedBlock === index}
              key={`block${index}`}
              setOverrideBackgroundColor={setOverrideBackgroundColor}
              breakPoint={breakPoint}
              toggleLightbox={toggleLightbox}
              setShowVideoLightbox={setShowVideoLightbox}
              showVideoLightbox={showVideoLightbox}
            />
          );
        }
        */
      })}
      {page.showFooter && !previewMode && (
        <Footer
          setIsShowingCookiePopup={setIsShowingCookiePopup}
          textColor={overrideBackgroundColor ? 2 : page.pageTextColor}
          titleColor={page.pageTitleColor}
          bgColor={page.pageBgColor}
        />
      )}
      <AnimatePresence>
        {showLightbox && (
          <ImageLightBox
            currentIndex={currentLightboxIndex}
            images={lightBoxImages}
            changeIndex={changeLightboxIndex}
            setShowLightbox={setShowLightbox}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showVideoLightbox && (
          <VideoLightbox
            video={showVideoLightbox}
            setShowLightbox={setShowVideoLightbox}
            breakPoint={breakPoint}
          />
        )}
      </AnimatePresence>
      {!showVideoLightbox && !previewMode && breakPoint !== 0 && (
        <MouseController />
      )}
    </div>
  );
}
