/** @jsx jsx */

import { useState } from "react";
import {
  FullWidthInput,
  TextArea,
  ControllerSection,
  COLORS,
} from "../../style/styledComponents";
import { cloneDeep } from "lodash-es";
import { css, jsx } from "@emotion/react";
import XOptionsRadioButtons from "../PAGEBUILDER_UTILITY_COMPONENTS/XOptionsRadioButtons";
import ColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/ColorOptions";
import BlockExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockExpandableBar";
import ImageSelector from "../PAGEBUILDER_UTILITY_COMPONENTS/ImageSelector";
import CategoryExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/CategoryExpandableBar";
import { BLOCKNAMES, BLOCKS } from "../../blocks";
import BlockColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockColorOptions";
import RichTextEditor from "../PAGEBUILDER_UTILITY_COMPONENTS/RichTextEditor";
import VerticalPaddingSliders from "../PAGEBUILDER_UTILITY_COMPONENTS/VerticalPaddingSliders";

interface Props {
  content: any;
  index: number;
  handleBlockChange: Function;
  disabledStyle: string;
}

export const productieProcesBlockInit = {
  textIntro: "",
  text0: "",
  text1: "",
  text2: "",
  text3: "",
  text4: "",
  text5: "",
  text6: "",
  text7: "",
  titleColor: 0,
  textColor: 0,
  bgColor: 0,
  vPaddingTop: 30,
  vPaddingBottom: 30,
};

export const ProductieProcesBlockControl = ({
  content,
  index,
  handleBlockChange,
  disabledStyle,
}: Props) => {
  const handleValueChange = (key: string, value: string | boolean | number) => {
    const newContent = cloneDeep(content);
    newContent[key] = value;
    handleBlockChange({ ...newContent }, index);
  };

  return (
    <>
      <CategoryExpandableBar title="TEXT" index={index}>
        <ControllerSection>
          <span>Text Intro</span>
          <RichTextEditor
            value={content.textIntro}
            propName="textIntro"
            handleValueChange={handleValueChange}
          />
        </ControllerSection>

        <ControllerSection>
          <span>Text Kennismaking</span>
          <RichTextEditor
            value={content.text0}
            propName="text0"
            handleValueChange={handleValueChange}
          />
        </ControllerSection>
        <ControllerSection>
          <span>Text Concept + Offerte</span>
          <RichTextEditor
            value={content.text1}
            propName="text1"
            handleValueChange={handleValueChange}
          />
        </ControllerSection>
        <ControllerSection>
          <span>Text Script</span>
          <RichTextEditor
            value={content.text2}
            propName="text2"
            handleValueChange={handleValueChange}
          />
        </ControllerSection>
        <ControllerSection>
          <span>Text Pre-productie</span>
          <RichTextEditor
            value={content.text3}
            propName="text3"
            handleValueChange={handleValueChange}
          />
        </ControllerSection>
        <ControllerSection>
          <span>Text Opnames</span>
          <RichTextEditor
            value={content.text4}
            propName="text4"
            handleValueChange={handleValueChange}
          />
        </ControllerSection>
        <ControllerSection>
          <span>Text Nabewerking</span>
          <RichTextEditor
            value={content.text5}
            propName="text5"
            handleValueChange={handleValueChange}
          />
        </ControllerSection>
        <ControllerSection>
          <span>Text Feedback</span>
          <RichTextEditor
            value={content.text6}
            propName="text6"
            handleValueChange={handleValueChange}
          />
        </ControllerSection>
        <ControllerSection>
          <span>Text Oplevering</span>
          <RichTextEditor
            value={content.text7}
            propName="text7"
            handleValueChange={handleValueChange}
          />
        </ControllerSection>
      </CategoryExpandableBar>

      <CategoryExpandableBar title="BLOCK OPTIONS" index={index}>
        <VerticalPaddingSliders
          vPaddingBottom={content.vPaddingBottom}
          vPaddingTop={content.vPaddingTop}
          handleValueChange={handleValueChange}
        />
      </CategoryExpandableBar>
      <BlockColorOptions
        titleColor={content.titleColor}
        bgColor={content.bgColor}
        textColor={content.textColor}
        handleValueChange={handleValueChange}
      />
    </>
  );
};
export default ProductieProcesBlockControl;
