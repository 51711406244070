/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import ImageComponent from "../ImageComponent";
import HighlightBlock from "./HighlightBlock";

import {
  colors,
  getTextAlignFromNumber,
  getXPaddingAmtFromNumber,
  maxContentWidth,
  vPaddingMultiplier,
  xPaddingAmtMobile,
} from "../../styles/style";
import { fillInMissingBlockDataWithDefaults } from "../../cms/functions";
import { twoTextColumnsInit } from "../../cms/CMSComponents/CONTROLLER_COMPONENTS/TwoTextColumnsBlockControl";
import { motion } from "framer-motion";
import useScrollInViewAnimation from "../../hooks/UseScrollInViewAnimation";
import ANIMATIONS from "../../styles/animations";
import TitleColorAccents from "../TitleColorAccents";
import DecorationCircle from "../DecorationCircle";
import ColorAccentsText from "../ColorAccentsText";
import H1H3Switch from "../H1H3Switch";
import H2H4Switch from "../H2H4Switch";

type Props = {
  blockContent: string;
  index: number;
  highlighted: boolean;
  pageTitleColor: number;
  breakPoint: number;
};

function TwoTextColumnsBlock({
  blockContent,
  index,
  highlighted,
  pageTitleColor,
  breakPoint,
}: Props) {
  const DATA = fillInMissingBlockDataWithDefaults(
    JSON.parse(blockContent),
    twoTextColumnsInit
  );

  let textAlignClass1 = getTextAlignFromNumber(DATA.textAlign1);
  let textAlignClass2 = getTextAlignFromNumber(DATA.textAlign2);

  if (breakPoint === 0) {
    textAlignClass1 = DATA.textAlignMobile
      ? getTextAlignFromNumber(DATA.textAlignMobile)
      : "text-left";
    textAlignClass2 = DATA.textAlignMobile
      ? getTextAlignFromNumber(DATA.textAlignMobile)
      : "text-left";
  }

  let widthClass = "w-full";
  if (DATA.textBoxWidth === 1) widthClass = "md:w-5/6";
  if (DATA.textBoxWidth === 2) widthClass = "md:w-3/4";
  if (DATA.textBoxWidth === 3) widthClass = "md:w-1/2";

  let textSizeClass = "";
  if (DATA.textSize === 1) textSizeClass = "text-lg";
  if (DATA.textSize === 2) textSizeClass = "text-xl";

  const [refText1, controlsText1] = useScrollInViewAnimation(true);

  let xPaddingAmt = getXPaddingAmtFromNumber(1, breakPoint);

  return (
    <section
      id={"container" + index}
      className={`relative  w-screen flex transition-colors`}
      style={{
        backgroundColor: colors[DATA.bgColor],
        paddingTop: `${DATA.vPaddingTop * vPaddingMultiplier}px`,
        paddingBottom: `${DATA.vPaddingBottom * vPaddingMultiplier}px`,
      }}
    >
      <div
        ref={refText1}
        id={"textbox" + index}
        className={`z-10 md:grid md:grid-rows-3 md:grid-cols-2 grid-flow-col w-fill gap-x-24 ${widthClass} m-auto`}
        style={{
          padding: `0 ${xPaddingAmt}px`,
          maxWidth: maxContentWidth + "px",
        }}
      >
        <H1H3Switch
          isH1={DATA.isMainTitle}
          className="relative z-50 text-4xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl font-title -ml-0.75 sm:-ml-0.5 lg:-ml-1 font-normal"
        >
          <ColorAccentsText
            text={DATA.title1}
            textColor={DATA.textColor}
            accentColor={
              DATA.titleColor === 0 ? pageTitleColor : DATA.titleColor
            }
          />
          {DATA.displayCircle && (
            <DecorationCircle
              bgColor={DATA.titleColor === 0 ? pageTitleColor : DATA.titleColor}
              growthSize={7.4}
              offsetX={35}
              offsetY={35}
              offsetXMobile={17}
              growthSizeMobile={4.2}
              offsetYMobile={20}
            />
          )}
        </H1H3Switch>
        <H2H4Switch
          isH2={DATA.isMainTitle}
          className="relative z-50 text-2xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-4xl font-title sm:-ml-0.5 font-normal"
        >
          <ColorAccentsText
            text={DATA.subtitle1}
            textColor={DATA.textColor}
            accentColor={
              DATA.titleColor === 0 ? pageTitleColor : DATA.titleColor
            }
          />
        </H2H4Switch>

        <div
          className={`${textAlignClass1} ${textSizeClass} mt-8`}
          style={{
            color: colors[DATA.textColor],
          }}
        >
          <ColorAccentsText
            text={DATA.richText1}
            textColor={DATA.textColor}
            accentColor={
              DATA.titleColor === 0 ? pageTitleColor : DATA.titleColor
            }
          />
        </div>
        <h1 className="relative z-50">
          <ColorAccentsText
            text={DATA.title2}
            textColor={DATA.textColor}
            accentColor={
              DATA.titleColor === 0 ? pageTitleColor : DATA.titleColor
            }
          />
        </h1>
        <h2 className="relative z-50">
          <ColorAccentsText
            text={DATA.subtitle2}
            textColor={DATA.textColor}
            accentColor={
              DATA.titleColor === 0 ? pageTitleColor : DATA.titleColor
            }
          />
        </h2>
        <div
          className={`${textAlignClass2} ${textSizeClass} mt-8`}
          style={{
            color: colors[DATA.textColor],
          }}
        >
          <ColorAccentsText
            text={DATA.richText2}
            textColor={DATA.textColor}
            accentColor={
              DATA.titleColor === 0 ? pageTitleColor : DATA.titleColor
            }
          />
        </div>
      </div>

      {DATA.imageData && (
        <div
          className={`absolute top-0 left-0 w-full h-full z-0 overflow-hidden`}
        >
          <ImageComponent imageData={DATA.imageData} crop={true} />
        </div>
      )}

      {highlighted && <HighlightBlock />}
    </section>
  );
}

export default TwoTextColumnsBlock;
