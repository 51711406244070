import React, { useState } from "react";
import ImageComponent from "./ImageComponent";
import { Link } from "gatsby";
import {
  colors,
  getTextAlignFromNumber,
  getXPaddingAmtFromNumber,
  vPaddingMultiplier,
} from "./../styles/style";
import { imageData } from "./../cms/CMSComponents/PAGEBUILDER_UTILITY_COMPONENTS/ImageSelector";
import { motion } from "framer-motion";
import ANIMATIONS from "../styles/animations";
import useScrollInViewAnimation from "../hooks/UseScrollInViewAnimation";

type BlogProps = {
  title: string;
  previewText: string;
  linkText: string;
  slug: string;
  tags: string;
  textColor: number;
  titleColor: number;
  pageTitleColor: number;
  image: imageData;
};
const BlogPostPreview = React.memo(function BlogPostPreview({
  title,
  previewText,
  linkText,
  slug,
  tags,
  textColor,
  image,
  pageTitleColor,
  titleColor,
}: BlogProps) {
  const [hover, setHover] = useState(false);
  const [itemsRef, itemsControls] = useScrollInViewAnimation();

  return (
    <>
      {previewText && title && (
        <Link to={`/blog/${slug}`}>
          <div
            ref={itemsRef}
            className="my-6 md:flex items-center w-full xl:w-4/5 mx-auto"
          >
            <div
              animate={itemsControls}
              variants={ANIMATIONS.fade}
              initial="hidden"
              className="w-full md:w-1/2 h-72 rounded-lg overflow-hidden relative"
            >
              {image && <ImageComponent imageData={image} crop />}
            </div>
            <motion.div
              animate={itemsControls}
              variants={ANIMATIONS.fade}
              initial="hidden"
              className="w-full md:w-1/2 md:ml-8"
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <h2
                className="inline-block "
                style={{
                  color:
                    titleColor === 0
                      ? colors[pageTitleColor]
                      : colors[titleColor],
                }}
              >
                {title}
              </h2>
              <p>{previewText}</p>

              <b
                className="text-l mt-4 block transition-colors duration-300"
                style={{ color: hover ? colors[pageTitleColor] : "inherit" }}
              >
                {linkText}
              </b>
            </motion.div>
          </div>
        </Link>
      )}
    </>
  );
});

export default BlogPostPreview;
