/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import loadable from "@loadable/component";

const ReactQuill = loadable(() => import("react-quill"));
type Props = {
  propName: string;
  value: string;
  handleValueChange: Function;
};

export default function RichTextEditor({
  handleValueChange,
  propName,
  value,
}: Props) {
  return (
    <div
      css={css`
        background-color: #ffffff;
        border: 1px solid black;
      `}
    >
      {typeof window !== "undefined" && document && (
        <ReactQuill
          modules={{
            toolbar: [
              ["bold", "italic", "underline", "strike", "blockquote"],
              [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
              ],
              ["link"],
              ["clean"],
            ],
          }}
          formats={[
            "header",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
          ]}
          value={value}
          onChange={(newState: any) => {
            handleValueChange(propName, newState);
          }}
          theme="snow"
        />
      )}
    </div>
  );
}
