/** @jsx jsx */

import { useState, useRef } from "react";
import { css, jsx } from "@emotion/react";
import ImageComponent from "../ImageComponent";
import useDOMElementSize from "../../hooks/useDOMElementSize";
import HighlightBlock from "./HighlightBlock";

import playbutton from "../../images/playbutton.svg";

import {
  colors,
  getTextAlignFromNumber,
  getXPaddingAmtFromNumber,
  vPaddingMultiplier,
} from "../../styles/style";
import { fillInMissingBlockDataWithDefaults } from "../../cms/functions";
import { VideoGalleryInit } from "../../cms/CMSComponents/CONTROLLER_COMPONENTS/VideoGalleryControl";
//import VimeoVideoComponent from "../VimeoVideoComponent";
import VideoPreview from "../VideoPreview";
import loadable from "@loadable/component";
const VimeoVideoComponent = loadable(() => import("../VimeoVideoComponent"));

type Props = {
  blockContent: string;
  index: number;
  highlighted: boolean;
  pageTitleColor: number;
  breakPoint: number;
  setShowVideoLightbox: Function;
};

function VideoGalleryBlock({
  blockContent,
  index,
  highlighted,
  pageTitleColor,
  breakPoint,
  setShowVideoLightbox,
}: Props) {
  const DATA = fillInMissingBlockDataWithDefaults(
    JSON.parse(blockContent),
    VideoGalleryInit
  );

  let textAlignClass = getTextAlignFromNumber(DATA.titlePosition);

  let xPaddingAmt = getXPaddingAmtFromNumber(DATA.contentWidth, breakPoint);

  return (
    <section
      id={"container" + index}
      className={`relative  w-screen transition-colors`}
      style={{
        backgroundColor: colors[DATA.bgColor],
        padding: `${DATA.vPaddingTop * vPaddingMultiplier}px ${xPaddingAmt}px ${
          DATA.vPaddingBottom * vPaddingMultiplier
        }px`,
      }}
    >
      <h3
        className={`${textAlignClass} text-4xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl font-title -ml-0.75 sm:-ml-0.5 lg:-ml-1 font-normal`}
        style={{
          color:
            DATA.titleColor === 0
              ? colors[pageTitleColor]
              : colors[DATA.titleColor],
        }}
      >
        {DATA.title}
      </h3>
      <h4
        className={`${textAlignClass} text-2xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-4xl font-title sm:-ml-0.5 font-normal`}
        style={{
          color: colors[DATA.textColor],
        }}
      >
        {DATA.subtitle}
      </h4>
      <div
        className={` w-full sm:flex lg:flex-nowrap sm:flex-wrap justify-center`}
        style={{
          color: colors[DATA.textColor],
        }}
      >
        {DATA.items.map((item: any, itemIndex: number) => {
          return (
            <div
              key={`video${index}-${itemIndex}`}
              className={`relative w-full  md:w-2/5 lg:w-1/4 sm:mx-4 mt-8 h-56 md:h-48 xl:h-56`}
            >
              <VideoPreview
                imageData={item.thumbnail}
                videoData={item.video}
                setShowVideoLightbox={setShowVideoLightbox}
                idStr={`video${index}-${itemIndex}`}
              />
            </div>
          );
        })}
      </div>

      {DATA.imageData && (
        <div
          className={`absolute top-0 left-0 w-full h-full  overflow-hidden`}
          style={{ zIndex: -10 }}
        >
          <ImageComponent imageData={DATA.imageData} crop={true} />
        </div>
      )}

      {highlighted && <HighlightBlock />}
    </section>
  );
}

type VideoGalleryItemProps = {
  item: any;
  setShowVideoLightbox: Function;
  idStr: string;
};
/*
function VideoGalleryItem({
  item,
  setShowVideoLightbox,
  idStr,
}: VideoGalleryItemProps) {
  const [hover, setHover] = useState(false);
  const containerSize = useDOMElementSize("#" + idStr, []);
  return (
    <div
      id={idStr}
      className={`relative w-full  md:w-1/2 lg:w-1/4 px-4 mt-8`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <ImageComponent imageData={item.thumbnail} crop />

      {hover && (
        <div className="absolute inset-0 transition-opacity overflow-hidden rounded-lg fadeIn pointer-events-none">
          <VimeoVideoComponent
            videoData={item.video}
            containerSize={containerSize}
          />
        </div>
      )}
      <div
        className="absolute inset-0 cursor-pointer hoverScaleXXL"
        onClick={() => setShowVideoLightbox({ ...item.video })}
      >
        <img
          className="w-16 h-16 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
          src={playbutton}
          alt="playbutton"
        />
      </div>
    </div>
  );
}
*/

export default VideoGalleryBlock;
