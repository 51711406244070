/** @jsx jsx */

import React, { useEffect, useState, useRef } from "react";
import { css, jsx } from "@emotion/react";
import ImageComponent from "../ImageComponent";
import HighlightBlock from "./HighlightBlock";
import userInstagram from "user-instagram";

import {
  colors,
  getXPaddingAmtFromNumber,
  vPaddingMultiplier,
} from "../../styles/style";
import { fillInMissingBlockDataWithDefaults } from "../../cms/functions";
import { instagramBlockInit } from "../../cms/CMSComponents/CONTROLLER_COMPONENTS/InstagramBlockControl";
import { imageData } from "../../cms/CMSComponents/PAGEBUILDER_UTILITY_COMPONENTS/ImageSelector";
import TitleColorAccents from "../TitleColorAccents";
import ColorAccentsText from "../ColorAccentsText";

type Props = {
  blockContent: string;
  index: number;
  highlighted: boolean;
  pageTitleColor: number;
  breakPoint: number;
};

function InstagramBlock({
  blockContent,
  index,
  highlighted,
  pageTitleColor,
  breakPoint,
}: Props) {
  const [instagramData, setInstagramData] = useState();

  const DATA = fillInMissingBlockDataWithDefaults(
    JSON.parse(blockContent),
    instagramBlockInit
  );

  /*
  async function getInstagramData(userName: string) {
  
    const url = `https://www.instagram.com/${userName}/channel/?__a=1`;
    console.log(url);
    try {
      const response = await fetch(url, {
        headers: { "Access-Control-Allow-Origin": "*" },
      });
      console.log(response);
      const data = await response.json();
      return data;
    } catch (e) {
      console.log(e);
    }
    
    try {
      await userInstagram("blikfilm");
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getInstagramData(DATA.instagramUsername).then((data) => console.log(data));
  }, []);
*/

  let xPaddingAmt = getXPaddingAmtFromNumber(DATA.contentWidth, breakPoint);

  return (
    <section
      id={"container" + index}
      className={`relative  w-screen transition-colors`}
      style={{
        backgroundColor: colors[DATA.bgColor],
        padding: `${DATA.vPaddingTop * vPaddingMultiplier}px ${xPaddingAmt}px ${
          DATA.vPaddingBottom * vPaddingMultiplier
        }px`,
      }}
    >
      <div className="flex flex-col items-center">
        <h3 className="relative z-50 text-4xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl font-title -ml-0.75 sm:-ml-0.5 lg:-ml-1 font-normal">
          <ColorAccentsText
            text={DATA.title}
            textColor={DATA.textColor}
            accentColor={
              DATA.titleColor === 0 ? pageTitleColor : DATA.titleColor
            }
          />
        </h3>
        <h4 className="relative z-50 text-2xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-4xl font-title sm:-ml-0.5 font-normal">
          <ColorAccentsText
            text={DATA.subtitle}
            textColor={DATA.textColor}
            accentColor={
              DATA.titleColor === 0 ? pageTitleColor : DATA.titleColor
            }
          />
        </h4>
      </div>
      <div
        className={` w-full flex flex-col items-center sm:flex-row sm:flex-wrap justify-center mx-auto mt-4`}
        style={{
          color: colors[DATA.textColor],
          maxWidth: "1100px",
        }}
      >
        {DATA.items.map((item: any, itemIndex: number) => {
          return (
            <ImageGalleryItem
              item={item}
              itemIndex={itemIndex}
              images={DATA.items}
              key={`staff${index}-${itemIndex}`}
              instagramLink={DATA.instagramUsername}
            />
          );
        })}
      </div>

      {DATA.imageData && (
        <div
          className={`absolute top-0 left-0 w-full h-full z-0 overflow-hidden pointer-events-none`}
        >
          <ImageComponent imageData={DATA.imageData} crop={true} />
        </div>
      )}

      {highlighted && <HighlightBlock />}
    </section>
  );
}

type ImageGalleryItemProps = {
  item: any;
  itemIndex: number;
  instagramLink: string;
  images: imageData[];
};

function ImageGalleryItem({
  item,
  itemIndex,
  images,
  instagramLink,
}: ImageGalleryItemProps) {
  return (
    <a href={"//" + instagramLink} target="_blank" className="w-80 h-80 m-3">
      <ImageComponent imageData={item.image} crop={true} />
    </a>
  );
}

export default InstagramBlock;
