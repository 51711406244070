/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import ImageComponent from "../ImageComponent";
import HighlightBlock from "./HighlightBlock";
import { useState } from "react";
import {
  ButtonCTA,
  colors,
  vPaddingMultiplier,
  xPaddingAmtMobile,
} from "../../styles/style";
import { fillInMissingBlockDataWithDefaults } from "../../cms/functions";
import Expand from "react-expand-animated";
import useScrollInViewAnimation from "../../hooks/UseScrollInViewAnimation";
import { vacaturesBlockInit } from "../../cms/CMSComponents/CONTROLLER_COMPONENTS/VacaturesBlockControl";

type Props = {
  blockContent: string;
  index: number;
  highlighted: boolean;
  pageTitleColor: number;
};

function VacaturesBlock({
  blockContent,
  index,
  highlighted,
  pageTitleColor,
}: Props) {
  const [selectedVacature, setSelectedVacature] = useState(0);
  const [exp, setExp] = useState(false);
  const transitions = ["height", "opacity", "background"];

  const DATA = fillInMissingBlockDataWithDefaults(
    JSON.parse(blockContent),
    vacaturesBlockInit
  );

  let widthClass = "w-full";
  if (DATA.textBoxWidth === 1) widthClass = "md:w-5/6";
  if (DATA.textBoxWidth === 2) widthClass = "md:w-3/4";
  if (DATA.textBoxWidth === 3) widthClass = "md:w-1/2";

  return (
    <section
      id={"container" + index}
      className={`relative  w-screen  transition-colors`}
      style={{
        backgroundColor: colors[DATA.bgColor],
        paddingTop: `${DATA.vPaddingTop * vPaddingMultiplier}px`,
        paddingBottom: `${DATA.vPaddingBottom * vPaddingMultiplier}px`,
      }}
    >
      <div
        className="mx-auto max-w-4xl flex flex-col items-start"
        style={{ padding: `0 ${xPaddingAmtMobile}px` }}
      >
        <h3
          className={`text-center mx-auto text-4xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl font-title font-normal`}
          style={{
            color: colors[DATA.titleColor],
          }}
        >
          {DATA.title}
        </h3>
        <h4
          className={`text-center mx-auto text-xl sm:text-2xl md:text-2xl lg:text-3xl xl:text-3xl font-title font-normal mt-4`}
          style={{
            color: colors[DATA.textColor],
          }}
        >
          {DATA.vacatures?.[selectedVacature]?.subtitle}
        </h4>
        <div
          style={{
            color: colors[DATA.textColor],
          }}
          className="text-left italic mt-10"
          dangerouslySetInnerHTML={{
            __html: DATA.vacatures?.[selectedVacature]?.textShort,
          }}
        ></div>
        <Expand open={exp} duration={200} transitions={transitions}>
          <div
            style={{
              color: colors[DATA.textColor],
            }}
            className="text-left italic mt-10"
            dangerouslySetInnerHTML={{
              __html: DATA.vacatures?.[selectedVacature]?.textLong,
            }}
          ></div>
        </Expand>

        <button
          style={{ opacity: exp ? 0 : 1, color: colors[DATA.textColor] }}
          className="underline mt-6"
          onClick={() => setExp(true)}
        >
          Lees meer...
        </button>
        {DATA.vacatures?.length > 1 && (
          <div className="flex mt-8">
            {DATA.vacatures.map((vacature: any, index: number) => (
              <div
                key={index}
                className="w-8 h-8 flex justify-center items-center"
                role="button"
                onClick={() => setSelectedVacature(index)}
              >
                <div
                  className="w-3 h-3 block rounded-full"
                  style={{
                    backgroundColor:
                      index === selectedVacature
                        ? colors[DATA.titleColor]
                        : colors[DATA.textColor],
                  }}
                ></div>
              </div>
            ))}
          </div>
        )}
      </div>
      {/* <motion.div
        ref={titleRef}
        animate={titleControls}
        variants={ANIMATIONS.fadeScale}
        initial="hidden"
      >
        <h1
          className={`text-center m-auto`}
          style={{
            color:
              DATA.titleColor === 0
                ? colors[pageTitleColor]
                : colors[DATA.titleColor],
          }}
        >
          {DATA.title}
        </h1>
        <h2
          className={`text-center m-auto`}
          style={{
            color: colors[DATA.textColor],
          }}
        >
          {DATA.subtitle}
        </h2>
      </motion.div>
      <div
        ref={itemsRef}
        style={{
          color: colors[DATA.textColor],
        }}
      >
        <div className="flex sm:mt-8 mt-4">
          <motion.div
            initial="hidden"
            variants={ANIMATIONS.staggerContainerFast}
            animate={itemsControls}
            className="w-1/2 px-2 text-xs sm:text-base"
          >
            {DATA.textLines.map((line: string[], index: number) => {
              if (index < lineAmt) {
                return (
                  <motion.span
                    variants={ANIMATIONS.fade}
                    className="text-right block h-6 whitespace-nowrap"
                    key={"credits" + index}
                  >
                    {line[0]}
                  </motion.span>
                );
              } else return "";
            })}
            <Expand open={exp} duration={200} transitions={transitions}>
              {DATA.textLines.map((line: string[], index: number) => {
                if (index >= lineAmt) {
                  return (
                    <motion.span
                      variants={ANIMATIONS.fade}
                      className="text-right block h-6 whitespace-nowrap"
                      key={"credits" + index}
                    >
                      {line[0]}
                    </motion.span>
                  );
                } else return "";
              })}
            </Expand>
          </motion.div>
          <motion.div
            initial="hidden"
            variants={ANIMATIONS.staggerContainerFast}
            animate={itemsControls}
            className="w-1/2 px-2  text-xs sm:text-base"
          >
            {DATA.textLines.map((line: string[], index: number) => {
              if (index < lineAmt) {
                return (
                  <motion.span
                    variants={ANIMATIONS.fade}
                    className="  block h-6 whitespace-nowrap"
                    key={"credits" + index}
                  >
                    {line[1]}
                  </motion.span>
                );
              } else return "";
            })}
            <Expand open={exp} duration={200} transitions={transitions}>
              {DATA.textLines.map((line: string[], index: number) => {
                if (index >= lineAmt) {
                  return (
                    <motion.span
                      variants={ANIMATIONS.fade}
                      className="block h-6 whitespace-nowrap"
                      key={"credits" + index}
                    >
                      {line[1]}
                    </motion.span>
                  );
                } else return "";
              })}
            </Expand>
          </motion.div>
        </div>
      </div>
      {DATA.textLines.length > lineAmt && !exp && (
        <div className="flex justify-center mt-4">
          <ButtonCTA
            text="..."
            size={1}
            color1={DATA.bgColor}
            color2={DATA.textColor}
            selected={hover}
            onClick={() => setExp(true)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          />
        </div>
      )} */}

      {DATA.imageData && (
        <div
          className={`absolute top-0 left-0 w-full h-full z-0 overflow-hidden`}
          style={{ pointerEvents: "none" }}
        >
          <ImageComponent imageData={DATA.imageData} crop={true} />
        </div>
      )}

      {highlighted && <HighlightBlock />}
    </section>
  );
}

export default VacaturesBlock;
