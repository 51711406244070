/** @jsx jsx */

import React, { useState } from "react";
import { css, jsx } from "@emotion/react";
import ImageComponent from "../ImageComponent";
import HighlightBlock from "./HighlightBlock";

import {
  ButtonCTA,
  colors,
  vPaddingMultiplier,
  xPaddingAmtMobile,
} from "../../styles/style";
import { fillInMissingBlockDataWithDefaults } from "../../cms/functions";
import { Link } from "gatsby";
import { buttonCTABlockInit } from "../../cms/CMSComponents/CONTROLLER_COMPONENTS/ButtonCTABlockControl";

type Props = {
  blockContent: string;
  index: number;
  highlighted: boolean;
  pageTitleColor: number;
};

function ButtonCTABlock({
  blockContent,
  index,
  highlighted,
  pageTitleColor,
}: Props) {
  const DATA = fillInMissingBlockDataWithDefaults(
    JSON.parse(blockContent),
    buttonCTABlockInit
  );

  const [selected, setSelected] = useState(false);

  let textSizeClass = "";
  if (DATA.textSize === 1) textSizeClass = "text-lg";
  if (DATA.textSize === 2) textSizeClass = "text-xl";

  return (
    <section
      id={"container" + index}
      className={`relative  w-screen flex justify-center`}
      style={{
        backgroundColor: colors[DATA.bgColor],
        padding: `${
          DATA.vPaddingTop * vPaddingMultiplier
        }px ${xPaddingAmtMobile}px ${
          DATA.vPaddingBottom * vPaddingMultiplier
        }px`,
      }}
    >
      <div
        id={"textbox" + index}
        className={`z-10 inline-block ${textSizeClass}`}
        style={{
          padding: `0 `,
        }}
        onMouseEnter={() => setSelected(true)}
        onMouseLeave={() => setSelected(false)}
      >
        {DATA.linkType === 0 ? (
          <Link to={"/" + DATA.url}>
            <ButtonCTA
              selected={selected}
              color1={DATA.textColor}
              color2={DATA.titleColor}
              text={DATA.text}
              size={DATA.buttonSize}
            />
          </Link>
        ) : (
          <a href={"//" + DATA.url} target="_blank">
            <ButtonCTA
              selected={selected}
              color1={DATA.textColor}
              color2={DATA.titleColor}
              text={DATA.text}
              size={DATA.buttonSize}
            />
          </a>
        )}
      </div>

      {DATA.imageData && (
        <div
          className={`absolute top-0 left-0 w-full h-full z-0 overflow-hidden pointer-events-none`}
        >
          <ImageComponent imageData={DATA.imageData} crop={true} />
        </div>
      )}

      {highlighted && <HighlightBlock />}
    </section>
  );
}

export default ButtonCTABlock;
