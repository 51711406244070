import React from "react";
import { ControllerSection, Input } from "../../style/styledComponents";

type Props = {
  vPaddingTop: number;
  vPaddingBottom: number;
  handleValueChange: Function;
};

export default function VerticalPaddingSliders({
  vPaddingTop,
  vPaddingBottom,
  handleValueChange,
}: Props) {
  return (
    <>
      <ControllerSection>
        <label>Padding Top: {vPaddingTop}%</label>
        <Input
          type="range"
          min={0}
          max={100}
          step={1}
          value={vPaddingTop}
          onChange={(e) =>
            handleValueChange("vPaddingTop", parseInt(e.target.value, 10))
          }
        ></Input>
      </ControllerSection>
      <ControllerSection>
        <label>Padding Bottom: {vPaddingBottom}%</label>
        <Input
          type="range"
          min={0}
          max={100}
          step={1}
          value={vPaddingBottom}
          onChange={(e) =>
            handleValueChange("vPaddingBottom", parseInt(e.target.value, 10))
          }
        ></Input>
      </ControllerSection>
    </>
  );
}
