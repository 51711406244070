import React, { useState, useRef, useEffect } from "react";
import { imageData } from "../cms/CMSComponents/PAGEBUILDER_UTILITY_COMPONENTS/ImageSelector";
import { videoData } from "../cms/CMSComponents/PAGEBUILDER_UTILITY_COMPONENTS/VideoSelector";
import ImageComponent from "./ImageComponent";
//import VimeoVideoComponent from "./VimeoVideoComponent";
import playbutton from "../images/playbutton.svg";
import loadable from "@loadable/component";

const VimeoVideoComponent = loadable(() => import("./VimeoVideoComponent"));

type Props = {
  imageData: imageData;
  videoData: videoData;
  setShowVideoLightbox: Function;
  idStr: string;
  dontCropVideo?: boolean;
  dontPreviewVideo?: boolean;
};

export default function VideoPreview({
  imageData,
  videoData,
  setShowVideoLightbox,
  idStr,
  dontCropVideo,
  dontPreviewVideo,
}: Props) {
  const sizeRef = useRef();
  const [hover, setHover] = useState(false);

  return (
    <div
      className={`relative w-full h-full flex items-center`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      id={idStr}
      ref={sizeRef}
    >
      <ImageComponent imageData={imageData} crop={imageData.crop} />
      {videoData.videoID != 0 && !dontPreviewVideo && (
        <div
          className="absolute w-full h-full inset-0 transition-opacity overflow-hidden pointer-events-none"
          style={{ opacity: hover ? 1 : 0 }}
        >
          <VimeoVideoComponent
            videoData={videoData}
            containerSize={{
              x: sizeRef.current?.offsetWidth,
              y: sizeRef.current?.offsetHeight,
            }}
            playing={hover}
            dontCropVideo={dontCropVideo}
          />
        </div>
      )}
      {videoData.videoID && (
        <div
          className="absolute inset-0 w-full h-full  hoverScaleXXL"
          onClick={() => setShowVideoLightbox({ ...videoData })}
        >
          <img
            className="w-16 h-16 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
            src={playbutton}
            alt="playbutton"
          />
        </div>
      )}
    </div>
  );
}
