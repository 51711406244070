import React, { useState, useRef } from "react";
import ImageComponent from "./ImageComponent";

import {
  colors,
  getRowHeightFromNumber,
  maxContentWidth,
  portfolioGapSize,
  portfolioRowHeight,
} from "../styles/style";
import { Link } from "gatsby";
import { imageData } from "../cms/CMSComponents/PAGEBUILDER_UTILITY_COMPONENTS/ImageSelector";
import { videoData } from "../cms/CMSComponents/PAGEBUILDER_UTILITY_COMPONENTS/VideoSelector";
import {
  PortfolioRowNames,
  portfolioPreviewItem,
} from "../cms/CMSComponents/PAGEBUILDER_UTILITY_COMPONENTS/PortfolioPreviewBuilder";
//import VimeoVideoComponent from "./VimeoVideoComponent";
import { motion } from "framer-motion";
import useScrollInViewAnimation from "../hooks/UseScrollInViewAnimation";
import ANIMATIONS from "../styles/animations";
import VideoPreview from "./VideoPreview";
import useCmsProofBreakpoints from "../hooks/useCmsProofBreakpoints";

import loadable from "@loadable/component";
import { preview } from "@cloudinary/base/actions/videoEdit";

const VimeoVideoComponent = loadable(() => import("./VimeoVideoComponent"));

type PortfolioRowProps = {
  items: portfolioPreviewItem[];
  switchOrder: boolean;
  rowIndex: number;
  textColor: number;
  rowHeight: number;
  delay: number;
  onlyImages?: boolean;
  setShowVideoLightbox: Function;
  blockIndex: number;
  bgColor: number;
};

type PortfolioProps = {
  title: string;
  subtitle: string;
  previewImage: imageData;
  previewVideo: videoData;
  slug: string;
  textColor: number;
  index: number;
  rowIndex: number;
  onlyImages?: boolean;
  setShowVideoLightbox: Function;
  blockIndex: number;
  bgColor: number;
};

type PortfolioPreviewProps = {
  rows: PortfolioRowProps[];
  breakPoint: number;
  onlyImages?: boolean;
  setShowVideoLightbox: Function;
  blockIndex: number;
  previewMode?: boolean;
  bgColor: number;
};

const delayFactor = 0.15;
const maxDelay = 0.3;

const PortfolioPreview = React.memo(function PortfolioPreview({
  rows,
  breakPoint,
  onlyImages,
  setShowVideoLightbox,
  blockIndex,
  previewMode,
  bgColor,
}: PortfolioPreviewProps) {
  let gatsbyBreakpoints = useCmsProofBreakpoints();

  return (
    <>
      {rows.map((row: any, index: number) => {
        if (!gatsbyBreakpoints.sm) {
          if (
            row.rowName === PortfolioRowNames.threeSameSize ||
            row.rowName === PortfolioRowNames.twoSameSize ||
            row.rowName === PortfolioRowNames.oneLarge
          )
            return (
              <AllSameSize
                key={`${index}`}
                rowIndex={index}
                items={row.children}
                switchOrder={row.switchOrder}
                textColor={2}
                rowHeight={row.rowHeight}
                delay={Math.min(index * delayFactor, maxDelay)}
                onlyImages={onlyImages}
                setShowVideoLightbox={setShowVideoLightbox}
                blockIndex={blockIndex}
                bgColor={bgColor}
              />
            );
          if (row.rowName === PortfolioRowNames.oneLargeOneSmall)
            return (
              <OneLargeOneSmall
                key={`${index}`}
                rowIndex={index}
                items={row.children}
                switchOrder={row.switchOrder}
                textColor={2}
                rowHeight={row.rowHeight}
                delay={Math.min(index * delayFactor, maxDelay)}
                onlyImages={onlyImages}
                setShowVideoLightbox={setShowVideoLightbox}
                blockIndex={blockIndex}
                bgColor={bgColor}
              />
            );
          if (row.rowName === PortfolioRowNames.oneXLTwoSmall)
            return (
              <OneXLTwoSmall
                key={`${index}`}
                rowIndex={index}
                items={row.children}
                switchOrder={row.switchOrder}
                textColor={2}
                rowHeight={row.rowHeight}
                delay={Math.min(index * delayFactor, maxDelay)}
                onlyImages={onlyImages}
                setShowVideoLightbox={setShowVideoLightbox}
                blockIndex={blockIndex}
                bgColor={bgColor}
              />
            );
        } else {
          return row.children.map(
            (item: portfolioPreviewItem, itemIndex: number) => (
              <div
                key={`rowItem${itemIndex}-${index}`}
                style={{
                  margin: `${portfolioGapSize}px 0px`,
                  height: `${portfolioRowHeight}px`,
                }}
                className="w-full h-full overflow-hidden"
              >
                <PortfolioPreviewItem
                  title={item.previewTitle}
                  subtitle={item.previewSubtitle}
                  previewImage={item.previewImage}
                  previewVideo={item.previewVideo}
                  slug={item.link}
                  textColor={2}
                  index={itemIndex}
                  rowIndex={index}
                  onlyImages={onlyImages}
                  setShowVideoLightbox={setShowVideoLightbox}
                  blockIndex={blockIndex}
                  bgColor={bgColor}
                />
              </div>
            )
          );
        }
      })}
    </>
  );
});

export function PortfolioPreviewItem({
  title,
  subtitle,
  previewImage,
  previewVideo,
  slug,
  textColor,
  index,
  rowIndex,
  onlyImages,
  setShowVideoLightbox,
  blockIndex,
  bgColor,
}: PortfolioProps) {
  const [hover, setHover] = useState(false);
  const [ref, controls] = useScrollInViewAnimation(true);
  const sizeRef = useRef(null);
  const gatsbyBreakpoints = useCmsProofBreakpoints();
  let link = "/";

  if (slug) link = slug;
  return (
    <div
      ref={sizeRef}
      id={"portfolioItem" + index + "-" + rowIndex + "-" + blockIndex}
      className={`relative text-white  rounded-lg overflow-hidden w-full h-full ${
        !onlyImages ? "" : ""
      }`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {onlyImages ? (
        <VideoPreview
          imageData={previewImage}
          videoData={previewVideo}
          idStr={"portfolioItem" + index + "-" + rowIndex}
          setShowVideoLightbox={setShowVideoLightbox}
        />
      ) : (
        <Link
          to={link}
          style={{
            position: "absolute",
            inset: 0,
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            overflow: "hidden",
          }}
        >
          {!gatsbyBreakpoints.sm && (
            <>
              <div
                className="absolute inset-0 w-full h-full transition-opacity z-20 rounded-lg overflow-hidden pointer-events-none duration-500"
                style={{
                  opacity: hover ? 0 : 1,
                }}
              >
                <ImageComponent imageData={previewImage} crop />
              </div>
              <div
                className="absolute inset-0 w-full h-full z-10  overflow-hidden rounded-lg  pointer-events-none"
                style={{
                  display: hover ? "" : "none",
                }}
              >
                <VimeoVideoComponent
                  videoData={{
                    ...previewVideo,
                    vFocus: previewImage.vFocus,
                    hFocus: previewImage.hFocus,
                  }}
                  playing={hover}
                  containerSize={{
                    x: sizeRef.current?.offsetWidth,
                    y: sizeRef.current?.offsetHeight,
                  }}
                />
              </div>
            </>
          )}
          <div className="absolute inset-0 w-full h-full  z-0 rounded-lg overflow-hidden pointer-events-none">
            <ImageComponent imageData={previewImage} crop />
          </div>

          <div
            className="absolute inset-0 top-0 left-0 w-full h-ful z-30"
            style={{
              background:
                "linear-gradient(180deg, rgba(0,0,0,0) 44%, rgba(0,0,0,0.45) 85%)",
            }}
          ></div>
          <motion.div
            ref={ref}
            animate={controls}
            variants={ANIMATIONS.fadeDelay}
            initial="hidden"
            className="absolute left-3 bottom-3 z-40"
          >
            <h2 className="uppercase text-2xl">{title}</h2>
            <p className="uppercase">{subtitle}</p>
          </motion.div>
        </Link>
      )}
    </div>
  );
}

function AllSameSize({
  items,
  switchOrder,
  rowIndex,
  textColor,
  rowHeight,
  delay,
  onlyImages,
  setShowVideoLightbox,
  blockIndex,
  bgColor,
}: PortfolioRowProps) {
  let directionClass = "";
  if (switchOrder) directionClass = "md:flex-row-reverse";
  const height = getRowHeightFromNumber(rowHeight);
  const [ref, controls] = useScrollInViewAnimation();
  return (
    <motion.div
      ref={ref}
      animate={controls}
      variants={ANIMATIONS.staggerContainer}
      initial="hidden"
      style={{
        height: `${height}px`,
        margin: `${portfolioGapSize}px 0`,
        maxWidth: maxContentWidth + "px",
      }}
      className={`w-full md:flex ${directionClass}`}
    >
      {items.map((item, index) => (
        <div
          key={`rowItem${index}-${rowIndex}`}
          style={{ margin: `0 ${portfolioGapSize}px` }}
          className="w-full h-full"
        >
          <PortfolioPreviewItem
            title={item.previewTitle}
            subtitle={item.previewSubtitle}
            previewImage={item.previewImage}
            previewVideo={item.previewVideo}
            slug={item.link}
            textColor={textColor}
            index={index}
            rowIndex={rowIndex}
            onlyImages={onlyImages}
            setShowVideoLightbox={setShowVideoLightbox}
            blockIndex={blockIndex}
          />
        </div>
      ))}
    </motion.div>
  );
}

function OneLargeOneSmall({
  items,
  switchOrder,
  rowIndex,
  textColor,
  rowHeight,
  delay,
  onlyImages,
  setShowVideoLightbox,
  blockIndex,
  bgColor,
}: PortfolioRowProps) {
  let directionClass = "";
  if (switchOrder) directionClass = "md:flex-row-reverse";
  const height = getRowHeightFromNumber(rowHeight);
  const [ref, controls] = useScrollInViewAnimation();
  return (
    <motion.div
      ref={ref}
      animate={controls}
      variants={ANIMATIONS.staggerContainer}
      initial="hidden"
      style={{
        height: `${height}px`,
        margin: `${portfolioGapSize}px 0`,
        maxWidth: maxContentWidth + "px",
      }}
      className={`w-full md:flex ${directionClass}`}
    >
      <div
        style={{ margin: `0 ${portfolioGapSize}px` }}
        className={`md:w-2/3 w-full h-full `}
      >
        <PortfolioPreviewItem
          title={items[0].previewTitle}
          subtitle={items[0].previewSubtitle}
          previewImage={items[0].previewImage}
          previewVideo={items[0].previewVideo}
          slug={items[0].link}
          textColor={textColor}
          index={0}
          rowIndex={rowIndex}
          onlyImages={onlyImages}
          setShowVideoLightbox={setShowVideoLightbox}
          blockIndex={blockIndex}
        />
      </div>
      <div
        style={{ margin: `0 ${portfolioGapSize}px` }}
        className="md:w-1/3 w-full h-full"
      >
        <PortfolioPreviewItem
          title={items[1].previewTitle}
          subtitle={items[1].previewSubtitle}
          previewImage={items[1].previewImage}
          previewVideo={items[1].previewVideo}
          slug={items[1].link}
          textColor={textColor}
          index={1}
          rowIndex={rowIndex}
          onlyImages={onlyImages}
          setShowVideoLightbox={setShowVideoLightbox}
          blockIndex={blockIndex}
        />
      </div>
    </motion.div>
  );
}

function OneXLTwoSmall({
  items,
  switchOrder,
  rowIndex,
  textColor,
  rowHeight,
  delay,
  onlyImages,
  setShowVideoLightbox,
  blockIndex,
  bgColor,
}: PortfolioRowProps) {
  let directionClass = "";
  if (switchOrder) directionClass = "md:flex-row-reverse";
  const height = getRowHeightFromNumber(rowHeight);
  const [ref, controls] = useScrollInViewAnimation();
  return (
    <motion.div
      ref={ref}
      animate={controls}
      variants={ANIMATIONS.staggerContainer}
      initial="hidden"
      style={{
        height: `${2 * height + 2 * portfolioGapSize}px`,
        margin: `${portfolioGapSize}px 0`,
        maxWidth: maxContentWidth + "px",
      }}
      className={`w-full md:flex ${directionClass} mx-auto`}
    >
      <div
        style={{ margin: `0 ${portfolioGapSize}px` }}
        className={`md:w-3/5 w-full h-full `}
      >
        <PortfolioPreviewItem
          title={items[0].previewTitle}
          subtitle={items[0].previewSubtitle}
          previewImage={items[0].previewImage}
          previewVideo={items[0].previewVideo}
          slug={items[0].link}
          textColor={textColor}
          index={0}
          rowIndex={rowIndex}
          onlyImages={onlyImages}
          setShowVideoLightbox={setShowVideoLightbox}
          blockIndex={blockIndex}
        />
      </div>
      <div
        style={{ margin: `0 ${portfolioGapSize}px` }}
        className="md:w-2/5 w-full h-full md:flex md:flex-col justify-between"
      >
        <div className="w-full" style={{ height: height + "px" }}>
          <PortfolioPreviewItem
            title={items[1].previewTitle}
            subtitle={items[1].previewSubtitle}
            previewImage={items[1].previewImage}
            previewVideo={items[1].previewVideo}
            slug={items[1].link}
            textColor={textColor}
            index={1}
            rowIndex={rowIndex}
            onlyImages={onlyImages}
            setShowVideoLightbox={setShowVideoLightbox}
            blockIndex={blockIndex}
          />
        </div>
        <div className="w-full" style={{ height: height + "px" }}>
          <PortfolioPreviewItem
            title={items[2].previewTitle}
            subtitle={items[2].previewSubtitle}
            previewImage={items[2].previewImage}
            previewVideo={items[2].previewVideo}
            slug={items[2].link}
            textColor={textColor}
            index={2}
            rowIndex={rowIndex}
            onlyImages={onlyImages}
            setShowVideoLightbox={setShowVideoLightbox}
            blockIndex={blockIndex}
          />
        </div>
      </div>
    </motion.div>
  );
}

export default PortfolioPreview;
