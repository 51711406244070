/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import ImageComponent from "../ImageComponent";
import HighlightBlock from "./HighlightBlock";

import {
  colors,
  getTextAlignFromNumber,
  getXPaddingAmtFromNumber,
  maxContentWidth,
  vPaddingMultiplier,
} from "../../styles/style";
import { fillInMissingBlockDataWithDefaults } from "../../cms/functions";

import { imageData } from "../../cms/CMSComponents/PAGEBUILDER_UTILITY_COMPONENTS/ImageSelector";
import { ImageGalleryInit } from "../../cms/CMSComponents/CONTROLLER_COMPONENTS/ImageGalleryControl";
import VideoPreview from "../VideoPreview";
import { OutlineMode } from "@cloudinary/base/qualifiers/outlineMode";

type Props = {
  blockContent: string;
  index: number;
  highlighted: boolean;
  pageTitleColor: number;
  breakPoint: number;
  toggleLightbox: Function;
  setShowVideoLightbox: Function;
};

function ImageGalleryBlock({
  blockContent,
  index,
  highlighted,
  pageTitleColor,
  breakPoint,
  toggleLightbox,
  setShowVideoLightbox,
}: Props) {
  const DATA = fillInMissingBlockDataWithDefaults(
    JSON.parse(blockContent),
    ImageGalleryInit
  );

  let textAlignClass = getTextAlignFromNumber(DATA.titlePosition);

  let xPaddingAmt = getXPaddingAmtFromNumber(DATA.contentWidth, breakPoint);

  const filteredImages = [...DATA.items];
  filteredImages.filter((item: any) => !item.video?.videoID);

  return (
    <section
      id={"container" + index}
      className={`relative  w-screen transition-colors`}
      style={{
        backgroundColor: colors[DATA.bgColor],
        padding: `${DATA.vPaddingTop * vPaddingMultiplier}px ${xPaddingAmt}px ${
          DATA.vPaddingBottom * vPaddingMultiplier
        }px`,
      }}
    >
      <div style={{ maxWidth: maxContentWidth + "px" }} className="mx-auto">
        <h3
          className={`${textAlignClass} text-4xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl font-title -ml-0.75 sm:-ml-0.5 lg:-ml-1 font-normal`}
          style={{
            color:
              DATA.titleColor === 0
                ? colors[pageTitleColor]
                : colors[DATA.titleColor],
          }}
        >
          {DATA.title}
        </h3>
        <h4
          className={`${textAlignClass} text-2xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-4xl font-title sm:-ml-0.5 font-normal`}
          style={{
            color: colors[DATA.textColor],
          }}
        >
          {DATA.subtitle}
        </h4>
        <div
          className={` w-full sm:flex sm:flex-wrap justify-center relative`}
          style={{
            color: colors[DATA.textColor],
          }}
        >
          {DATA.items.map((item: any, itemIndex: number) => {
            return (
              <ImageGalleryItem
                item={item}
                index={index}
                itemIndex={itemIndex}
                images={filteredImages}
                key={`image${index}-${itemIndex}`}
                toggleLightbox={toggleLightbox}
                setShowVideoLightbox={setShowVideoLightbox}
              />
            );
          })}
        </div>

        {DATA.imageData && (
          <div
            className={`absolute top-0 left-0 w-full h-full  overflow-hidden`}
            style={{ zIndex: -10 }}
          >
            <ImageComponent imageData={DATA.imageData} crop={true} />
          </div>
        )}
      </div>
      {highlighted && <HighlightBlock />}
    </section>
  );
}

type ImageGalleryItemProps = {
  item: any;
  itemIndex: number;
  index: number;
  toggleLightbox: Function;
  setShowVideoLightbox: Function;
  images: imageData[];
};

function ImageGalleryItem({
  item,
  itemIndex,
  index,
  toggleLightbox,
  setShowVideoLightbox,
  images,
}: ImageGalleryItemProps) {
  const idStr = "Image" + index + "-" + itemIndex;
  return (
    <div
      id={idStr}
      className={` w-full lg:w-1/2 px-4 lg:px-4  mt-8 hoverScale relative`}
      onClick={() => {
        if (!item.video?.videoID) toggleLightbox(itemIndex, images);
      }}
    >
      {item.video && item.video.videoID ? (
        <VideoPreview
          imageData={item.image}
          videoData={item.video}
          setShowVideoLightbox={setShowVideoLightbox}
          idStr={idStr}
        />
      ) : (
        <ImageComponent imageData={item.image} crop={false} />
      )}
    </div>
  );
}

export default ImageGalleryBlock;
