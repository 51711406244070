/** @jsx jsx */

import { useState, useRef } from "react";
import {
  FullWidthInput,
  TextArea,
  ControllerSection,
  COLORS,
} from "../../style/styledComponents";
import { cloneDeep } from "lodash-es";
import { css, jsx } from "@emotion/react";
import XOptionsRadioButtons from "../PAGEBUILDER_UTILITY_COMPONENTS/XOptionsRadioButtons";
import ColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/ColorOptions";
import BlockExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockExpandableBar";
import ImageSelector from "../PAGEBUILDER_UTILITY_COMPONENTS/ImageSelector";
import CategoryExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/CategoryExpandableBar";
import { BLOCKNAMES, BLOCKS } from "../../blocks";
import BlockColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockColorOptions";
import RichTextEditor from "../PAGEBUILDER_UTILITY_COMPONENTS/RichTextEditor";
import VerticalPaddingSliders from "../PAGEBUILDER_UTILITY_COMPONENTS/VerticalPaddingSliders";
import { Button } from "../../style/styledComponents";
import IoIosArrowDown from "../../../images/icons/angle-down-solid";
import IoIosArrowUp from "../../../images/icons/angle-up-solid";

interface Props {
  content: any;
  index: number;
  handleBlockChange: Function;
  disabledStyle: string;
}

export const testimonialBlockInit = {
  testimonialAmt: 0,
  testimonials: [],
  titleColor: 0,
  textColor: 0,
  bgColor: 0,
  vPaddingTop: 30,
  vPaddingBottom: 30,
};

export const TestimonialBlockControl = ({
  content,
  index,
  handleBlockChange,
  disabledStyle,
}: Props) => {
  const [selectedTestimonial, setSelectedTestimonial] = useState(0);
  const selectedTestimonialRef = useRef(0);

  const handleValueChange = (key: string, value: any) => {
    const newContent = cloneDeep(content);
    newContent[key] = value;
    handleBlockChange({ ...newContent }, index);
  };

  function handleTestimonialSelect(index: number) {
    setSelectedTestimonial(index);
    selectedTestimonialRef.current = index;
  }

  const initTestimonial = {
    img: { imageID: "", crop: true, vFocus: 50, hFocus: 50 },
    title: "",
    subtitle: "",
    text: "",
  };

  type Testimonial = {
    img: any;
    title: string;
    subtitle: string;
    text: string;
  };

  function handleAddTestimonial() {
    const newTestimonials = cloneDeep(content.testimonials);
    newTestimonials.push({ ...initTestimonial });
    handleValueChange("testimonials", newTestimonials);
  }

  function handleRemoveTestimonial() {
    if (content.testimonials.length > 0) {
      const newTestimonials = cloneDeep(content.testimonials).slice(0, -1);
      if (selectedTestimonialRef.current < content.testimonials.length - 1) {
        selectedTestimonialRef.current = content.testimonials.length - 2;
        setSelectedTestimonial(content.testimonials.length - 2);
      }
      handleValueChange("testimonials", newTestimonials);
    }
  }

  function handleEditTestimonial(keyName: string, val: any) {
    const newTestimonials = cloneDeep(content.testimonials);
    newTestimonials[selectedTestimonialRef.current][keyName] = val;
    handleValueChange("testimonials", newTestimonials);
  }

  function moveForward(index: number) {
    const newArr = [...content.testimonials];
    const item = newArr.splice(index, 1);
    newArr.splice(index - 1, 0, ...item);
    handleValueChange("testimonials", newArr);
    setSelectedTestimonial(index - 1);
    selectedTestimonialRef.current = index - 1;
  }

  function moveBackward(index: number) {
    const newArr = [...content.testimonials];
    const item = newArr.splice(index, 1);
    newArr.splice(index + 1, 0, ...item);
    handleValueChange("testimonials", newArr);
    setSelectedTestimonial(index + 1);
    selectedTestimonialRef.current = index + 1;
  }

  return (
    <>
      <Button
        css={css`
          margin-top: 10px;
        `}
        bgColor={COLORS.variation3}
        onClick={handleAddTestimonial}
      >
        Add testimonial
      </Button>
      <Button bgColor={COLORS.variation3} onClick={handleRemoveTestimonial}>
        Remove testimonial
      </Button>

      {!content.testimonials || content.testimonials.length === 0 ? (
        <i
          css={css`
            margin-bottom: 20px;
            display: block;
          `}
        >
          No testimonials added yet!
        </i>
      ) : (
        <>
          <div>Select which testimonial you want to edit:</div>
          <div
            css={css`
              display: flex;
            `}
          >
            {content.testimonials.map(
              (testimonial: Testimonial, index: number) => {
                return (
                  <div
                    css={css`
                      width: 20px;
                      height: 20px;
                      line-height: 20px;
                      margin: 10px 10px 10px 0;
                      text-align: center;
                      cursor: pointer;
                      border-radius: 5px;
                      color: ${index === selectedTestimonialRef.current
                        ? COLORS.accent1
                        : COLORS.black};
                      background-color: ${index ===
                      selectedTestimonialRef.current
                        ? COLORS.black
                        : "none"};
                    `}
                    onClick={() => handleTestimonialSelect(index)}
                  >
                    {index + 1}
                  </div>
                );
              }
            )}
          </div>
        </>
      )}

      {selectedTestimonialRef.current <= content.testimonials?.length - 1 && (
        <div>
          <div
            css={css`
              display: flex;
            `}
          >
            <Button
              onClick={() => moveForward(selectedTestimonialRef.current)}
              disabled={selectedTestimonialRef.current === 0}
            >
              Move forward
            </Button>
            <Button
              css={css`
                margin-left: 10px;
              `}
              onClick={() => moveBackward(selectedTestimonialRef.current)}
              disabled={
                selectedTestimonialRef.current ===
                content.testimonials?.length - 1
              }
            >
              Move backward
            </Button>
          </div>

          <ControllerSection>
            <label htmlFor="title">Title</label>
            <FullWidthInput
              type="text"
              value={content.testimonials[selectedTestimonialRef.current].title}
              onChange={(e) => handleEditTestimonial("title", e.target.value)}
            />
          </ControllerSection>
          <ControllerSection>
            <label htmlFor="subtitle">Subtitle</label>
            <FullWidthInput
              type="text"
              value={
                content.testimonials[selectedTestimonialRef.current].subtitle
              }
              onChange={(e) =>
                handleEditTestimonial("subtitle", e.target.value)
              }
            />
          </ControllerSection>
          <ControllerSection>
            <span>Text</span>
            <RichTextEditor
              value={content.testimonials[selectedTestimonialRef.current].text}
              propName="text"
              handleValueChange={handleEditTestimonial}
            />
          </ControllerSection>
          <ControllerSection>
            <ImageSelector
              currentValue={
                content.testimonials[selectedTestimonialRef.current].img
              }
              handleValueChange={handleEditTestimonial}
              label="Image:"
              propName="img"
              croppedByDefault
            />
          </ControllerSection>
        </div>
      )}

      <CategoryExpandableBar title="BLOCK OPTIONS" index={index}>
        <VerticalPaddingSliders
          vPaddingBottom={content.vPaddingBottom}
          vPaddingTop={content.vPaddingTop}
          handleValueChange={handleValueChange}
        />
      </CategoryExpandableBar>
      <BlockColorOptions
        titleColor={content.titleColor}
        bgColor={content.bgColor}
        textColor={content.textColor}
        handleValueChange={handleValueChange}
      />
    </>
  );
};
export default TestimonialBlockControl;
