export function findPageData(data: any, pageName: string) {
  const pageRegex = new RegExp(".*content/" + pageName + "\\.md");
  const index = data.findIndex((elem: any) =>
    pageRegex.test(elem.node.fileAbsolutePath)
  );
  return data[index].node.frontmatter.pageBuilder;
}

export function findFooterData(data: any) {
  const footerRegex = new RegExp(".*content/footer\\.md");
  const index = data.findIndex((elem: any) =>
    footerRegex.test(elem.node.fileAbsolutePath)
  );
  return data[index].node.frontmatter.footerBuilder;
}

export function findSeoData(data: any) {
  const seoRegex = new RegExp(".*content/seo\\.md");
  const index = data.findIndex((elem: any) =>
    seoRegex.test(elem.node.fileAbsolutePath)
  );
  return data[index].node.frontmatter.seoBuilder;
}

export function findHeaderData(data: any) {
  const headerRegex = new RegExp(".*content/header\\.md");
  const index = data.findIndex((elem: any) =>
    headerRegex.test(elem.node.fileAbsolutePath)
  );
  return data[index].node.frontmatter.headerBuilder;
}

export function findBlogPosts(data: any) {
  const blogRegex = new RegExp(".*content/blog/.*\\.md");
  return data
    .filter((elem: any) => blogRegex.test(elem.node.fileAbsolutePath))
    .map((elem: any) => elem.node.frontmatter.pageBuilder);
}

export function findFictiePages(data: any) {
  const regex = new RegExp(".*content/fictie/.*\\.md");
  return data
    .filter((elem: any) => regex.test(elem.node.fileAbsolutePath))
    .map((elem: any) => elem.node.frontmatter.pageBuilder);
}

export function findWerkPages(data: any) {
  const regex = new RegExp(".*content/werkwerk/.*\\.md");
  return data
    .filter((elem: any) => regex.test(elem.node.fileAbsolutePath))
    .map((elem: any) => elem.node.frontmatter.pageBuilder);
}

export function findLightPages(data: any) {
  const regex = new RegExp(".*content/werklight/.*\\.md");
  return data
    .filter((elem: any) => regex.test(elem.node.fileAbsolutePath))
    .map((elem: any) => elem.node.frontmatter.pageBuilder);
}

export function sortBlogPostsByDate(posts: any[]) {
  posts.forEach((post) => {
    const day = post.blogOptions.createdAt.split("-")[2];
    const month = parseInt(post.blogOptions.createdAt.split("-")[1]) - 1;
    const year = post.blogOptions.createdAt.split("-")[0];

    post.date = new Date(year, month, day);
  });
  return posts.sort((post1, post2) => post2?.date - post1.date);
}

export function sortPortfolioByDate(posts: any[]) {
  posts.forEach((post) => {
    const day = post.portfolioOptions.createdAt.split("-")[2];
    const month = parseInt(post.portfolioOptions.createdAt.split("-")[1]) - 1;
    const year = post.portfolioOptions.createdAt.split("-")[0];

    post.date = new Date(year, month, day);
  });
  return posts.sort((post1, post2) => post2?.date - post1.date);
}
