/** @jsx jsx */

import { useState } from "react";
import {
  FullWidthInput,
  TextArea,
  ControllerSection,
  COLORS,
} from "../../style/styledComponents";
import { cloneDeep } from "lodash-es";
import { css, jsx } from "@emotion/react";
import XOptionsRadioButtons from "../PAGEBUILDER_UTILITY_COMPONENTS/XOptionsRadioButtons";
import ColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/ColorOptions";
import BlockExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockExpandableBar";
import ImageSelector from "../PAGEBUILDER_UTILITY_COMPONENTS/ImageSelector";
import CategoryExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/CategoryExpandableBar";
import { BLOCKNAMES, BLOCKS } from "../../blocks";
import BlockColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockColorOptions";
import RichTextEditor from "../PAGEBUILDER_UTILITY_COMPONENTS/RichTextEditor";
import VerticalPaddingSliders from "../PAGEBUILDER_UTILITY_COMPONENTS/VerticalPaddingSliders";

interface Props {
  content: any;
  index: number;
  handleBlockChange: Function;
  disabledStyle: string;
}

export const twoTextColumnsInit = {
  title1: "Title Text",
  subtitle1: "subtitle",
  textAlign1: 0,
  richText1: "",
  title2: "Title Text",
  subtitle2: "subtitle",
  textAlign2: 0,
  richText2: "",
  textBoxWidth: 0,
  textSize: 0,

  imageData: { imageID: "", crop: true, vFocus: 50, hFocus: 50 },
  hFocus: 50,
  vFocus: 50,
  titleColor: 0,
  textColor: 0,
  bgColor: 0,
  vPaddingTop: 30,
  vPaddingBottom: 30,
};

export const TwoTextColumnsControl = ({
  content,
  index,
  handleBlockChange,
  disabledStyle,
}: Props) => {
  const handleValueChange = (key: string, value: string | boolean | number) => {
    const newContent = cloneDeep(content);
    newContent[key] = value;
    handleBlockChange({ ...newContent }, index);
  };

  return (
    <>
      <CategoryExpandableBar title="TEXT" index={index}>
        <ControllerSection>
          <label htmlFor="title1">Title Left</label>
          <FullWidthInput
            type="text"
            value={content.title1}
            onChange={(e) => handleValueChange("title1", e.target.value)}
          />
        </ControllerSection>

        <ControllerSection>
          <label htmlFor="subtitle">Subtitle Left</label>
          <FullWidthInput
            type="text"
            value={content.subtitle1}
            onChange={(e) => handleValueChange("subtitle1", e.target.value)}
          />{" "}
        </ControllerSection>
        <ControllerSection>
          <input
            type="checkbox"
            checked={content.displayCircle}
            onChange={() => {
              handleValueChange("displayCircle", !content.displayCircle);
            }}
          ></input>
          <label>Display Animated Circle</label>
        </ControllerSection>
        <ControllerSection>
          <span>Text Left</span>
          <RichTextEditor
            value={content.richText1}
            propName="richText1"
            handleValueChange={handleValueChange}
          />
        </ControllerSection>
        <ControllerSection>
          <span>Text Align Left</span>
          <XOptionsRadioButtons
            propName="textAlign1"
            currentVal={content.textAlign1}
            handleChange={handleValueChange}
            index={index}
            labels={["left", "center", "right"]}
          />
        </ControllerSection>

        <ControllerSection>
          <label htmlFor="title2">Title Right</label>
          <FullWidthInput
            type="text"
            value={content.title2}
            onChange={(e) => handleValueChange("title2", e.target.value)}
          />
        </ControllerSection>

        <ControllerSection>
          <label htmlFor="subtitle">Subtitle Right</label>
          <FullWidthInput
            type="text"
            value={content.subtitle2}
            onChange={(e) => handleValueChange("subtitle2", e.target.value)}
          />{" "}
        </ControllerSection>
        <ControllerSection>
          <span>Text Right</span>
          <RichTextEditor
            value={content.richText2}
            propName="richText2"
            handleValueChange={handleValueChange}
          />
        </ControllerSection>
        <ControllerSection>
          <span>Text Align Right</span>
          <XOptionsRadioButtons
            propName="textAlign2"
            currentVal={content.textAlign2}
            handleChange={handleValueChange}
            index={index}
            labels={["left", "center", "right"]}
          />
        </ControllerSection>
        <ControllerSection>
          <label>Text Size</label>
          <XOptionsRadioButtons
            index={index}
            propName="textSize"
            labels={["Normal", "Large", "XL"]}
            currentVal={content.textSize}
            handleChange={handleValueChange}
          />
        </ControllerSection>

        <ControllerSection>
          <label>Text Box Width</label>
          <XOptionsRadioButtons
            index={index}
            propName="textBoxWidth"
            labels={["Full", "Medium", "Small"]}
            currentVal={content.textBoxWidth}
            handleChange={handleValueChange}
          />
        </ControllerSection>
        <ControllerSection>
          <span>Text Align Mobile</span>
          <XOptionsRadioButtons
            propName="textAlignMobile"
            currentVal={content.textAlignMobile}
            handleChange={handleValueChange}
            index={index}
            labels={["left", "center", "right"]}
          />
        </ControllerSection>
      </CategoryExpandableBar>
      <CategoryExpandableBar title="BACKGROUND IMAGE" index={index}>
        <ControllerSection>
          <ImageSelector
            currentValue={content.imageData}
            handleValueChange={handleValueChange}
            label="Background Image:"
            propName="imageData"
            croppedByDefault
          />
        </ControllerSection>
      </CategoryExpandableBar>
      <CategoryExpandableBar title="BLOCK OPTIONS" index={index}>
        <VerticalPaddingSliders
          vPaddingBottom={content.vPaddingBottom}
          vPaddingTop={content.vPaddingTop}
          handleValueChange={handleValueChange}
        />
      </CategoryExpandableBar>
      <BlockColorOptions
        titleColor={content.titleColor}
        bgColor={content.bgColor}
        textColor={content.textColor}
        handleValueChange={handleValueChange}
      />
    </>
  );
};
export default TwoTextColumnsControl;
