import styled from "@emotion/styled";
import { propTypes } from "gatsby-plugin-image/dist/src/components/static-image.server";

export const COLORS = {
  white: "#ffffff",
  accent1: "#F6F6F9",
  accent2: "#E5E2E9",
  deep: "#A51AA8",
  red: "#EB5C5C",
  variation1: "#C98ED4",
  variation2: "#D48CA2",
  variation3: "#8CAFD4",
  variation4: "#A58CD4",
  variation5: "#d4b78c",
  variation6: "#bed48c",
  variation7: "#8cd49b",
  variation8: "#8cd4bc",

  black: "#111",
};

export const SHADOWS = {
  medium: "0 0 5px #727272",
  large: "0 0 9px #535353",
};

export const MARGIN = "10px";

export const ROUNDNESS = "10px";

export const Container = styled.div`
  color: ${COLORS.black};
  justify-content: space-between;
  font-family: Helvetica, sans-serif;
  & > span,
  > label {
    font-weight: bold;
    display: block;
  }
`;

export const Input = styled.input`
  border: 1px solid black;
  display: block;
  width: 100%;
  min-width: 300px;
  max-width: 400px;
  margin-bottom: ${MARGIN};
`;

export const FullWidthInput = styled.input`
  border: 1px solid black;
  display: block;
  width: 100%;
`;

export const Button = styled.div`
  color: ${COLORS.white};
  background-color: ${(props: Buttonprops) => props.bgColor || COLORS.deep};
  opacity: ${(props: Buttonprops) => (props.disabled ? 0.2 : 1)};
  width: fit-content;
  padding: 6px;
  font-size: 1rem;
  border-radius: ${ROUNDNESS};
  box-shadow: ${SHADOWS.medium};
  margin-bottom: ${MARGIN};
  transition: box-shadow 0.2s;
  &:hover {
    cursor: ${(props: Buttonprops) => (props.disabled ? "auto" : "pointer")};
    box-shadow: ${(props: Buttonprops) =>
      props.disabled ? "none" : SHADOWS.large};
  }
`;

export const ExplanationP = styled.p`
  margin-bottom: 0;
  width: 100%;
  min-width: 300px;
  max-width: 400px;
`;

type Buttonprops = {
  bgColor?: string;
  disabled?: boolean;
};

export const TextArea = styled.textarea`
  border: 1px solid black;
  display: block;
  width: 100%;
`;

export const ControllerSection = styled.section`
  margin-bottom: 20px;
  & > span,
  > label {
    font-weight: bold;
  }
`;

export const SmallMargin = styled.div`
  height: 20px;
  display: block;
  width: 100%;
`;

export const LargeMargin = styled.div`
  height: 50px;
  display: block;
  width: 100%;
`;

export const LoadingRing = styled.div`
  display: inline-block;
  width: 80px;
  height: 80px;

  :after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #6453ff;
    border-color: #6453ff transparent #6453ff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
