/** @jsx jsx */
import React, { useState, useEffect, useRef } from "react";
import { css, jsx } from "@emotion/react";
import ImageComponent from "../ImageComponent";
import HighlightBlock from "./HighlightBlock";

import {
  colors,
  getTextAlignFromNumber,
  getXPaddingAmtFromNumber,
  vPaddingMultiplier,
  xPaddingAmtMobile,
} from "../../styles/style";
import { fillInMissingBlockDataWithDefaults } from "../../cms/functions";
import { titleBlockInit } from "../../cms/CMSComponents/CONTROLLER_COMPONENTS/TitleBlockControl";
import AnimatedTypingTitle from "../AnimatedTypingTitle";
import H1H3Switch from "../H1H3Switch";
import H2H4Switch from "../H2H4Switch";

type Props = {
  blockContent: string;
  index: number;
  highlighted: boolean;
  pageTitleColor: number;
  breakPoint: number;
};

function TitleBlock({
  blockContent,
  index,
  highlighted,
  pageTitleColor,
  breakPoint,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  //const [isVisible, yFactor] = useIntersection(ref.current);
  const [isVisible, setIsVisible] = useState(false);

  const DATA = fillInMissingBlockDataWithDefaults(
    JSON.parse(blockContent),
    titleBlockInit
  );

  let textAlignClass = getTextAlignFromNumber(DATA.textAlign);
  let xPaddingAmt = getXPaddingAmtFromNumber(1, breakPoint);

  useEffect(() => {
    const observer =
      typeof window !== "undefined"
        ? new IntersectionObserver(([entry]) => {
            setIsVisible(entry.isIntersecting);
          })
        : undefined;
    if (ref.current && typeof window !== "undefined") {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current && typeof window !== "undefined") {
        observer.unobserve(ref.current);
      }
    };
  }, [ref.current]);

  return (
    <section
      ref={ref}
      id={"container" + index}
      className={`relative  w-screen transition-colors ${textAlignClass}`}
      style={{
        backgroundColor: colors[DATA.bgColor],
        padding: `${DATA.vPaddingTop * vPaddingMultiplier}px ${xPaddingAmt}px ${
          DATA.vPaddingBottom * vPaddingMultiplier
        }px`,
      }}
    >
      {DATA.animateTitle ? (
        <AnimatedTypingTitle
          DATA={DATA}
          pageTitleColor={pageTitleColor}
          isVisible={isVisible}
        />
      ) : (
        <>
          <H1H3Switch
            className={`z-10 ${
              DATA.titleSize === 1
                ? "text-4xl"
                : "text-4xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl"
            } font-title -ml-0.75 sm:-ml-0.5 lg:-ml-1 font-normal`}
            style={{
              color:
                DATA.titleColor === 0
                  ? colors[pageTitleColor]
                  : colors[DATA.titleColor],
            }}
          >
            {DATA.italicTitle ? <i>{DATA.title}</i> : DATA.title}
          </H1H3Switch>
          <H2H4Switch
            className={`z-10 ${
              DATA.titleSize === 1
                ? "text-2xl"
                : "text-2xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-4xl"
            } font-title sm:-ml-0.5 font-normal`}
            style={{
              color: colors[DATA.textColor],
            }}
          >
            {DATA.subtitle}
          </H2H4Switch>
        </>
      )}

      {DATA.imageData && (
        <div
          className={`absolute top-0 left-0 w-full h-full z-0 overflow-hidden`}
        >
          <ImageComponent imageData={DATA.imageData} crop={true} />
        </div>
      )}

      {highlighted && <HighlightBlock />}
    </section>
  );
}

export default TitleBlock;
