import { useState, useEffect, useRef, useCallback } from "react";

export const useIntersectionCallback = () => {
  const [yFactor, setYFactor] = useState<number>(0);
  const containerRef = useRef(null);
  const waitingForAnimation = useRef(false);

  const ref = useCallback((node) => {
    if (node !== null && typeof window !== "undefined") {
      containerRef.current = node;
    }
  }, []);

  function handleScroll() {
    if (!waitingForAnimation.current && containerRef.current) {
      waitingForAnimation.current = true;
      requestAnimationFrame(() => {
        setYFactor(
          containerRef.current.getBoundingClientRect().bottom /
            (window.innerHeight +
              containerRef.current.getBoundingClientRect().height)
        );
        waitingForAnimation.current = false;
      });
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll);
      handleScroll();
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return [ref, yFactor];
};

export default useIntersectionCallback;
