/** @jsx jsx */

import { useState } from "react";
import {
  FullWidthInput,
  TextArea,
  ControllerSection,
  Input,
  COLORS,
  ExplanationP,
} from "../../style/styledComponents";
import { cloneDeep } from "lodash-es";
import { css, jsx } from "@emotion/react";
import XOptionsRadioButtons from "../PAGEBUILDER_UTILITY_COMPONENTS/XOptionsRadioButtons";
import ColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/ColorOptions";
import BlockExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockExpandableBar";
import ImageSelector from "../PAGEBUILDER_UTILITY_COMPONENTS/ImageSelector";
import CategoryExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/CategoryExpandableBar";
import { BLOCKNAMES, BLOCKS } from "../../blocks";
import BlockColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockColorOptions";
import VerticalPaddingSliders from "../PAGEBUILDER_UTILITY_COMPONENTS/VerticalPaddingSliders";
import VideoSelector from "../PAGEBUILDER_UTILITY_COMPONENTS/VideoSelector";
import RichTextEditor from "../PAGEBUILDER_UTILITY_COMPONENTS/RichTextEditor";

interface Props {
  content: any;
  index: number;
  handleBlockChange: Function;
}

const initImg = { imageID: "", crop: true, vFocus: 50, hFocus: 50 };

export const logoBlockInit = {
  title: "Title Text",
  img1: initImg,
  img2: initImg,
  img3: initImg,
  img4: initImg,
  img5: initImg,
  img6: initImg,
  img7: initImg,
  img8: initImg,
  img9: initImg,
  img10: initImg,
  img11: initImg,
  img12: initImg,
  img13: initImg,
  img14: initImg,
  clipStyle: 0,
  textColor: 0,
  titleColor: 0,
  bgColor: 0,
  imageLeft: false,
  textPadding: 60,
  imagePadding: false,
  vPaddingTop: 30,
  vPaddingBottom: 30,
};

export const LogoBlockControl = ({
  content,
  index,
  handleBlockChange,
}: Props) => {
  const handleValueChange = (key: string, value: string | boolean | number) => {
    const newContent = cloneDeep(content);
    newContent[key] = value;
    handleBlockChange({ ...newContent }, index);
  };

  return (
    <>
      <CategoryExpandableBar title="TEXT" index={index}>
        <ControllerSection>
          <label htmlFor="title">Title</label>
          <FullWidthInput
            name="title"
            id="title"
            type="text"
            value={content.title}
            onChange={(e) => handleValueChange("title", e.target.value)}
          />
        </ControllerSection>
      </CategoryExpandableBar>

      <CategoryExpandableBar title="Row 1" index={index}>
        <ControllerSection>
          <ImageSelector
            croppedByDefault
            currentValue={content.img1}
            handleValueChange={handleValueChange}
            label="Image 1:"
            propName="img1"
          />
          <ImageSelector
            croppedByDefault
            currentValue={content.img2}
            handleValueChange={handleValueChange}
            label="Image 2:"
            propName="img2"
          />
          <ImageSelector
            croppedByDefault
            currentValue={content.img3}
            handleValueChange={handleValueChange}
            label="Image 3:"
            propName="img3"
          />
          <ImageSelector
            croppedByDefault
            currentValue={content.img4}
            handleValueChange={handleValueChange}
            label="Image 4:"
            propName="img4"
          />
          <ImageSelector
            croppedByDefault
            currentValue={content.img5}
            handleValueChange={handleValueChange}
            label="Image 5:"
            propName="img5"
          />
          <ImageSelector
            croppedByDefault
            currentValue={content.img6}
            handleValueChange={handleValueChange}
            label="Image 6:"
            propName="img6"
          />
          <ImageSelector
            croppedByDefault
            currentValue={content.img7}
            handleValueChange={handleValueChange}
            label="Image 7:"
            propName="img7"
          />
        </ControllerSection>
      </CategoryExpandableBar>
      <CategoryExpandableBar title="Row 2" index={index}>
        <ControllerSection>
          <ImageSelector
            croppedByDefault
            currentValue={content.img8}
            handleValueChange={handleValueChange}
            label="Image 8:"
            propName="img8"
          />
          <ImageSelector
            croppedByDefault
            currentValue={content.img9}
            handleValueChange={handleValueChange}
            label="Image 9:"
            propName="img9"
          />
          <ImageSelector
            croppedByDefault
            currentValue={content.img10}
            handleValueChange={handleValueChange}
            label="Image 10:"
            propName="img10"
          />
          <ImageSelector
            croppedByDefault
            currentValue={content.img11}
            handleValueChange={handleValueChange}
            label="Image 11:"
            propName="img11"
          />
          <ImageSelector
            croppedByDefault
            currentValue={content.img12}
            handleValueChange={handleValueChange}
            label="Image 12:"
            propName="img12"
          />
          <ImageSelector
            croppedByDefault
            currentValue={content.img13}
            handleValueChange={handleValueChange}
            label="Image 13:"
            propName="img13"
          />
          <ImageSelector
            croppedByDefault
            currentValue={content.img14}
            handleValueChange={handleValueChange}
            label="Image 14:"
            propName="img14"
          />
        </ControllerSection>
      </CategoryExpandableBar>

      <CategoryExpandableBar title="BLOCK OPTIONS" index={index}>
        <ControllerSection>
          <span>Content Width:</span>
          <XOptionsRadioButtons
            propName="contentWidth"
            currentVal={content.contentWidth}
            handleChange={handleValueChange}
            index={index}
            labels={["Full-width", "Extra padding"]}
          />
        </ControllerSection>
        <VerticalPaddingSliders
          vPaddingBottom={content.vPaddingBottom}
          vPaddingTop={content.vPaddingTop}
          handleValueChange={handleValueChange}
        />
      </CategoryExpandableBar>
      <BlockColorOptions
        titleColor={content.titleColor}
        bgColor={content.bgColor}
        textColor={content.textColor}
        handleValueChange={handleValueChange}
      />
    </>
  );
};
export default LogoBlockControl;
