/** @jsx jsx */

import {
  FullWidthInput,
  TextArea,
  ControllerSection,
  COLORS,
  ExplanationP,
} from "../../style/styledComponents";
import { cloneDeep } from "lodash-es";
import { css, jsx } from "@emotion/react";
import XOptionsRadioButtons from "../PAGEBUILDER_UTILITY_COMPONENTS/XOptionsRadioButtons";
import ImageSelector, {
  imageData,
} from "../PAGEBUILDER_UTILITY_COMPONENTS/ImageSelector";
import CategoryExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/CategoryExpandableBar";
import BlockColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockColorOptions";
import VerticalPaddingSliders from "../PAGEBUILDER_UTILITY_COMPONENTS/VerticalPaddingSliders";
import ControlledArrayItems from "../PAGEBUILDER_UTILITY_COMPONENTS/ControlledArrayItems";
import VideoSelector from "../PAGEBUILDER_UTILITY_COMPONENTS/VideoSelector";

interface Props {
  content: any;
  index: number;
  handleBlockChange: Function;
  disabledStyle: string;
}

export type imageType = {
  image: imageData;
  label: string;
};

const initImg = { imageID: "", vFocus: 50, hFocus: 50, crop: true };

const initVideo = {
  video: { videoID: "", aspectRatioX: 16, aspectRatioY: 9 },
  thumbnail: { imageID: "", vFocus: 50, hFocus: 50, crop: true },
};

export const KostenVideosBlockInit = {
  title: "",
  bottomText: "",
  video1Link: "",
  video1Text: "",
  video1Image: { ...initImg },
  video1Video: { ...initVideo },
  video2Link: "",
  video2Text: "",
  video2Image: { ...initImg },
  video2Video: { ...initVideo },
  video3Link: "",
  video3Text: "",
  video3Image: { ...initImg },
  video3Video: { ...initVideo },
  contentWidth: 1,
  titleColor: 0,
  textColor: 0,
  bgColor: 0,
  vPaddingTop: 30,
  vPaddingBottom: 30,
};

export const KostenVideosBlockControl = ({
  content,
  index,
  handleBlockChange,
  disabledStyle,
}: Props) => {
  const handleValueChange = (key: string, value: any) => {
    const newContent = cloneDeep(content);
    newContent[key] = value;
    handleBlockChange({ ...newContent }, index);
  };

  return (
    <>
      <CategoryExpandableBar title="Video 1" index={index}>
        <ControllerSection>
          <label>Links To:</label>
          <ExplanationP>
            Link to the project page. Leave out the first part of the URL: e.g.
            for www.awesometestsite.com/prijzen/2000-4000 enter only
            "/prijzen/2000-4000"
          </ExplanationP>
          <FullWidthInput
            type="text"
            value={content.video1Link}
            onChange={(e) => handleValueChange("video1Link", e.target.value)}
          />
        </ControllerSection>
        <ControllerSection>
          <label htmlFor="video1Text">Video text</label>
          <FullWidthInput
            type="text"
            value={content.video1Text}
            onChange={(e) => handleValueChange("video1Text", e.target.value)}
          />
        </ControllerSection>
        <ControllerSection>
          <ImageSelector
            currentValue={content.video1Image}
            handleValueChange={handleValueChange}
            label="Image:"
            propName="video1Image"
            croppedByDefault
          />
        </ControllerSection>
        <ControllerSection>
          <label>Hover video</label>
          <VideoSelector
            propName="video1Video"
            currentValue={content.video1Video}
            handleValueChange={handleValueChange}
          ></VideoSelector>
        </ControllerSection>
      </CategoryExpandableBar>

      <CategoryExpandableBar title="Video 2" index={index}>
        <ControllerSection>
          <label>Links To:</label>
          <ExplanationP>
            Link to the project page. Leave out the first part of the URL: e.g.
            for www.awesometestsite.com/prijzen/2000-4000 enter only
            "/prijzen/2000-4000"
          </ExplanationP>
          <FullWidthInput
            type="text"
            value={content.video2Link}
            onChange={(e) => handleValueChange("video2Link", e.target.value)}
          />
        </ControllerSection>
        <ControllerSection>
          <label htmlFor="video2Text">Video text</label>
          <FullWidthInput
            type="text"
            value={content.video2Text}
            onChange={(e) => handleValueChange("video2Text", e.target.value)}
          />
        </ControllerSection>
        <ControllerSection>
          <ImageSelector
            currentValue={content.video2Image}
            handleValueChange={handleValueChange}
            label="Image:"
            propName="video2Image"
            croppedByDefault
          />
        </ControllerSection>
        <ControllerSection>
          <label>Hover video</label>
          <VideoSelector
            propName="video2Video"
            currentValue={content.video2Video}
            handleValueChange={handleValueChange}
          ></VideoSelector>
        </ControllerSection>
      </CategoryExpandableBar>

      <CategoryExpandableBar title="Video 3" index={index}>
        <ControllerSection>
          <label>Links To:</label>
          <ExplanationP>
            Link to the project page. Leave out the first part of the URL: e.g.
            for www.awesometestsite.com/prijzen/2000-4000 enter only
            "/prijzen/2000-4000"
          </ExplanationP>
          <FullWidthInput
            type="text"
            value={content.video3Link}
            onChange={(e) => handleValueChange("video3Link", e.target.value)}
          />
        </ControllerSection>
        <ControllerSection>
          <label htmlFor="video3Text">Video text</label>
          <FullWidthInput
            type="text"
            value={content.video3Text}
            onChange={(e) => handleValueChange("video3Text", e.target.value)}
          />
        </ControllerSection>
        <ControllerSection>
          <ImageSelector
            currentValue={content.video3Image}
            handleValueChange={handleValueChange}
            label="Image:"
            propName="video3Image"
            croppedByDefault
          />
        </ControllerSection>
        <ControllerSection>
          <label>Hover video</label>
          <VideoSelector
            propName="video3Video"
            currentValue={content.video3Video}
            handleValueChange={handleValueChange}
          ></VideoSelector>
        </ControllerSection>
      </CategoryExpandableBar>

      <CategoryExpandableBar title="TEXT" index={index}>
        <ControllerSection>
          <label>Title</label>
          <FullWidthInput
            type="text"
            value={content.title}
            onChange={(e) => handleValueChange("title", e.target.value)}
          />
        </ControllerSection>

        <ControllerSection>
          <label htmlFor="bottomText">Bottom text</label>
          <FullWidthInput
            type="text"
            value={content.bottomText}
            onChange={(e) => handleValueChange("bottomText", e.target.value)}
          />
        </ControllerSection>
        <ControllerSection>
          <label>Links To:</label>
          <ExplanationP>
            Link to the project page. Leave out the first part of the URL: e.g.
            for www.awesometestsite.com/prijzen/2000-4000 enter only
            "/prijzen/2000-4000"
          </ExplanationP>
          <FullWidthInput
            type="text"
            value={content.bottomLink}
            onChange={(e) => handleValueChange("bottomLink", e.target.value)}
          />
        </ControllerSection>
      </CategoryExpandableBar>

      <CategoryExpandableBar title="BLOCK OPTIONS" index={index}>
        <ControllerSection>
          <span>Content Width:</span>
          <XOptionsRadioButtons
            propName="contentWidth"
            currentVal={content.contentWidth}
            handleChange={handleValueChange}
            index={index}
            labels={["Full-width", "Extra padding"]}
          />
        </ControllerSection>
        <VerticalPaddingSliders
          vPaddingBottom={content.vPaddingBottom}
          vPaddingTop={content.vPaddingTop}
          handleValueChange={handleValueChange}
        />
      </CategoryExpandableBar>
      <BlockColorOptions
        titleColor={content.titleColor}
        bgColor={content.bgColor}
        textColor={content.textColor}
        handleValueChange={handleValueChange}
      />
    </>
  );
};
export default KostenVideosBlockControl;
