/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { findSeoData } from "../functions";

interface Props {
  description?: string;
  lang?: string;
  meta: any;
  title: string;
}

const SEO = ({ description, lang, meta, title }: Props) => {
  const data = useStaticQuery(
    graphql`
      query SEOQuery {
        allMarkdownRemark {
          edges {
            node {
              frontmatter {
                seoBuilder {
                  shareImageUrl
                  siteDescription
                  siteTitle
                }
              }
              fileAbsolutePath
            }
          }
        }
      }
    `
  );

  const seoData = findSeoData(data.allMarkdownRemark.edges);

  const metaDescription = description || seoData.siteDescription;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${seoData.siteTitle} | ${title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: `${seoData.siteTitle} | ${title}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: seoData.shareImageUrl,
        },
        {
          property: `og:image:type`,
          content: "image/png",
        },
        {
          property: `og:image:alt`,
          content: "logo",
        },
        {
          property: `og:image:width`,
          content: "800px",
        },
        {
          property: `og:image:height`,
          content: "800px",
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:site`,
          content: seoData.url,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
};

SEO.defaultProps = {
  lang: `nl`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
