/** @jsx jsx */

import { useState } from "react";
import {
  FullWidthInput,
  TextArea,
  ControllerSection,
  COLORS,
  Button,
  Input,
  ExplanationP,
} from "../../style/styledComponents";
import { cloneDeep } from "lodash-es";
import { css, jsx } from "@emotion/react";
import XOptionsRadioButtons from "../PAGEBUILDER_UTILITY_COMPONENTS/XOptionsRadioButtons";
import ColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/ColorOptions";
import BlockExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockExpandableBar";
import ImageSelector from "../PAGEBUILDER_UTILITY_COMPONENTS/ImageSelector";
import CategoryExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/CategoryExpandableBar";
import { BLOCKNAMES, BLOCKS } from "../../blocks";
import BlockColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockColorOptions";
import RichTextEditor from "../PAGEBUILDER_UTILITY_COMPONENTS/RichTextEditor";
import VerticalPaddingSliders from "../PAGEBUILDER_UTILITY_COMPONENTS/VerticalPaddingSliders";

import IoIosArrowDown from "../../../images/icons/angle-down-solid";
import IoIosArrowUp from "../../../images/icons/angle-up-solid";
import AiOutlineClose from "../../../images/icons/xmark-solid";

interface Props {
  content: any;
  index: number;
  handleBlockChange: Function;
  disabledStyle: string;
}

export const creditsBlockInit = {
  title: "Title Text",
  subtitle: "subtitle",
  textLines: [],
  imageData: { imageID: "", crop: true, vFocus: 50, hFocus: 50 },
  titleColor: 0,
  textColor: 0,
  lineAmt: 8,
  bgColor: 0,
  vPaddingTop: 30,
  vPaddingBottom: 30,
};

export const CreditsBlockControl = ({
  content,
  index,
  handleBlockChange,
  disabledStyle,
}: Props) => {
  const handleValueChange = (key: string, value: string | boolean | number) => {
    const newContent = cloneDeep(content);
    newContent[key] = value;
    handleBlockChange({ ...newContent }, index);
  };

  return (
    <>
      <CategoryExpandableBar title="TEXT" index={index}>
        <ControllerSection>
          <label htmlFor="title">Title</label>
          <FullWidthInput
            type="text"
            value={content.title}
            onChange={(e) => handleValueChange("title", e.target.value)}
          />
        </ControllerSection>

        <ControllerSection>
          <label htmlFor="subtitle">Subtitle</label>
          <FullWidthInput
            type="text"
            value={content.subtitle}
            onChange={(e) => handleValueChange("subtitle", e.target.value)}
          />{" "}
        </ControllerSection>
        <ControllerSection>
          <CreditsController
            handleValueChange={handleValueChange}
            textLines={content.textLines}
          />
        </ControllerSection>

        <ControllerSection>
          <label htmlFor="subtitle">Amount of line to display</label>
          <ExplanationP>
            If more lines than this amount are provided, a button to expand the
            list will be displayed
          </ExplanationP>
          <FullWidthInput
            type="number"
            value={content.lineAmt ? content.lineAmt : 8}
            onChange={(e) => handleValueChange("lineAmt", e.target.value)}
          />{" "}
        </ControllerSection>
      </CategoryExpandableBar>
      <CategoryExpandableBar title="BACKGROUND IMAGE" index={index}>
        <ControllerSection>
          <ImageSelector
            currentValue={content.imageData}
            handleValueChange={handleValueChange}
            label="Background Image:"
            propName="imageData"
            croppedByDefault
          />
        </ControllerSection>
      </CategoryExpandableBar>
      <CategoryExpandableBar title="BLOCK OPTIONS" index={index}>
        <VerticalPaddingSliders
          vPaddingBottom={content.vPaddingBottom}
          vPaddingTop={content.vPaddingTop}
          handleValueChange={handleValueChange}
        />
      </CategoryExpandableBar>
      <BlockColorOptions
        titleColor={content.titleColor}
        bgColor={content.bgColor}
        textColor={content.textColor}
        handleValueChange={handleValueChange}
      />
    </>
  );
};
export default CreditsBlockControl;

type CreditsProps = {
  handleValueChange: Function;
  textLines: string[][];
};

function CreditsController({ handleValueChange, textLines }: CreditsProps) {
  const addCredit = () => {
    const newState = [...textLines];
    newState.push(["", ""]);
    handleValueChange("textLines", newState);
  };

  const deleteItem = (index: number) => {
    const newState = [...textLines];
    newState.splice(index, 1);
    handleValueChange("textLines", newState);
  };

  const moveItem = (index: number, amt: number) => {
    if (index + amt >= 0 && index + amt < textLines.length) {
      const newState = [...textLines];
      const movedItem = newState.splice(index, 1);
      newState.splice(index + amt, 0, ...movedItem);
      handleValueChange("textLines", newState);
    }
  };

  const changeCredit = (index: number, leftOrRight: number, value: string) => {
    const newState = [...textLines];
    newState[index][leftOrRight] = value;
    handleValueChange("textLines", newState);
  };

  return (
    <div>
      <label>Text Lines</label>
      {textLines.map((line, index) => {
        return (
          <div
            css={css`
              display: flex;
              position: relative;
              width: 100%;
              justify-content: flex-start;
            `}
          >
            <Input
              css={css`
                flex-grow: 1;
                min-width: 80px;
                margin-right: 2px;
              `}
              type="text"
              value={line[0]}
              onChange={(e) => changeCredit(index, 0, e.target.value)}
            />
            <Input
              css={css`
                flex-grow: 1;
                min-width: 80px;
                margin-left: 2px;
                margin-right: 5px;
              `}
              type="text"
              value={line[1]}
              onChange={(e) => changeCredit(index, 1, e.target.value)}
            />
            <div
              css={css`
                width: 60px;
                height: 19px;
                display: flex;
                justify-content: space-between;
                align-items: center;
              `}
            >
              <IoIosArrowUp
                onClick={() => moveItem(index, -1)}
                css={css`
                  height: 1.1rem;
                  cursor: ${index > 0 ? "pointer" : "default"};
                  opacity: ${index > 0 ? 1 : 0.3};

                  margin: 0 2px;
                `}
              />
              <IoIosArrowDown
                onClick={() => moveItem(index, 1)}
                css={css`
                  height: 1.1rem;
                  cursor: ${index < textLines.length - 1
                    ? "pointer"
                    : "default"};
                  opacity: ${index < textLines.length - 1 ? 1 : 0.3};
                  cursor: pointer;
                  margin: 0 2px;
                `}
              />
              <AiOutlineClose
                onClick={() => deleteItem(index)}
                css={css`
                  height: 0.9rem;
                  cursor: pointer;
                  margin: 0 2px;
                `}
              />
            </div>
          </div>
        );
      })}
      <div
        css={css`
          display: flex;
          width: 100%;
          justify-content: space-between;
        `}
      >
        <Button bgColor={COLORS.variation1} onClick={addCredit}>
          Add Line
        </Button>
      </div>
    </div>
  );
}
