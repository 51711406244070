/** @jsx jsx */

import { css, jsx } from "@emotion/react";

import HighlightBlock from "./HighlightBlock";

import {
  colors,
  getXPaddingAmtFromNumber,
  vPaddingMultiplier,
} from "../../styles/style";
import { fillInMissingBlockDataWithDefaults } from "../../cms/functions";

import { mapBlockInit } from "../../cms/CMSComponents/CONTROLLER_COMPONENTS/MapBlockControl";

type Props = {
  blockContent: string;
  index: number;
  highlighted: boolean;
  pageTitleColor: number;
  breakPoint: number;
};

function MapBlock({
  blockContent,
  index,
  highlighted,
  pageTitleColor,
  breakPoint,
}: Props) {
  const DATA = fillInMissingBlockDataWithDefaults(
    JSON.parse(blockContent),
    mapBlockInit
  );

  let xPaddingAmt = getXPaddingAmtFromNumber(DATA.contentWidth, breakPoint);
  let heightClass = "";

  return (
    <section
      id={"container" + index}
      className={`relative  w-screen transition-colors `}
      style={{
        backgroundColor: colors[DATA.bgColor],
        padding: `${DATA.vPaddingTop * vPaddingMultiplier}px ${xPaddingAmt}px ${
          DATA.vPaddingBottom * vPaddingMultiplier
        }px`,
      }}
    >
      <iframe
        className={` w-full`}
        height="500px"
        id="gmap_canvas"
        src="https://maps.google.com/maps?q=Hooghiemstraplein%20121%20Utrecht&t=&z=15&ie=UTF8&iwloc=&output=embed"
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
      ></iframe>

      {highlighted && <HighlightBlock />}
    </section>
  );
}

export default MapBlock;
