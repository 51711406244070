/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import { useEffect, useState } from "react";
import HighlightBlock from "./HighlightBlock";
import {
  colors,
  getTextAlignFromNumber,
  getXPaddingAmtFromNumber,
  maxContentWidth,
  vPaddingMultiplier,
} from "../../styles/style";
import { Link } from "gatsby";
import { fillInMissingBlockDataWithDefaults } from "../../cms/functions";
import { blogPostsPreviewInit } from "../../cms/CMSComponents/CONTROLLER_COMPONENTS/BlogPostsPreviewBlockControl";
import BlogPostPreview from "../BlogPostPreview";
import { cloneDeep } from "lodash-es";
import { sortBlogPostsByDate } from "../../functions";
import { motion } from "framer-motion";
import useScrollInViewAnimation from "../../hooks/UseScrollInViewAnimation";
import ANIMATIONS from "../../styles/animations";
import TitleColorAccents from "../TitleColorAccents";
import DecorationCircle from "../DecorationCircle";
import ColorAccentsText from "../ColorAccentsText";
import { Color } from "@cloudinary/url-gen/qualifiers";

type Props = {
  blockContent: string;
  index: number;
  highlighted: boolean;
  blogPosts: any[];
  pageTitleColor: number;
  breakPoint: number;
};

function BlogPostsPreviewBlock({
  blockContent,
  index,
  highlighted,
  blogPosts,
  pageTitleColor,
  breakPoint,
}: Props) {
  const DATA = fillInMissingBlockDataWithDefaults(
    JSON.parse(blockContent),
    blogPostsPreviewInit
  );
  const [filteredBlogPosts, setFilteredBlogposts] = useState<any[]>([]);

  let textAlignClass = getTextAlignFromNumber(DATA.textAlign);
  let xPaddingAmt = getXPaddingAmtFromNumber(DATA.contentWidth, breakPoint);

  const [titleRef, titleControls] = useScrollInViewAnimation(true);

  useEffect(() => {
    const blogPostsInstance = cloneDeep(blogPosts);
    blogPostsInstance.forEach((post: any) => {
      if (typeof post.blogOptions === "string")
        post.blogOptions = JSON.parse(post.blogOptions);
    });
    setFilteredBlogposts(sortBlogPostsByDate(blogPostsInstance));
  }, []);

  return (
    <section
      id={"container" + index}
      className={`relative  w-screen transition-colors flex flex-col items-center`}
      style={{
        backgroundColor: colors[DATA.bgColor],
        padding: `${DATA.vPaddingTop * vPaddingMultiplier}px ${xPaddingAmt}px ${
          DATA.vPaddingBottom * vPaddingMultiplier
        }px`,
        color: colors[DATA.textColor],
      }}
    >
      <div
        className="w-full mx-auto flex flex-col items-center"
        style={{ maxWidth: maxContentWidth + "px" }}
      >
        <div
          ref={titleRef}
          initial="hidden"
          animate={titleControls}
          variants={ANIMATIONS.fadeScale}
          className="flex flex-col items-center"
        >
          <h3 className="relative text-4xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl font-title -ml-0.75 sm:-ml-0.5 lg:-ml-1 font-normal">
            <ColorAccentsText
              textColor={DATA.textColor}
              center
              accentColor={
                DATA.titleColor === 0 ? pageTitleColor : DATA.titleColor
              }
              text={DATA.title}
            >
              {DATA.displayCircle && (
                <DecorationCircle
                  bgColor={DATA.titleColor}
                  growthSize={9}
                  offsetY={44}
                  growthSizeMobile={4}
                  offsetXMobile={10}
                  offsetYMobile={22}
                  offsetX={46}
                  maxMovement={-100}
                />
              )}
            </ColorAccentsText>
          </h3>
          <h4 className="text-2xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-4xl font-title sm:-ml-0.5 font-normal">
            <ColorAccentsText
              center
              textColor={DATA.textColor}
              accentColor={
                DATA.titleColor === 0 ? pageTitleColor : DATA.titleColor
              }
              text={DATA.subTitle}
            />
          </h4>
        </div>
        <div className="flex flex-col items-center mt-4">
          {filteredBlogPosts.map((post: any, index) => {
            if (index <= DATA.amtOfPosts) {
              const blogOptions = post.blogOptions;
              return (
                <BlogPostPreview
                  title={blogOptions.previewTitle}
                  previewText={blogOptions.previewText}
                  image={blogOptions.previewImage}
                  tags={blogOptions.tags}
                  pageTitleColor={pageTitleColor}
                  slug={post.slug}
                  linkText={DATA.linkText1}
                  textColor={DATA.textColor}
                  titleColor={DATA.textColor}
                  key={index}
                />
              );
            }
          })}
        </div>
        <Link className="relative" to="/blog">
          <h2
            className="inline-block text-center  hoverScaleXL"
            style={{
              color:
                DATA.titleColor === 0
                  ? colors[pageTitleColor]
                  : colors[DATA.titleColor],
            }}
          >
            {DATA.linkText2}
          </h2>
        </Link>
      </div>
      {highlighted && <HighlightBlock />}
    </section>
  );
}

export default BlogPostsPreviewBlock;
