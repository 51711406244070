/** @jsx jsx */
import { useRef } from "react";
import { css, jsx } from "@emotion/react";
import ImageComponent from "../ImageComponent";
import HighlightBlock from "./HighlightBlock";

import {
  colors,
  getTextAlignFromNumber,
  xPaddingAmtMobile,
} from "../../styles/style";

import VimeoVideoComponent from "../VimeoVideoComponent";
import { fillInMissingBlockDataWithDefaults } from "../../cms/functions";
import { heroBlockInit } from "../../cms/CMSComponents/CONTROLLER_COMPONENTS/HeroBlockControl";
import H1H3Switch from "../H1H3Switch";
import H2H4Switch from "../H2H4Switch";

export type HeroBlockProps = {
  title: string;
  subtitle: string;
  text: string;
  textAlign: number;
  textMarginX: number;
  textMarginY: number;
  textPositionX: number;
  textPositionY: number;
  imageData: any;
  hFocus: number;
  vFocus: number;
  textColor: number;
  bgColor: number;
  height: number;
};

type Props = {
  blockContent: string;
  index: number;
  highlighted: boolean;
  pageTitleColor: number;
  breakPoint: number;
  showVideoLightbox: any;
};

function HeroBlock({
  blockContent,
  index,
  highlighted,
  pageTitleColor,
  breakPoint,
  showVideoLightbox,
}: Props) {
  const sizeRef = useRef();

  const DATA = fillInMissingBlockDataWithDefaults(
    JSON.parse(blockContent),
    heroBlockInit
  );

  let heightClass = "";

  switch (DATA.height) {
    case 0:
      heightClass = "16.6vh";
      break;
    case 1:
      heightClass = "25vh";
      break;
    case 2:
      heightClass = "33vh";
      break;
    case 3:
      heightClass = "50vh";
      break;
    case 4:
      heightClass = "75vh";
      break;
    case 5:
      heightClass = "100vh";
      break;
  }
  let textAlignClass = getTextAlignFromNumber(DATA.textAlign);

  let textMarginLeft = "";
  let textMarginRight = "";
  let textMarginTop = "";
  let textMarginBottom = "";
  let textTranslateX = "";
  let textTranslateY = "";

  if (DATA.textPositionX === 0) {
    textMarginLeft = `calc(${xPaddingAmtMobile}px + ${DATA.textMarginX}%)`;
    textMarginRight = "";
    textTranslateX = "0";
  }

  if (DATA.textPositionX === 1) {
    textMarginLeft = `50%`;
    textMarginRight = "";
    textTranslateX = "-50%";
  }

  if (DATA.textPositionX === 2) {
    textMarginRight = `calc(${xPaddingAmtMobile}px + ${DATA.textMarginX}%)`;
    textMarginLeft = "";
    textTranslateX = "0";
  }

  if (DATA.textPositionY === 0) {
    textMarginTop = `${DATA.textMarginY}%`;
    textMarginBottom = "";
    textTranslateY = "0";
  }

  if (DATA.textPositionY === 1) {
    textMarginTop = `50%`;
    textMarginBottom = "";
    textTranslateY = "-50%";
  }

  if (DATA.textPositionY === 2) {
    textMarginBottom = `${DATA.textMarginY}%`;
    textMarginTop = "";
    textTranslateY = "0";
  }

  let italicClass = "";
  if (DATA.italicTitle) italicClass = "italic";

  return (
    <section
      className={`relative  w-screen flex transition-colors`}
      style={{ backgroundColor: colors[DATA.bgColor], minHeight: heightClass }}
    >
      {breakPoint !== 0 ? (
        <div
          id={"textbox" + index}
          className={`z-10 inline-block absolute
         ${textAlignClass} `}
          style={{
            left: textMarginLeft,
            right: textMarginRight,
            top: textMarginTop,
            bottom: textMarginBottom,
            transform: `translate(${textTranslateX}, ${textTranslateY})`,
            height: "fit-content",
          }}
        >
          <H1H3Switch
            isH1={DATA.isMainTitle}
            className={`${italicClass} text-4xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl font-title -ml-0.75 sm:-ml-0.5 lg:-ml-1 font-normal`}
            style={{
              color:
                DATA.titleColor === 0
                  ? colors[pageTitleColor]
                  : colors[DATA.titleColor],
            }}
          >
            {DATA.title}
          </H1H3Switch>
          <H2H4Switch
            isH2={DATA.isMainTitle}
            className={`${italicClass} text-2xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-4xl font-title sm:-ml-0.5 font-normal`}
            style={{
              color: colors[DATA.textColor],
            }}
          >
            {DATA.subtitle}
          </H2H4Switch>
        </div>
      ) : (
        <div
          id={"textbox" + index}
          className={`z-10 inline-block text-center m-auto self-center`}
          style={{
            padding: `${index === 0 ? "60px" : "0"} ${xPaddingAmtMobile}px ${
              index === 0 ? "30px" : "0"
            }`,
          }}
        >
          <H1H3Switch
            isH1={DATA.isMainTitle}
            className={`${italicClass} text-4xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl font-title -ml-0.75 sm:-ml-0.5 lg:-ml-1 font-normal`}
            style={{
              color:
                DATA.titleColor === 0
                  ? colors[pageTitleColor]
                  : colors[DATA.titleColor],
            }}
          >
            {DATA.title}
          </H1H3Switch>
          <H2H4Switch
            isH2={DATA.isMainTitle}
            className={`${italicClass} text-2xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-4xl font-title sm:-ml-0.5 font-normal`}
            style={{
              color: colors[DATA.textColor],
            }}
          >
            {DATA.subtitle}
          </H2H4Switch>
        </div>
      )}

      <div
        ref={sizeRef}
        id={"container" + index}
        className={`absolute top-0 left-0 w-full h-full z-0 overflow-hidden`}
        style={
          {
            //  maxHeight: maxImgHeight,
          }
        }
      >
        {!DATA.video.videoID && (
          <ImageComponent imageData={DATA.imageData} crop={true} />
        )}
        {DATA.video.videoID && (
          <VimeoVideoComponent
            videoData={DATA.video}
            containerSize={{
              x: sizeRef.current?.offsetWidth,
              y: sizeRef.current?.offsetHeight,
            }}
            hideBecauseVideoLightboxIsShowing={
              showVideoLightbox ? true : undefined
            }
            playing={true}
          />
        )}
      </div>

      {highlighted && <HighlightBlock />}
    </section>
  );
}

export default HeroBlock;
