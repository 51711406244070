import { number } from "prop-types";
import React, { useRef, useEffect, useState } from "react";
import Player from "@vimeo/player";

type Props = {
  videoData: {
    videoID: number;
    aspectRatioX: number;
    aspectRatioY: number;
    hFocus?: number;
    vFocus?: number;
  };
  containerSize: {
    x: number;
    y: number;
  };
  hideBecauseVideoLightboxIsShowing?: any;
  playing: boolean;
  setLoaded?: Function;
  keyStr?: string;
  dontCropVideo?: boolean;
};

export default function VimeoVideoComponent({
  videoData,
  hideBecauseVideoLightboxIsShowing,
  playing,
  containerSize,
  setLoaded,
  keyStr = "",
  dontCropVideo,
}: Props) {
  const [x, setX] = useState(1280);
  const [y, setY] = useState(720);
  const sizeRef = useRef();
  const timeoutRef = useRef();

  const iframeRef = useRef(null);
  const playerRef = useRef<any>();
  /*
  const width = Math.max(
    containerSize.y * (videoData.aspectRatioX / videoData.aspectRatioY),
    containerSize.x
  );
  const height = Math.max(
    containerSize.x * (videoData.aspectRatioY / videoData.aspectRatioX),
    containerSize.y
  );
  */

  async function setSize() {
    //console.log(containerSize);
    setX(
      dontCropVideo
        ? Math.min(
            containerSize.y * (videoData.aspectRatioX / videoData.aspectRatioY),
            containerSize.x
          )
        : Math.max(
            containerSize.y * (videoData.aspectRatioX / videoData.aspectRatioY),
            containerSize.x
          )
    );
    setY(
      dontCropVideo
        ? Math.min(
            containerSize.x * (videoData.aspectRatioY / videoData.aspectRatioX),
            containerSize.y
          )
        : Math.max(
            containerSize.x * (videoData.aspectRatioY / videoData.aspectRatioX),
            containerSize.y
          )
    );
    sizeRef.current = {
      x: Math.max(
        containerSize.y * (videoData.aspectRatioX / videoData.aspectRatioY),
        containerSize.x
      ),
      y: Math.max(
        containerSize.x * (videoData.aspectRatioY / videoData.aspectRatioX),
        containerSize.y
      ),
    };
    /*
    console.log(
      "calculated video size:" + sizeRef.current.x,
      sizeRef.current.y
    );
    console.log("container size:" + containerSize.x, containerSize.y);
    */
  }

  useEffect(() => {
    if (iframeRef.current) {
      if (!playerRef.current) playerRef.current = new Player(iframeRef.current);
      setSize();
      if (playing) {
        playerRef.current.play();
      }
      playerRef.current.on("timeupdate", (update: any) => {
        if (setLoaded && update.seconds > 2) setLoaded(true);
      });
    }
    return () => {};
  }, [iframeRef, containerSize]);

  useEffect(() => {
    if (playerRef.current) {
      if (playing) {
        playerRef.current.play();
      } else {
        playerRef.current.pause();
      }
    }
  }, [playing]);

  useEffect(() => {
    setSize();
  }, [containerSize.x, containerSize.y]);

  return (
    <>
      {videoData.videoID && videoData.videoID != 0 && (
        <>
          <div
            style={{
              padding: dontCropVideo
                ? (videoData.aspectRatioX / videoData.aspectRatioY) * 50 +
                  "% 0 " +
                  (videoData.aspectRatioX / videoData.aspectRatioY) * 50 +
                  "% 0 0"
                : (y / x) * 100 + "% 0 0 0",
              /* 
              padding:
                (videoData.aspectRatioY / videoData.aspectRatioX) * 100 +
                "% 0 0 0",
                */
              position: "relative",
              width: dontCropVideo ? containerSize.x + "px" : x + "px",
              height: dontCropVideo
                ? containerSize.x *
                    (videoData.aspectRatioY / videoData.aspectRatioX) +
                  "px"
                : y + "px",
              pointerEvents: "none",
            }}
          >
            {typeof hideBecauseVideoLightboxIsShowing === "undefined" && (
              <iframe
                key={keyStr}
                ref={iframeRef}
                title="video"
                src={`https://player.vimeo.com/video/${videoData.videoID}?controls=false&loop=true&muted=true&autopause=0`}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                className="absolute w-full h-full top-0 left-0"
                style={{
                  left:
                    typeof videoData.hFocus !== "undefined"
                      ? (videoData.hFocus / 100) * (containerSize.x - x) + "px"
                      : (containerSize.x - x) / 2 + "px",
                  top:
                    typeof videoData.vFocus !== "undefined"
                      ? (videoData.vFocus / 100) * (containerSize.y - y) + "px"
                      : (containerSize.y - y) / 2 + "px",
                }}
              ></iframe>
            )}
          </div>
        </>
      )}
    </>
  );
}
