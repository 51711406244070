/** @jsx jsx */

import { useState } from "react";
import {
  FullWidthInput,
  TextArea,
  ControllerSection,
  COLORS,
} from "../../style/styledComponents";
import { cloneDeep } from "lodash-es";
import { css, jsx } from "@emotion/react";
import XOptionsRadioButtons from "../PAGEBUILDER_UTILITY_COMPONENTS/XOptionsRadioButtons";
import ColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/ColorOptions";
import BlockExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockExpandableBar";
import ImageSelector from "../PAGEBUILDER_UTILITY_COMPONENTS/ImageSelector";
import CategoryExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/CategoryExpandableBar";
import { BLOCKNAMES, BLOCKS } from "../../blocks";
import BlockColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockColorOptions";
import VerticalPaddingSliders from "../PAGEBUILDER_UTILITY_COMPONENTS/VerticalPaddingSliders";

interface Props {
  content: any;
  index: number;
  handleBlockChange: Function;
  disabledStyle: string;
}

export const allBlogPostsBlockInit = {
  title: "Inspiratie nodig?",
  subTitle: "Lees hier onze laatste blogs!",
  linkText1: "Lees meer...",
  contentWidth: 0,
  textAlign: 0,
  amtOfPosts: 3,
  textMarginY: 30,
  titleColor: 0,
  textColor: 0,
  bgColor: 0,
  vPaddingTop: 30,
  vPaddingBottom: 30,
};

export const AllBlogPostsBlockControl = ({
  content,
  index,
  handleBlockChange,
  disabledStyle,
}: Props) => {
  const handleValueChange = (key: string, value: string | boolean | number) => {
    const newContent = cloneDeep(content);
    newContent[key] = value;
    handleBlockChange({ ...newContent }, index);
  };

  return (
    <>
      <CategoryExpandableBar title="TEXT" index={index}>
        <ControllerSection>
          <label>Title text</label>
          <FullWidthInput
            type="text"
            value={content.title}
            onChange={(e) => handleValueChange("title", e.target.value)}
          />
        </ControllerSection>
        <ControllerSection>
          <label>Subtitle text</label>
          <FullWidthInput
            type="text"
            value={content.subTitle}
            onChange={(e) => handleValueChange("subTitle", e.target.value)}
          />
        </ControllerSection>
        <ControllerSection>
          <label>Link text 1</label>
          <FullWidthInput
            type="text"
            value={content.linkText1}
            onChange={(e) => handleValueChange("linkText1", e.target.value)}
          />
        </ControllerSection>

        <ControllerSection>
          <span>Text Align</span>
          <XOptionsRadioButtons
            propName="textAlign"
            currentVal={content.textAlign}
            handleChange={handleValueChange}
            index={index}
            labels={["left", "center", "right"]}
          />
        </ControllerSection>
      </CategoryExpandableBar>
      <CategoryExpandableBar title="BLOCK OPTIONS" index={index}>
        <ControllerSection>
          <span>Content Width:</span>
          <XOptionsRadioButtons
            propName="contentWidth"
            currentVal={content.contentWidth}
            handleChange={handleValueChange}
            index={index}
            labels={["Full-width", "Extra padding"]}
          />
        </ControllerSection>
        <ControllerSection>
          <VerticalPaddingSliders
            vPaddingBottom={content.vPaddingBottom}
            vPaddingTop={content.vPaddingTop}
            handleValueChange={handleValueChange}
          />
        </ControllerSection>
      </CategoryExpandableBar>
      <BlockColorOptions
        titleColor={content.titleColor}
        bgColor={content.bgColor}
        textColor={content.textColor}
        handleValueChange={handleValueChange}
      />
    </>
  );
};
export default AllBlogPostsBlockControl;
