/** @jsx jsx */

import React, { useState, useEffect, useRef } from "react";

import { css, jsx } from "@emotion/react";

import HighlightBlock from "./HighlightBlock";

import {
  colors,
  getXPaddingAmtFromNumber,
  maxContentWidth,
} from "../../styles/style";

import { fillInMissingBlockDataWithDefaults } from "../../cms/functions";
import { heroBlockInit } from "../../cms/CMSComponents/CONTROLLER_COMPONENTS/HeroBlockControl";
import VimeoVideoComponent from "../VimeoVideoComponent";
import useCmsProofBreakpoints from "../../hooks/useCmsProofBreakpoints";
import ImageComponent from "../ImageComponent";
import H1H3Switch from "../H1H3Switch";
import H2H4Switch from "../H2H4Switch";

export type HeroBlockProps = {
  title: string;
  subtitle: string;
  text: string;
  textAlign: number;
  textMarginX: number;
  textMarginY: number;
  textPositionX: number;
  textPositionY: number;
  imageData: any;
  hFocus: number;
  vFocus: number;
  textColor: number;
  bgColor: number;
  height: number;
};

type Props = {
  blockContent: string;
  index: number;
  highlighted: boolean;
  pageTitleColor: number;
  breakPoint: number;
  setShowVideoLightbox: Function;
  showVideoLightbox: any;
};

function HeroVideoBlock({
  blockContent,
  index,
  highlighted,
  pageTitleColor,
  breakPoint,
  setShowVideoLightbox,
  showVideoLightbox,
}: Props) {
  const sizeRef = useRef();
  const updateRef = useRef(true);
  const [update, setUpdate] = useState(true);
  const [scrolled, setScrolled] = useState(true);
  const scrolledRef = useRef(false);
  const [loaded, setLoaded] = useState(false);
  const DATA = fillInMissingBlockDataWithDefaults(
    JSON.parse(blockContent),
    heroBlockInit
  );

  let xPaddingAmt = getXPaddingAmtFromNumber(1, breakPoint);
  function handleScroll() {
    if (!scrolledRef.current) {
      scrolledRef.current = true;
      setScrolled(true);
    }
  }

  let heightClass = "";
  let gatsbyBreakPoints = useCmsProofBreakpoints();

  switch (DATA.height) {
    case 0:
      heightClass = "16.6vh";
      break;
    case 1:
      heightClass = "25vh";
      break;
    case 2:
      heightClass = "33vh";
      break;
    case 3:
      heightClass = "50vh";
      break;
    case 4:
      heightClass = "75vh";
      break;
    case 5:
      heightClass = "100vh";
      break;
  }

  if (gatsbyBreakPoints.sm) {
    heightClass = "75vh";
  }

  function scrollFunc() {
    if (typeof window !== "undefined") {
      const elem = document.getElementById("contactForm");
      // console.log(elem);
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }

  useEffect(() => {
    setScrolled(false);
    const updateFunc = () => {
      updateRef.current = !updateRef.current;
      setUpdate(updateRef.current);
    };

    if (typeof window !== "undefined") {
      if (document.readyState === "complete") {
        setLoaded(true);
      }
      window.addEventListener("load", () => setLoaded(true));
      window.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", updateFunc);
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("load", () => setLoaded(true));
        window.removeEventListener("resize", updateFunc);
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  return (
    <section
      id={"container" + index}
      className={`relative  w-screen transition-colors sm:h-auto  `}
      style={{
        //backgroundColor: colors[DATA.bgColor],
        height: heightClass ? heightClass : "50vh",
        minHeight: "400px",
      }}
    >
      {DATA.video.videoID && (
        <>
          <div
            ref={sizeRef}
            id={"container" + index}
            className={`absolute top-0 left-0 w-full h-full  overflow-hidden z-0`}
          >
            {!showVideoLightbox && (
              <VimeoVideoComponent
                videoData={{
                  ...DATA.video,
                  vFocus: DATA.loadingImg?.vFocus,
                  hFocus: DATA.loadingImg?.hFocus,
                }}
                containerSize={{
                  x: sizeRef.current?.clientWidth,
                  y: sizeRef.current?.clientHeight,
                }}
                playing={true}
                setLoaded={setLoaded}
                keyStr={"heroVideo" + index}
              />
            )}
          </div>
          <div
            className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 overflow-hidden z-10 delay-1000`}
            style={{
              opacity: loaded ? 0 : 1,
              backgroundColor: colors[DATA.bgColor],
            }}
          >
            {DATA.loadingImg && (
              <ImageComponent
                imageData={DATA.loadingImg}
                crop={true}
                noPlugins
              />
            )}
          </div>
        </>
      )}
      {(DATA.title2 || DATA.subtitle2) && (
        <div
          className="absolute top-1/2 transform -translate-y-1/2 w-screen left-1/2 -translate-x-1/2 z-50 flex flex-col justify-center"
          style={{
            padding: `0 ${xPaddingAmt}px`,
            maxWidth: `${maxContentWidth}px`,
          }}
        >
          <H1H3Switch
            isH1={DATA.isMainTitle}
            className="text-3xl md:text-5xl lg:text-6xl xl:text-bigTitle font-title -ml-0.75 sm:-ml-0.5 lg:-ml-1 font-normal"
            style={{
              color:
                DATA.titleColor === 0
                  ? colors[pageTitleColor]
                  : colors[DATA.titleColor],
            }}
            dangerouslySetInnerHTML={{ __html: DATA.title2 }}
          ></H1H3Switch>
          <H2H4Switch
            isH2={DATA.isMainTitle}
            className="text-2xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-4xl font-title sm:-ml-0.5 font-normal max-w-5xl"
            style={{
              color:
                DATA.titleColor === 0
                  ? colors[pageTitleColor]
                  : colors[DATA.titleColor],
            }}
            dangerouslySetInnerHTML={{ __html: DATA.subtitle2 }}
          ></H2H4Switch>
          {DATA.buttonText && (
            <button
              onClick={scrollFunc}
              style={{ backgroundColor: colors[DATA.bgColor] }}
              className="sm:ml-0 mx-auto rounded-full w-4/5 sm:w-auto py-2 px-8 sm:px-10 mt-12 text-md sm:text-lg lg:text-xl"
            >
              {DATA.buttonText}
            </button>
          )}
        </div>
      )}
      {DATA.displayScrollIcon && (
        <div
          className="scroll-downs sm:block hidden z-20"
          style={{ opacity: scrolled ? 0 : 1 }}
        >
          <div className="mousey">
            <div className="scroller"></div>
          </div>
        </div>
      )}
      {highlighted && <HighlightBlock />}
    </section>
  );
}

export default HeroVideoBlock;
