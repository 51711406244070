/** @jsx jsx */

import { useEffect, useState, useRef } from "react";
import { css, jsx } from "@emotion/react";

import HighlightBlock from "./HighlightBlock";
import { Link } from "gatsby";
import {
  Button,
  colors,
  getXPaddingAmtFromNumber,
  vPaddingMultiplier,
} from "../../styles/style";

import { fillInMissingBlockDataWithDefaults } from "../../cms/functions";
import { useParams } from "react-router-dom";
import PortfolioPreview from "../PortfolioPreview";
import { werkLightPreviewBlockInit } from "../../cms/CMSComponents/CONTROLLER_COMPONENTS/WerkLightPreviewBlockControl";
import TitleColorAccents from "../TitleColorAccents";
import DecorationCircle from "../DecorationCircle";
import ColorAccentsText from "../ColorAccentsText";

type Props = {
  blockContent: string;
  index: number;
  highlighted: boolean;
  pageTitleColor: number;
  breakPoint: number;
  setOverrideBackgroundColor: Function;
  previewMode?: boolean;
};

function WerkLightPreviewBlock({
  blockContent,
  index,
  highlighted,
  pageTitleColor,
  breakPoint,
  setOverrideBackgroundColor,
  previewMode,
}: Props) {
  // const [showLight, setShowLight] = useState(false);
  const showLightRef = useRef(false);
  const [content, setContent] = useState([]);
  const canTriggerTransition = useRef(false);

  const transitionDelay = 0;
  const DATA = fillInMissingBlockDataWithDefaults(
    JSON.parse(blockContent),
    werkLightPreviewBlockInit
  );

  let textSizeClass = "";
  if (DATA.textSize === 1) textSizeClass = "text-lg";
  if (DATA.textSize === 2) textSizeClass = "text-xl";

  // if (typeof window !== "undefined" && canTriggerTransition.current) {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const light = urlParams.get("light");
  //   if (light === "true" && !showLightRef.current) {
  //     startTransition(true);
  //     showLightRef.current = true;
  //   }
  //   if (light === "false" && showLightRef.current) {
  //     startTransition(false);
  //     showLightRef.current = false;
  //   }
  // }

  useEffect(() => {
    setContent(DATA.werkRows);
  }, [blockContent]);

  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     canTriggerTransition.current = true;
  //     const urlParams = new URLSearchParams(window.location.search);
  //     const light = urlParams.get("light");

  //     if (light === "true") {
  //       setShowLight(true);
  //       showLightRef.current = true;
  //       setContent(DATA.lightRows);
  //     }
  //     if (light === "false") {
  //       setShowLight(false);
  //       showLightRef.current = false;
  //       setContent(DATA.werkRows);
  //     }
  //   }
  // }, []);

  // function startTransition(isLight: boolean) {
  //   if (typeof window !== "undefined") {
  //     if (isLight) {
  //       window.history.replaceState(null, "", "?light=true");
  //       setShowLight(true);
  //       showLightRef.current = true;
  //       setOverrideBackgroundColor(true);
  //       setContent([]);
  //       setTimeout(() => {
  //         setContent(DATA.lightRows);
  //       }, transitionDelay);
  //     } else {
  //       window.history.replaceState(null, "", "?light=false");
  //       setShowLight(false);
  //       showLightRef.current = false;
  //       setOverrideBackgroundColor(false);
  //       setContent([]);
  //       setTimeout(() => {
  //         setContent(DATA.werkRows);
  //       }, transitionDelay);
  //     }
  //   }
  // }

  let xPaddingAmt = getXPaddingAmtFromNumber(DATA.contentWidth, breakPoint);

  return (
    <section
      id={"container" + index}
      className={`relative flex flex-col items-center w-screen transition-colors duration-700 justify-start z-0`}
      style={{
        minHeight: "100vh",
        backgroundColor: "none",
        padding: `${DATA.vPaddingTop * vPaddingMultiplier}px ${xPaddingAmt}px ${
          DATA.vPaddingBottom * vPaddingMultiplier
        }px`,
        //color: showLight ? colors[2] : colors[1],
        color: colors[1],
      }}
    >
      <div style={{ maxWidth: "800px" }}>
        <div className="z-0">
          <h1 className="relative">
            {DATA.displayCircle && (
              <DecorationCircle
                bgColor={
                  DATA.titleColor === 0 ? pageTitleColor : DATA.titleColor
                }
                maxMovement={-60}
                growthSize={7.5}
                offsetX={72}
                offsetY={35}
                growthSizeMobile={4}
                offsetXMobile={72}
                offsetYMobile={20}
                noAnimation
              />
            )}
            <ColorAccentsText
              text={DATA.title}
              textColor={DATA.textColor}
              accentColor={DATA.titleColor}
            />
          </h1>

          <div
            className={`mt-8 text-l`}
            style={{
              color: colors[DATA.textColor],
            }}
          >
            <ColorAccentsText
              text={DATA.richText}
              textColor={DATA.textColor}
              accentColor={DATA.titleColor}
            />
          </div>
        </div>
        {/* <b
        style={{ color: colors[3] }}
        className="m-auto text-center block mt-10 text-xl"
      >
        Kies een categorie:
      </b> */}
        {/* <div
          className="flex sm:w-96 w-full m-auto justify-between mt-12 "
          style={{ marginBottom: "10px" }}
        >
          <Button
            selected={!showLight}
            text="Werk"
            onClick={() => {
              startTransition(false);
            }}
          />

          <Button
            selected={showLight}
            text="Light"
            onClick={() => {
              startTransition(true);
            }}
          />
        </div> */}
      </div>
      <PortfolioPreview
        rows={content}
        breakPoint={breakPoint}
        previewMode={previewMode}
      />

      {highlighted && <HighlightBlock />}
    </section>
  );
}

export default WerkLightPreviewBlock;
