export const colors = ["inherit", "#111111", "#FFFFFF", "#3bd177"];

export const clipLeft = [
  "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
  "polygon(20% 0, 100% 0, 100% 100%, 0% 100%)",
  "polygon(0 0, 100% 0, 100% 100%, 20% 100%)",
  " circle(75% at 75% 50%)",
  "polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 18% 50%, 0% 0%)",
  "polygon(100% 0%, 100% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%)",
];

export const clipRight = [
  "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
  "polygon(0 0, 80% 0, 100% 100%, 0% 100%)",
  "polygon(0 0, 100% 0, 80% 100%, 0% 100%)",
  "circle(75% at 25% 50%)",
  "polygon(100% 0%, 82% 50%, 100% 100%, 0 100%, 0% 50%, 0 0)",
  "polygon(82% 0, 100% 50%, 82% 100%, 0% 100%, 0 49%, 0% 0%)",
];

export const xPaddingAmtMobile = 25;
export const xPaddingAmtSmall = 30;
export const xPaddingAmtLarge = 180;
export const imgPaddingAmt = 4;
export const vPaddingMultiplier = 3;
export const maxContentWidth = 1800;

export function getXPaddingAmtFromNumber(num: number, breakPoint: number) {
  if (num === 1) {
    if (breakPoint === -1) {
      return xPaddingAmtLarge;
    }
    if (breakPoint !== 0) {
      return xPaddingAmtSmall;
    } else {
      return xPaddingAmtMobile;
    }
  } else {
    if (breakPoint !== 0) {
      return xPaddingAmtSmall;
    } else {
      return xPaddingAmtMobile;
    }
  }
}

export function getTextAlignFromNumber(num: number) {
  let textAlignClass = "text-left";
  if (num === 1) textAlignClass = "text-center";
  if (num === 2) textAlignClass = "text-right";
  return textAlignClass;
}

export function getRowHeightFromNumber(num: number) {
  let height = portfolioRowHeight;
  if (num === 1) height = portfolioRowHeightLarge;
  return height;
}

export const BREAKPOINTS = [768, 1000, 1400];
export const portfolioRowHeight = 350;
export const portfolioRowHeightLarge = 500;

export const portfolioGapSize = 10;

type buttonProps = {
  selected: boolean;
  text: string;
  [x: string]: any;
};

export function Button({ selected, text, size, ...rest }: buttonProps) {
  return (
    <button
      style={{
        color: selected ? colors[2] : colors[3],
        backgroundColor: selected ? colors[3] : "rgba(0,0,0,0)",
        borderColor: colors[3],
      }}
      className="py-2 px-10 border text-center m-auto rounded-full hoverScaleXL"
      {...rest}
    >
      {text}
    </button>
  );
}

type buttonCTAProps = {
  selected: boolean;
  text: string;
  [x: string]: any;
  color1: number;
  color2: number;
  size: number;
};
export function ButtonCTA({
  selected,
  text,
  color1,
  color2,
  size,
  ...rest
}: buttonCTAProps) {
  return (
    <button
      style={{
        color: selected ? colors[color1] : colors[color2],
        backgroundColor: selected ? colors[color2] : colors[color1],
        borderColor: colors[color2],
      }}
      className={`${size === 0 ? "py-2" : "py-0"} ${
        size === 0 ? "px-12" : "px-6"
      } ${
        size === 0 ? "border-2" : "border"
      } text-center m-auto rounded-full hoverScaleXL`}
      {...rest}
    >
      {text}
    </button>
  );
}
