/** @jsx jsx */

import { useState } from "react";
import { css, jsx } from "@emotion/react";
import ImageComponent from "../ImageComponent";
import HighlightBlock from "./HighlightBlock";

import {
  colors,
  getTextAlignFromNumber,
  getXPaddingAmtFromNumber,
  maxContentWidth,
  portfolioRowHeight,
  vPaddingMultiplier,
} from "../../styles/style";
import { fillInMissingBlockDataWithDefaults } from "../../cms/functions";
import { VideoGalleryInit } from "../../cms/CMSComponents/CONTROLLER_COMPONENTS/VideoGalleryControl";
import { PortfolioPreviewItem } from "../PortfolioPreview";
import { sortPortfolioByDate } from "../../functions";

import useScrollInViewAnimation from "../../hooks/UseScrollInViewAnimation";
import ANIMATIONS from "../../styles/animations";
import { motion } from "framer-motion";
import TitleColorAccents from "../TitleColorAccents";
import ColorAccentsText from "../ColorAccentsText";

type Props = {
  blockContent: string;
  pagePortfolioOptions: string | null;
  index: number;
  highlighted: boolean;
  pageTitleColor: number;
  breakPoint: number;
  setShowVideoLightbox: Function;
  werkPages: any[];
  fictiePages: any[];
  lightPages: any[];
};

function LatestWorkBlock({
  blockContent,
  index,
  highlighted,
  pageTitleColor,
  breakPoint,
  setShowVideoLightbox,
  pagePortfolioOptions,
  werkPages,
  fictiePages,
  lightPages,
}: Props) {
  const DATA = fillInMissingBlockDataWithDefaults(
    JSON.parse(blockContent),
    VideoGalleryInit
  );
  const newWerkPages = [...werkPages];
  const newFictiePages = [...fictiePages];
  const newLightPAges = [...lightPages];

  newWerkPages.forEach((page) => (page.categorySlug = "werk"));
  newFictiePages.forEach((page) => (page.categorySlug = "fictie"));
  newLightPAges.forEach((page) => (page.categorySlug = "light"));

  const [refTitle, controlsTitle] = useScrollInViewAnimation();
  const [refImg, controlsImg] = useScrollInViewAnimation();

  let textAlignClass = getTextAlignFromNumber(DATA.titlePosition);
  let xPaddingAmt = getXPaddingAmtFromNumber(DATA.contentWidth, breakPoint);

  let filteredPages: any[] = [];

  if (DATA.displayWerk) {
    filteredPages = [...filteredPages, ...newWerkPages];
  }
  if (DATA.displayLight) {
    filteredPages.push(...newLightPAges);
  }
  if (DATA.displayFictie) {
    filteredPages = [...filteredPages, ...newFictiePages];
  }
  if (!DATA.displayWerk && !DATA.displayFictie && !DATA.displayLight) {
    filteredPages = [...newWerkPages, ...newLightPAges, ...newFictiePages];
  }

  if (pagePortfolioOptions) {
    let title = JSON.parse(pagePortfolioOptions).previewTitle;
    filteredPages = filteredPages.filter((page: any, index) => {
      if (page.portfolioOptions) {
        let pagePortfolioOptions = page.portfolioOptions;
        if (typeof pagePortfolioOptions === "string")
          pagePortfolioOptions = JSON.parse(pagePortfolioOptions);
        return pagePortfolioOptions.previewTitle !== title;
      }
      return false;
    });
  }

  filteredPages = filteredPages.filter((page: any) => page.portfolioOptions);

  filteredPages.forEach((page: any) => {
    if (page.portfolioOptions && typeof page.portfolioOptions === "string")
      page.portfolioOptions = JSON.parse(page.portfolioOptions);
  });

  filteredPages = filteredPages.filter(
    (page: any) => page.portfolioOptions.showInPreviews
  );

  filteredPages = sortPortfolioByDate(filteredPages);
  // console.log(filteredPages);

  return (
    <section
      id={"container" + index}
      className={`relative  w-screen transition-colors`}
      style={{
        backgroundColor: colors[DATA.bgColor],
        padding: `${DATA.vPaddingTop * vPaddingMultiplier}px ${xPaddingAmt}px ${
          DATA.vPaddingBottom * vPaddingMultiplier
        }px`,
      }}
    >
      <motion.div
        ref={refTitle}
        initial="hidden"
        animate={controlsTitle}
        variants={ANIMATIONS.fadeScale}
        className="flex flex-col items-center"
      >
        <h3 className="text-4xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl font-title -ml-0.75 sm:-ml-0.5 lg:-ml-1 font-normal">
          <ColorAccentsText
            center
            textColor={DATA.textColor}
            accentColor={
              DATA.titleColor === 0 ? pageTitleColor : DATA.titleColor
            }
            text={DATA.title}
          />
        </h3>
        <h4 className="text-2xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-4xl font-title sm:-ml-0.5 font-normal">
          <ColorAccentsText
            center
            textColor={DATA.textColor}
            accentColor={
              DATA.titleColor === 0 ? pageTitleColor : DATA.titleColor
            }
            text={DATA.subtitle}
          />
        </h4>
      </motion.div>
      <motion.div
        ref={refImg}
        initial="hidden"
        animate={controlsImg}
        variants={ANIMATIONS.staggerContainerDelay}
        className="md:flex w-full justify-between mx-auto"
        style={{ maxWidth: maxContentWidth + "px" }}
      >
        {filteredPages.map((page: any, itemIndex) => {
          if (itemIndex < 3)
            return (
              <div
                className="md:w-1/3 w-full md:mx-2 h-full mt-8"
                key={itemIndex}
                style={{ height: portfolioRowHeight + "px" }}
              >
                <PortfolioPreviewItem
                  title={page.portfolioOptions.previewTitle}
                  subtitle={page.portfolioOptions.previewSubtitle}
                  previewImage={page.portfolioOptions.previewImage}
                  previewVideo={page.portfolioOptions.previewVideo}
                  slug={"/" + page.categorySlug + "/" + page.slug}
                  setShowVideoLightbox={() => {}}
                  textColor={0}
                  index={itemIndex}
                  rowIndex={0}
                  blockIndex={index}
                />
              </div>
            );
        })}
      </motion.div>
      {DATA.imageData && (
        <div
          className={`absolute top-0 left-0 w-full h-full  overflow-hidden`}
          style={{ zIndex: -10 }}
        >
          <ImageComponent imageData={DATA.imageData} crop={true} />
        </div>
      )}

      {highlighted && <HighlightBlock />}
    </section>
  );
}

export default LatestWorkBlock;
