/** @jsx jsx */
import React, { Component, useState, useRef } from "react";
import {
  Input,
  Button,
  Container,
  ExplanationP,
  ControllerSection,
  SmallMargin,
  LargeMargin,
  COLORS,
  SHADOWS,
} from "../../style/styledComponents";
import { jsx, css } from "@emotion/react";
import { cloneDeep } from "lodash-es";
import IoIosArrowDown from "../../../images/icons/angle-down-solid";
import IoIosArrowUp from "../../../images/icons/angle-up-solid";
import AiOutlineClose from "../../../images/icons/xmark-solid";
import AiOutlineArrowUp from "../../../images/icons/arrow-up-solid";
import AiOutlineArrowDown from "../../../images/icons/arrow-down-solid";
import Expand from "react-expand-animated";

import ImageSelector, { imageData } from "./ImageSelector";
import { colors } from "../../../styles/style";
import VideoSelector, { videoData } from "./VideoSelector";

type Props = {
  label: string;
  itemName: string;
  itemType: any;
  initItem: any;
  handleValueChange: Function;
  propName: string;
  currentValue: any[];
};

export default function ControlledArrayItems({
  label,
  itemType,
  itemName,
  handleValueChange,
  currentValue,
  propName,
  initItem,
}: Props) {
  function handleAddItem() {
    const newArr = cloneDeep(currentValue);
    newArr.push({ ...initItem });
    handleValueChange(propName, [...newArr]);
  }
  function handleMoveItem(index: number, amt: number) {
    if (
      !(
        (index === 0 && amt === -1) ||
        (index === currentValue.length - 1 && amt === 1)
      )
    ) {
      const newArr = cloneDeep(currentValue);
      const movedItem = newArr.splice(index, 1);
      newArr.splice(index + amt, 0, movedItem[0]);
      handleValueChange(propName, [...newArr]);
    }
  }
  function handleDeleteItem(deleteIndex: number) {
    const newArr = cloneDeep(currentValue);
    newArr.splice(deleteIndex, 1);
    handleValueChange(propName, [...newArr]);
  }
  function handleItemUpdate(value: any, index: number) {
    const newArr = cloneDeep(currentValue);
    newArr[index] = value;
    handleValueChange(propName, newArr);
  }

  return (
    <>
      <label
        css={css`
          display: block;
        `}
      >
        <b>{label}</b>
      </label>
      <Button bgColor={COLORS.variation3} onClick={handleAddItem}>
        Add {itemName}
      </Button>
      <SmallMargin />
      <ControllerSection>
        {currentValue.map((item: any, index) => {
          return (
            <ArrayItem
              index={index}
              itemType={itemType}
              itemValue={item}
              amtOfItems={currentValue.length}
              handleItemUpdate={handleItemUpdate}
              handleDeleteItem={handleDeleteItem}
              handleMoveItem={handleMoveItem}
              initItem={initItem}
            />
          );
        })}
      </ControllerSection>
    </>
  );
}

type ArrayItemProps = {
  itemType: any;
  initItem: any;
  itemValue: any;
  index: number;
  amtOfItems: number;
  handleItemUpdate: Function;
  handleDeleteItem: Function;
  handleMoveItem: Function;
};

function ArrayItem({
  itemType,
  itemValue,
  amtOfItems,
  index,
  initItem,
  handleItemUpdate,
  handleDeleteItem,
  handleMoveItem,
}: ArrayItemProps) {
  const transitions = ["height", "opacity", "background"];
  const [exp, setExp] = useState(false);

  function handleValueChange(key: string, value: any) {
    const newItemValue = { ...itemValue };
    newItemValue[key] = value;
    handleItemUpdate(newItemValue, index);
  }

  const labelKey =
    Object.keys(itemValue)[
      Object.keys(itemValue).findIndex((key) => itemType[key].type === "text")
    ];

  const labelFromState = labelKey ? itemValue[labelKey] : "Item #" + index;

  return (
    <div>
      <div
        css={css`
          background-color: ${COLORS.variation6};
          box-shadow: ${SHADOWS.medium};
          //border: 1px solid black;
          border-radius: 8px;
          margin-bottom: 8px;
          margin-top: 6px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 5px 10px;
        `}
      >
        <div
          css={css`
            display: flex;
            width: 50px;
          `}
        >
          {exp ? (
            <IoIosArrowUp
              css={css`
                height: 16px;
                width: 16px;
                transition: transform 0.3s;
                align-self: center;
                &:hover {
                  cursor: pointer;
                  transform: scale(1.2);
                }
              `}
              onClick={() => setExp(false)}
            />
          ) : (
            <IoIosArrowDown
              css={css`
                height: 16px;
                width: 16px;
                transition: transform 0.3s;
                align-self: center;
                &:hover {
                  cursor: pointer;
                  transform: scale(1.2);
                }
              `}
              onClick={() => setExp(true)}
            />
          )}
        </div>
        <span
          css={css`
            display: block;
          `}
        >
          {labelFromState}
        </span>
        <div
          css={css`
            display: flex;
            width: 80px;
            justify-content: space-between;
          `}
        >
          <div
            css={css`
              display: flex;
              width: 50px;
              justify-content: space-between;
              margin-right: 30px;
              align-self: center;
            `}
          >
            <AiOutlineArrowDown
              onClick={() => handleMoveItem(index, 1)}
              css={css`
                width: 20px;
                height: 20px;
                opacity: ${index === amtOfItems - 1 ? 0.5 : 1};
                &:hover {
                  cursor: ${index === amtOfItems - 1 ? "auto" : "pointer"};
                }
              `}
            />
            <AiOutlineArrowUp
              onClick={() => handleMoveItem(index, -1)}
              css={css`
                width: 20px;
                height: 20px;
                opacity: ${index === 0 ? 0.5 : 1};
                &:hover {
                  cursor: ${index === 0 ? "auto" : "pointer"};
                }
              `}
            />
          </div>
          <AiOutlineClose
            css={css`
              height: 16px;
              width: 16px;
              transition: transform 0.3s;
              align-self: center;
              &:hover {
                cursor: pointer;
                transform: scale(1.2);
              }
            `}
            onClick={() => handleDeleteItem(index)}
          />
        </div>
      </div>
      <Expand open={exp} duration={200} transitions={transitions}>
        <div
          css={css`
            width: 100%;
            padding: 4px 10px 4px 0;
          `}
        >
          {Object.keys(itemType).map((key) => {
            switch (itemType[key].type) {
              case "image":
                return (
                  <ControllerSection>
                    <ImageSelector
                      handleValueChange={handleValueChange}
                      currentValue={itemValue[key]}
                      label={itemType[key].label}
                      propName={key}
                      croppedByDefault
                    />
                  </ControllerSection>
                );
              case "text":
                return (
                  <ControllerSection>
                    <label>{itemType[key].label}</label>
                    <Input
                      type="text"
                      value={itemValue[key]}
                      onChange={(e) => handleValueChange(key, e.target.value)}
                    />
                  </ControllerSection>
                );
              case "video":
                return (
                  <ControllerSection>
                    <label>{itemType[key].label}</label>
                    <VideoSelector
                      currentValue={
                        itemValue[key] ? itemValue[key] : initItem.video
                      }
                      propName={key}
                      handleValueChange={handleValueChange}
                    />
                  </ControllerSection>
                );
            }
          })}
        </div>
      </Expand>
    </div>
  );
}
