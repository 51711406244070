const DURATION_MOD = 1;

const ANIMATIONS = {
  fadeFromRight: {
    hidden: { opacity: 0, x: 200 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1 * DURATION_MOD,
      },
    },
  },
  fadeFromLeft: {
    hidden: { opacity: 0, x: -200 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1 * DURATION_MOD,
      },
    },
  },
  fadeScale: {
    hidden: { opacity: 0, scale: 0.8, y: 50 },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        duration: 1 * DURATION_MOD,
        ease: "easeOut",
      },
    },
  },
  fadeScaleSubtle: {
    hidden: { opacity: 0, y: 20, scale: 0.9 },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        duration: 1 * DURATION_MOD,
        ease: "easeOut",
      },
    },
  },
  staggerContainer: {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: DURATION_MOD * 0.7,
        staggerChildren: 0.3,
      },
    },
  },
  staggerContainerFast: {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15,
      },
    },
  },
  staggerContainerDelay: {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 1 * DURATION_MOD,
        staggerChildren: 0.3,
        delayChildren: 0.3,
      },
    },
  },
  fade: {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    transition: { duration: 1 * DURATION_MOD },
  },
  fadeDelay: {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    transition: { duration: 1 * DURATION_MOD },
  },
};

export default ANIMATIONS;
