/** @jsx jsx */

import { useState } from "react";
import {
  FullWidthInput,
  TextArea,
  ControllerSection,
  COLORS,
  ExplanationP,
  SmallMargin,
} from "../../style/styledComponents";
import { cloneDeep } from "lodash-es";
import { css, jsx } from "@emotion/react";
import XOptionsRadioButtons from "../PAGEBUILDER_UTILITY_COMPONENTS/XOptionsRadioButtons";
import ColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/ColorOptions";
import BlockExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockExpandableBar";
import ImageSelector from "../PAGEBUILDER_UTILITY_COMPONENTS/ImageSelector";
import CategoryExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/CategoryExpandableBar";
import { BLOCKNAMES, BLOCKS } from "../../blocks";
import BlockColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockColorOptions";
import RichTextEditor from "../PAGEBUILDER_UTILITY_COMPONENTS/RichTextEditor";
import VerticalPaddingSliders from "../PAGEBUILDER_UTILITY_COMPONENTS/VerticalPaddingSliders";
import VideoSelector from "../PAGEBUILDER_UTILITY_COMPONENTS/VideoSelector";
import HoverHelp from "../PAGEBUILDER_UTILITY_COMPONENTS/HoverHelp";
import youtubeHint from "../../img/youtubeHint.jpg";

interface Props {
  content: any;
  index: number;
  handleBlockChange: Function;
  disabledStyle: string;
}

export const imageVideoBlockInit = {
  captionText: "",
  imageSize: 0,
  imagePosition: 0,
  imageData: { imageID: "", crop: true, vFocus: 50, hFocus: 50 },
  videoData: { videoID: "", aspectRatioX: 16, aspectRatioY: 9 },
  youtubeData: "",
  hFocus: 50,
  vFocus: 50,
  titleColor: 0,
  textColor: 0,
  bgColor: 0,
  vPaddingTop: 30,
  vPaddingBottom: 30,
  contentWidth: 0,
};

export const ImageVideoBlockControl = ({
  content,
  index,
  handleBlockChange,
  disabledStyle,
}: Props) => {
  const handleValueChange = (key: string, value: string | boolean | number) => {
    const newContent = cloneDeep(content);
    newContent[key] = value;
    handleBlockChange({ ...newContent }, index);
  };

  return (
    <>
      <CategoryExpandableBar title="TEXT" index={index}>
        <ControllerSection>
          <label>Image Caption</label>
          <ExplanationP>
            Position of this text is dictated by the 'Image Position' option in
            the 'Image' tab
          </ExplanationP>
          <FullWidthInput
            type="text"
            value={content.captionText}
            onChange={(e) => handleValueChange("captionText", e.target.value)}
          />
        </ControllerSection>
      </CategoryExpandableBar>
      <CategoryExpandableBar title="IMAGE" index={index}>
        <ControllerSection>
          <ImageSelector
            currentValue={content.imageData}
            handleValueChange={handleValueChange}
            label="Image:"
            propName="imageData"
          />
        </ControllerSection>
        <ControllerSection>
          <label>Image Size</label>
          <XOptionsRadioButtons
            index={index}
            propName="imageSize"
            labels={["Full-Width", "Large", "Medium", "Small"]}
            currentVal={content.imageSize}
            handleChange={handleValueChange}
          />
        </ControllerSection>
        <ControllerSection>
          <label>Image Position</label>
          <XOptionsRadioButtons
            index={index}
            propName="imagePosition"
            labels={["Left", "Center", "Right"]}
            currentVal={content.imagePosition}
            handleChange={handleValueChange}
          />
        </ControllerSection>
      </CategoryExpandableBar>
      <CategoryExpandableBar title="VIMEO VIDEO" index={index}>
        <ControllerSection>
          <ExplanationP>
            Adding a vimeo video here will make the image selected in the
            'Image' tab function as the thumbnail for the video.
          </ExplanationP>
          <VideoSelector
            propName="videoData"
            currentValue={content.videoData}
            handleValueChange={handleValueChange}
          ></VideoSelector>
        </ControllerSection>
      </CategoryExpandableBar>
      <CategoryExpandableBar title="YOUTUBE VIDEO">
        <ExplanationP>
          Adding a youtube video here will disable the image and vimeo video.
          The size of the embedded video is still controlled by the image
          controls.
        </ExplanationP>
        <SmallMargin />
        <ControllerSection>
          <label htmlFor="youtubeData">Youtube Video ID</label>
          <HoverHelp title="Where can I find the ID of a youtube video?">
            <div className="w-72">
              <img src={youtubeHint} className="w-full bg-white rounded-md" />
              <ExplanationP>
                Find the video you want to embed on youtube. The video ID is the
                code in the URL that starts after 'watch?=' and ends before the
                next {"'&'"} sign
              </ExplanationP>
            </div>
          </HoverHelp>
          <FullWidthInput
            type="text"
            value={content.youtubeData}
            onChange={(e) => handleValueChange("youtubeData", e.target.value)}
          />
        </ControllerSection>
      </CategoryExpandableBar>
      <CategoryExpandableBar title="BLOCK OPTIONS" index={index}>
        <VerticalPaddingSliders
          vPaddingBottom={content.vPaddingBottom}
          vPaddingTop={content.vPaddingTop}
          handleValueChange={handleValueChange}
        />
        <ControllerSection>
          <span>Content Width:</span>
          <XOptionsRadioButtons
            propName="contentWidth"
            currentVal={content.contentWidth}
            handleChange={handleValueChange}
            index={index}
            labels={["Full-width", "Extra padding"]}
          />
        </ControllerSection>
      </CategoryExpandableBar>
      <BlockColorOptions
        titleColor={content.titleColor}
        bgColor={content.bgColor}
        textColor={content.textColor}
        handleValueChange={handleValueChange}
      />
    </>
  );
};
export default ImageVideoBlockControl;
