/** @jsx jsx */

import { useState } from "react";
import {
  FullWidthInput,
  TextArea,
  ControllerSection,
  COLORS,
  ExplanationP,
} from "../../style/styledComponents";
import { cloneDeep } from "lodash-es";
import { css, jsx } from "@emotion/react";
import XOptionsRadioButtons from "../PAGEBUILDER_UTILITY_COMPONENTS/XOptionsRadioButtons";
import ColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/ColorOptions";
import BlockExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockExpandableBar";
import ImageSelector from "../PAGEBUILDER_UTILITY_COMPONENTS/ImageSelector";
import CategoryExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/CategoryExpandableBar";
import { BLOCKNAMES, BLOCKS } from "../../blocks";
import BlockColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockColorOptions";
import HoverHelp from "../PAGEBUILDER_UTILITY_COMPONENTS/HoverHelp";
import VideoSelector from "../PAGEBUILDER_UTILITY_COMPONENTS/VideoSelector";

interface Props {
  content: any;
  index: number;
  handleBlockChange: Function;
  disabledStyle: string;
}

export const heroBlockInit = {
  title: "Title Text",
  subtitle: "subtitle",
  italicTitle: false,
  video: {
    videoID: "",
    aspectRatioX: 16,
    aspectRatioY: 9,
  },
  textWidth: 6,
  textAlign: 1,
  textPositionX: 1,
  textPositionY: 0,
  textMarginX: 0,
  textMarginY: 0,
  imageData: { imageID: "", crop: true, vFocus: 50, hFocus: 50 },
  titleColor: 0,
  textColor: 0,
  bgColor: 0,
  height: 0,
};

export const HeroBlockControl = ({
  content,
  index,
  handleBlockChange,
  disabledStyle,
}: Props) => {
  const handleValueChange = (key: string, value: string | boolean | number) => {
    const newContent = cloneDeep(content);
    newContent[key] = value;
    handleBlockChange({ ...newContent }, index);
  };

  return (
    <>
      <CategoryExpandableBar title="TEXT" index={index}>
        <ControllerSection>
          <label htmlFor="title">Title</label>
          <FullWidthInput
            name="title"
            id="title"
            type="text"
            value={content.title}
            onChange={(e) => handleValueChange("title", e.target.value)}
          />
        </ControllerSection>

        <ControllerSection>
          <label htmlFor="subtitle">Subtitle</label>
          <FullWidthInput
            name="subtitle"
            id="subtitle"
            type="text"
            value={content.subtitle}
            onChange={(e) => handleValueChange("subtitle", e.target.value)}
          />{" "}
        </ControllerSection>
        <ControllerSection>
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <input
              type="checkbox"
              checked={content.isMainTitle}
              onChange={() => {
                handleValueChange("isMainTitle", !content.isMainTitle);
              }}
            ></input>
            <label style={{ fontWeight: 700 }}>
              This block is the main title of this page (use H1 and H2 instead
              of H3 and H4 elements for the title)
            </label>
          </div>
        </ControllerSection>
        <ControllerSection>
          <input
            type="checkbox"
            checked={content.italicTitle}
            onChange={() => {
              handleValueChange("italicTitle", !content.italicTitle);
            }}
          ></input>
          <label>Italic title</label>
        </ControllerSection>
        <ControllerSection>
          <span>Text Align</span>
          <XOptionsRadioButtons
            propName="textAlign"
            currentVal={content.textAlign}
            handleChange={handleValueChange}
            index={index}
            labels={["left", "center", "right"]}
          />
        </ControllerSection>

        <ControllerSection>
          <span>Horzontal Text Position</span>
          <XOptionsRadioButtons
            propName="textPositionX"
            currentVal={content.textPositionX}
            handleChange={handleValueChange}
            index={index}
            labels={["left", "center", "right"]}
          />
        </ControllerSection>
        <ControllerSection>
          <label
            css={css`
              display: block;
              ${content.textPositionX === 1 ? disabledStyle : ""}
            `}
          >
            Horizontal Margin
          </label>
          <input
            disabled={content.textPositionX === 1}
            type="range"
            min={0}
            max={45}
            step={1}
            value={content.textMarginX}
            onChange={(e) =>
              handleValueChange("textMarginX", parseInt(e.target.value, 10))
            }
          ></input>
        </ControllerSection>

        <ControllerSection>
          <span>Vertical Text Position</span>
          <XOptionsRadioButtons
            propName="textPositionY"
            currentVal={content.textPositionY}
            handleChange={handleValueChange}
            index={index}
            labels={["top", "center", "bottom"]}
          />
        </ControllerSection>
        <ControllerSection>
          <label
            css={css`
              display: block;
              ${content.textPositionY === 1 ? disabledStyle : ""}
            `}
          >
            Vertical Margin
          </label>
          <input
            disabled={content.textPositionY === 1}
            type="range"
            min={0}
            max={45}
            step={1}
            value={content.textMarginY}
            onChange={(e) =>
              handleValueChange("textMarginY", parseInt(e.target.value, 10))
            }
          ></input>
        </ControllerSection>
        <ControllerSection>
          <label>Text Box Width</label>
          <input
            type="range"
            min={1}
            max={11}
            step={1}
            value={content.textWidth}
            onChange={(e) =>
              handleValueChange("textWidth", parseInt(e.target.value, 10))
            }
          ></input>
        </ControllerSection>
      </CategoryExpandableBar>

      <CategoryExpandableBar title="IMAGE" index={index}>
        <ControllerSection>
          <ImageSelector
            currentValue={content.imageData}
            handleValueChange={handleValueChange}
            label="Image:"
            propName="imageData"
            croppedByDefault
          />
        </ControllerSection>
      </CategoryExpandableBar>
      <CategoryExpandableBar title="VIDEO" index={index}>
        <label>BACKGROUND VIDEO</label>
        <ExplanationP>
          Paste the ID-number of a video here to give the block a
          background-video. This will disable any image you have loaded for this
          block.
        </ExplanationP>
        <VideoSelector
          propName="video"
          currentValue={content.video}
          handleValueChange={handleValueChange}
        />
      </CategoryExpandableBar>
      <CategoryExpandableBar title="BLOCK OPTIONS" index={index}>
        <ControllerSection>
          <span>Block Height</span>
          <XOptionsRadioButtons
            propName="height"
            currentVal={content.height}
            handleChange={handleValueChange}
            index={index}
            labels={["1/6", "1/4", "1/3", "1/2", "3/4", "Fill screen"]}
          />
        </ControllerSection>
      </CategoryExpandableBar>
      <BlockColorOptions
        titleColor={content.titleColor}
        bgColor={content.bgColor}
        textColor={content.textColor}
        handleValueChange={handleValueChange}
      />
    </>
  );
};
export default HeroBlockControl;
