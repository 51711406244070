/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import React, { ReactNode, useState, useEffect } from "react";
import { COLORS, ExplanationP } from "../../style/styledComponents";

type Props = {
  title: string;
  children: ReactNode;
};

export default function HoverHelp({ title, children }: Props) {
  const [exp, setExp] = useState(false);
  const [pos, setPos] = useState({ x: 0, y: 0 });

  function updatePos(e: MouseEvent) {
    setPos({ x: e.clientX, y: e.clientY });
  }

  useEffect(() => {
    window.addEventListener("mousemove", updatePos);
    return () => {
      window.removeEventListener("mousemove", updatePos);
    };
  }, []);

  return (
    <div
      css={css`
        margin: 8px 0;
      `}
      onMouseEnter={() => setExp(true)}
      onMouseLeave={() => setExp(false)}
    >
      <ExplanationP>
        <u>{title}</u>
      </ExplanationP>
      {exp && (
        <div
          css={css`
            position: fixed;
            pointer-events: none;
            background-color: white;
            border-radius: 5px;
            width: 500px;
            z-index: 10000;
            padding: 15px;
            border: 1px solid ${COLORS.accent2};
          `}
          style={{ left: pos.x + "px", top: pos.y + "px" }}
        >
          {children}
        </div>
      )}
    </div>
  );
}
