/** @jsx jsx */

import {
  FullWidthInput,
  TextArea,
  ControllerSection,
  COLORS,
} from "../../style/styledComponents";
import { cloneDeep } from "lodash-es";
import { css, jsx } from "@emotion/react";
import XOptionsRadioButtons from "../PAGEBUILDER_UTILITY_COMPONENTS/XOptionsRadioButtons";
import ImageSelector, {
  imageData,
} from "../PAGEBUILDER_UTILITY_COMPONENTS/ImageSelector";
import CategoryExpandableBar from "../PAGEBUILDER_UTILITY_COMPONENTS/CategoryExpandableBar";
import BlockColorOptions from "../PAGEBUILDER_UTILITY_COMPONENTS/BlockColorOptions";
import VerticalPaddingSliders from "../PAGEBUILDER_UTILITY_COMPONENTS/VerticalPaddingSliders";
import ControlledArrayItems from "../PAGEBUILDER_UTILITY_COMPONENTS/ControlledArrayItems";

interface Props {
  content: any;
  index: number;
  handleBlockChange: Function;
  disabledStyle: string;
}

export const VideoGalleryInit = {
  title: "Video Gallery",
  subtitle: "subtitle",
  titlePosition: 0,
  contentWidth: 0,
  items: [],
  titleColor: 0,
  textColor: 0,
  bgColor: 0,
  vPaddingTop: 30,
  vPaddingBottom: 30,
};

export type imageType = {
  image: imageData;
  label: string;
};

const initVideo = {
  video: { videoID: "", aspectRatioX: 16, aspectRatioY: 9 },
  thumbnail: { imageID: "", vFocus: 50, hFocus: 50, crop: true },
};

export const VideoGalleryControl = ({
  content,
  index,
  handleBlockChange,
  disabledStyle,
}: Props) => {
  const handleValueChange = (key: string, value: any) => {
    const newContent = cloneDeep(content);
    newContent[key] = value;
    handleBlockChange({ ...newContent }, index);
  };

  return (
    <>
      <CategoryExpandableBar title="TEXT" index={index}>
        <ControllerSection>
          <label>Title</label>
          <FullWidthInput
            type="text"
            value={content.title}
            onChange={(e) => handleValueChange("title", e.target.value)}
          />
        </ControllerSection>

        <ControllerSection>
          <label htmlFor="subtitle">Subtitle</label>
          <FullWidthInput
            type="text"
            value={content.subtitle}
            onChange={(e) => handleValueChange("subtitle", e.target.value)}
          />{" "}
        </ControllerSection>
        <ControllerSection>
          <span>Title Position</span>
          <XOptionsRadioButtons
            propName="titlePosition"
            currentVal={content.titlePosition}
            handleChange={handleValueChange}
            index={index}
            labels={["left", "center", "right"]}
          />
        </ControllerSection>
      </CategoryExpandableBar>
      <CategoryExpandableBar title="VIDEOS">
        <ControlledArrayItems
          label="Videos:"
          itemType={{
            video: { type: "video", label: "Video:" },
            thumbnail: { type: "image", label: "Thumbnail:" },
          }}
          handleValueChange={handleValueChange}
          currentValue={content.items}
          propName="items"
          itemName="video"
          initItem={initVideo}
        />
      </CategoryExpandableBar>
      <CategoryExpandableBar title="BLOCK OPTIONS" index={index}>
        <ControllerSection>
          <span>Content Width:</span>
          <XOptionsRadioButtons
            propName="contentWidth"
            currentVal={content.contentWidth}
            handleChange={handleValueChange}
            index={index}
            labels={["Full-width", "Extra padding"]}
          />
        </ControllerSection>
        <VerticalPaddingSliders
          vPaddingBottom={content.vPaddingBottom}
          vPaddingTop={content.vPaddingTop}
          handleValueChange={handleValueChange}
        />
      </CategoryExpandableBar>
      <BlockColorOptions
        titleColor={content.titleColor}
        bgColor={content.bgColor}
        textColor={content.textColor}
        handleValueChange={handleValueChange}
      />
    </>
  );
};
export default VideoGalleryControl;
