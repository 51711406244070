/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import ImageComponent from "../ImageComponent";
import HighlightBlock from "./HighlightBlock";
import { useState } from "react";
import logo from "../../images/bliklogoWhite.png";
import Expand from "react-expand-animated";

import test from "../../images/werkproces/test.png";

import intro from "../../images/werkprocesIcons/intro.svg";
import kennismaken from "../../images/werkprocesIcons/kennismaking.svg";
import concept from "../../images/werkprocesIcons/conceptenofferte.svg";
import script from "../../images/werkprocesIcons/script.svg";
import preproductie from "../../images/werkprocesIcons/preproductie.svg";
import opnames from "../../images/werkprocesIcons/opnames.svg";
import nabewerking from "../../images/werkprocesIcons/nabewerking.svg";
import feedback from "../../images/werkprocesIcons/feedback.svg";
import oplevering from "../../images/werkprocesIcons/oplevering.svg";
import {
  colors,
  getTextAlignFromNumber,
  getXPaddingAmtFromNumber,
  maxContentWidth,
  vPaddingMultiplier,
  xPaddingAmtMobile,
} from "../../styles/style";
import { fillInMissingBlockDataWithDefaults } from "../../cms/functions";
import { textBoxInit } from "../../cms/CMSComponents/CONTROLLER_COMPONENTS/TextBoxBlockControl";

type Props = {
  blockContent: string;
  index: number;
  highlighted: boolean;
  pageTitleColor: number;
  breakPoint: number;
};

function ProductieProcesBlock({
  blockContent,
  index,
  highlighted,
  pageTitleColor,
  breakPoint,
}: Props) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [textIndex, setTextIndex] = useState(0);

  const [hoveredIndex, setHoveredIndex] = useState(0);
  const [visible, setVisible] = useState(true);

  function triggerAnimation(index: number) {
    setVisible(false);
    setSelectedIndex(index);
    setTimeout(() => setTextIndex(index), 300);
    setTimeout(() => setVisible(true), 320);
  }

  const DATA = fillInMissingBlockDataWithDefaults(
    JSON.parse(blockContent),
    textBoxInit
  );

  let xPaddingAmt = getXPaddingAmtFromNumber(1, breakPoint);

  const appContent = [
    {
      title: "Intro",
      text: DATA.textIntro,
      icon: intro,
    },
    {
      title: "Kennismaking",
      text: DATA.text0,
      icon: kennismaken,
    },
    {
      title: "Concept & Offerte",
      text: DATA.text1,
      icon: concept,
    },
    {
      title: "Script",
      text: DATA.text2,
      icon: script,
    },
    {
      title: "Pre-productie",
      text: DATA.text3,
      icon: preproductie,
    },
    {
      title: "Opnames",
      text: DATA.text4,
      icon: opnames,
    },
    {
      title: "Nabewerking",
      text: DATA.text5,
      icon: nabewerking,
    },
    {
      title: "Feedback",
      text: DATA.text6,
      icon: feedback,
    },
    {
      title: "Oplevering",
      text: DATA.text7,
      icon: oplevering,
    },
  ];

  return (
    <section
      id={"container" + index}
      className={`relative  w-screen transition-colors z-0`}
      style={{
        color: colors[DATA.textColor],
        backgroundColor: colors[DATA.bgColor],
        padding: `${DATA.vPaddingTop * vPaddingMultiplier}px ${xPaddingAmt}px ${
          DATA.vPaddingBottom * vPaddingMultiplier
        }px`,
      }}
    >
      {/* DESKTOP VERSION */}
      <div
        style={{ maxWidth: maxContentWidth + "px" }}
        className="mx-auto hidden md:block"
      >
        <div
          className="flex h-1 rounded-full xl:-mx-10 mx-6"
          style={{ backgroundColor: colors[DATA.textColor] }}
        ></div>
        <div className="flex justify-between mb-24 xl:-mx-10 mx-6">
          {appContent.map((item, index) => {
            if (index > 0)
              return (
                <ClickableDesktop
                  item={item}
                  index={index}
                  setHoveredIndex={setHoveredIndex}
                  setSelectedIndex={triggerAnimation}
                  textColor={DATA.textColor}
                  hovered={hoveredIndex === index}
                  selected={selectedIndex === index}
                  key={item.title}
                  selectedIndex={selectedIndex}
                />
              );
          })}
        </div>
        <div
          className="flex items-start justify-center transition-opacity ease-linear duration-300 lg:h-80 h-96"
          style={{ opacity: visible ? 1 : 0 }}
        >
          <div className="w-1/3" style={{ maxWidth: "340px" }}>
            <img
              src={appContent[textIndex].icon}
              className="w-full object-contain px-8  mx-auto"
            ></img>
          </div>

          <div
            id={"textbox" + index}
            className={`z-10 w-2/3 xl:w-1/2 flex flex-col justify-center`}
            style={{
              padding: `0 `,
              maxWidth: "650px",
            }}
          >
            <h4 className="relative z-0 text-2xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-4xl font-title sm:-ml-0.5 font-normal">
              {appContent[textIndex].title}
            </h4>

            <div
              className={`mt-8 "mx-0"}`}
              style={{
                color: colors[DATA.textColor],
              }}
              dangerouslySetInnerHTML={{
                __html: appContent[textIndex].text,
              }}
            ></div>
          </div>
        </div>
      </div>

      {/* MOBILE VERSION */}
      <div className=" md:hidden block">
        <div className="inline-flex justify-between flex-col relative mt-8">
          {appContent.map((item, index) => {
            if (index > 0)
              return (
                <ClickableMobile
                  key={item.title}
                  item={item}
                  index={index}
                  setSelectedIndex={setSelectedIndex}
                  textColor={DATA.textColor}
                  selectedIndex={selectedIndex}
                  selected={selectedIndex === index}
                  noLine={index === appContent.length - 1}
                  appContent={appContent}
                />
              );
          })}
        </div>
      </div>
      {highlighted && <HighlightBlock />}
    </section>
  );
}

type clickableDesktopProps = {
  item: { [x: string]: any };
  index: number;
  setHoveredIndex: Function;
  setSelectedIndex: Function;
  textColor: number;
  hovered: boolean;
  selected: boolean;
  selectedIndex: number;
};

function ClickableDesktop({
  item,
  index,
  setHoveredIndex,
  setSelectedIndex,
  textColor,
  hovered,
  selected,
  selectedIndex,
}: clickableDesktopProps) {
  return (
    <div
      className="inline-flex flex-col items-center relative"
      style={{ transform: "translateY(-10px)" }}
    >
      <div
        className={`relative rounded-full duration-300 transform transition-transform w-4 h-4 ease-in-out ${
          selectedIndex === 0 ? "pulseAnimation" : ""
        } ${selected ? "scale-175" : hovered ? "scale-125" : "scale-100"}`}
        style={{
          backgroundColor: colors[textColor],
          animationDelay: index * 0.5 + "s",
        }}
      >
        <img
          src={logo}
          className={`w-4 h-4 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  object-contain duration-300 transform transition-transform ${
            selected ? "scale-125" : "scale-0"
          }`}
          //style={{ width: "14px", height: "14px" }}
        ></img>
      </div>
      <span
        onMouseEnter={() => setHoveredIndex(index)}
        onMouseLeave={() => setHoveredIndex(NaN)}
        onClick={() => setSelectedIndex(index)}
        className={`absolute transform transition-transform ease-in-out duration-300 -translate-x-1/2 left-1/2 w-40 -top-1 pt-8 text-center text-xs xl:text-sm ${
          selected ? "scale-125" : hovered ? "scale-110" : "scale-100"
        }`}
      >
        {item.title}
      </span>
    </div>
  );
}

type clickableMobileProps = {
  item: { [x: string]: any };
  index: number;
  setSelectedIndex: Function;
  textColor: number;
  selected: boolean;
  selectedIndex: number;
  noLine: boolean;
  appContent: any;
};

function ClickableMobile({
  item,
  index,
  setSelectedIndex,
  textColor,
  selectedIndex,
  selected,
  noLine,
  appContent,
}: clickableMobileProps) {
  const transitions = ["height", "opacity"];

  return (
    <div className="flex relative mb-6">
      {!noLine && (
        <div
          className="absolute w-0.5 rounded-full"
          style={{
            backgroundColor: colors[textColor],
            left: "15px",
            height: selected
              ? "calc(100% - 10px)"
              : selectedIndex === index + 1
              ? "calc(100% - 8px)"
              : "calc(100%)",
            top: selected ? "42px" : "32px",
            transition: "top 0.3s, height 0.3s",
          }}
        ></div>
      )}
      <div
        className={`relative rounded-full duration-300 transform transition-transform w-10 h-10 ease-in-out `}
        //style={{ backgroundColor: colors[textColor] }}
      >
        <img
          src={appContent[index].icon}
          className={`w-10 h-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  object-contain duration-300 transform transition-transform ${
            selected ? "scale-150" : "scale-100"
          }`}
          onClick={() => {
            if (selected) {
              setSelectedIndex(0);
            } else {
              setSelectedIndex(index);
            }
          }}
          //style={{ width: "14px", height: "14px" }}
        ></img>
      </div>
      <div className={"w-full ml-4 mt-0.5"}>
        <h4
          onClick={() => {
            if (selected) {
              setSelectedIndex(0);
            } else {
              setSelectedIndex(index);
            }
          }}
          className={`text-2xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-4xl font-title sm:-ml-0.5 font-normal`}
        >
          {item.title}
        </h4>
        <Expand open={selected} duration={200} transitions={transitions}>
          <div
            className="pt-4 pb-4"
            dangerouslySetInnerHTML={{ __html: item.text }}
          ></div>
        </Expand>
      </div>
    </div>
  );
}

export default ProductieProcesBlock;
